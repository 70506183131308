import React from "react";
import "./solutionCard.scss"

const index = ({ title, icon, redText, desc, knowMore, alt }) => {
  return (
    <div className="card_wrapper">
      <div className="first_block">
        <div className="card_title_desc_wrapper">
          <p className="card_title">{title}</p>
          <p className="red_text">{redText}</p>
        </div>
        <div className="card_icon">
          <img src={icon} alt={alt} className="img" />
        </div>
      </div>
      <div className="card_desc">{desc}</div>
      {/* <div className="third_block">
        <a className="know_button" href={knowMore}>Know More</a>
      </div> */}
    </div>
  );
};

export default index;

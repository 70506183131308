import React from "react";
import "./LogoutConfirmation.scss";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";

function LogoutConfirmation({ setIsLogoutConfirmationOpen, handleLogout }) {
  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackLogoutYes = async () => {
    const user = await fetchUserId();
    // let data = localStorage.getItem("auth");
    // UserId
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);


      //set event data
      window.Moengage.track_event(
        eventHelpers.LOGOUT_Y
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackLogoutYesFailed = async () => {
    // const user = await fetchUserId();
    let UserId = localStorage.getItem("UserId");
    // UserId
    // console.log("user---->", user);
    // const userId = user.data.withAuth.userDetails.id;
    // const userName = user.data.withAuth.userDetails.name.full;
    // const userEmail = user.data.withAuth.userDetails.emails[0].address;
    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(UserId);
      //set user attributes
      // window.Moengage.add_user_name(userName);
      // window.Moengage.add_email(userEmail);
      //set event data
      window.Moengage.track_event(eventHelpers.LOGOUT_Y, {
        ID: UserId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
      });
      // window.Moengage.destroy_session();
    }
  };

  const trackLogoutNo = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.LOGOUT_N
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <div className="logout-confirmation-container">
      <div className="logout-confirmation-wrapper">
        <div className="lc-top">{/* <img/> */}</div>
        <div className="lc-center">
          <h3>Confirmation</h3>
          <p>Are you sure you want to logout ?</p>
        </div>
        <div className="lc-bottom">
          <button
            className="button-no"
            onClick={() => {
              setIsLogoutConfirmationOpen(false);
              trackLogoutNo();
            }}
          >
            No
          </button>
          <button
            className="button-yes"
            onClick={async () => {
              try {
                 trackLogoutYes();
                 await new Promise(resolve => setTimeout(resolve, 1000));
                handleLogout();
                setIsLogoutConfirmationOpen(false);
              } catch (error) {
                console.error("Error during trackLogoutYes:", error);
                trackLogoutYesFailed();
                handleLogout();
                setIsLogoutConfirmationOpen(false);
              }

              // trackLogoutYes()
              //   .then(() => {
              //     handleLogout();
              //     setIsLogoutConfirmationOpen(false);
              //   })
              // .catch((error) => {
              //   console.error("Error during trackLogoutYes:", error);
              // })
              // .finally(() => {
              //   trackLogoutYesFailed();
              //   handleLogout();
              //   setIsLogoutConfirmationOpen(false);
              // });
              // trackLogoutYes();
              // handleLogout();
              // setIsLogoutConfirmationOpen(false);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default LogoutConfirmation;

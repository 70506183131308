import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setJobModal } from "../../../redux/userReducer";
import Reasonselect from "../../Common/DropDown/DropDown";
import "./JobModal.scss";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";

function JobOptionModel({ jobPostRemoveId, removeOpenJob, title }) {
  let dispatch = useDispatch();
  let [reasonToRemove, setReasonToRemove] = useState({
    reason: "",
    comment: "",
  });
  let [reasonToRemoveError, setReasonToRemoveError] = useState({});
  function handleJobPost(jobType) {
    dispatch(setJobModal());
  }
  function handleRemove() {
    removeOpenJob(jobPostRemoveId?.allData?.id, reasonToRemove);
    dispatch(setJobModal());
  }

  function validate() {
    let error = {};
    if (!reasonToRemove.reason) {
      error.reason = "please select one reason";
    }
    if (!reasonToRemove.comment) {
      error.comment = "please provide reason to remove";
    }
    if (reasonToRemove.comment.length < 8) {
      error.comment = "please provide valid comment";
    }
    return error;
  }

  useEffect(() => {
    setReasonToRemoveError(validate());
  }, [reasonToRemove]);

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackCancOngLcbYes = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.CANCL_ONG_LJB_Y
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackCancOngLcbNo = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.CANCL_ONG_LJB_N
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackCancAccptedLcbYes = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);
      
      //set event data
      window.Moengage.track_event(
        eventHelpers.CANCL_ACCPT_LJB_Y
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackCancAccptedLcbNo = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.CANCL_ACCPT_LJB_N
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <div className="Jobmodel-card">
      <h3>Are you sure you want to cancel {title} job?</h3>
      <div className="Jobmodal-reason">
        <p>Reason to cancel:</p>
        <Reasonselect
          reasonToRemove={reasonToRemove}
          setReasonToRemove={setReasonToRemove}
          title={"Reason"}
        />
      </div>
      <div className="Jobmodal-comment">
        <h2>
          Comments<span style={{ color: "#d74f58" }}>*</span>
        </h2>
        <textarea
          aria-label="minimum height"
          rows="8"
          cols="80"
          placeholder="Type Here"
          onChange={(e) =>
            setReasonToRemove({
              ...reasonToRemove,
              comment: e.target.value.trim(),
            })
          }
          style={{
            width: "96%",
            border: "none",
            outline: "none",
            background: "#F7F7F7",
            borderRadius: "8px",
            padding: "10px",
            resize: "none",
            overFlowX: "auto",
          }}
        />
      </div>
      <div className="Jobmodal-buttonsDiv">
        <button
          className="Jobmodal-buttonsNo"
          onClick={() => {
            if (title === "ongoing") {
              trackCancOngLcbNo();
            } else if (title === "accepted") {
              trackCancAccptedLcbNo();
            }
            handleJobPost();
          }}
        >
          No
        </button>
        <button
          className="Jobmodal-buttonsYes"
          style={{
            opacity:
              Object.keys(reasonToRemoveError).length !== 0 ? "0.3" : "1",
          }}
          onClick={() => {
            if (title === "ongoing") {
              trackCancOngLcbYes();
            } else if (title === "accepted") {
              trackCancAccptedLcbYes();
            }
            handleRemove();
          }}
          disabled={Object.keys(reasonToRemoveError).length !== 0}
        >
          Yes
        </button>
      </div>
    </div>
  );
}

export default JobOptionModel;

import React, { useEffect, useState } from "react";
import "./jobListingCard.scss";
import graphqlRequest from "../../requestMethod";
import SearchIcon from "../../asset/footer/search-job.svg";
import Manipal from "../../asset/footer/manipal-icon.svg";
import CKBirla from "../../asset/footer/CKBirla.png";
import Motherland from "../../asset/footer/Motherland.png";
import SGRH from "../../asset/footer/SGRH.png";
import RightArrowBlue from "../../asset/footer/arrow-right_blue.svg";

const Index = () => {
  let [landingJobsData, setLandingJobsData] = useState([]);

  let sameDay = +new Date();
  let afterOneDay = new Date().setDate(new Date().getDate() + 1);
  let afterTwoDay = new Date().setDate(new Date().getDate() + 2);

  const data = [
    {
      department: {
        departmentType: {
          name: "General ward",
        },
      },
      candidateType: {
        name: "Nurse",
      },
      cost: "2500",
      branch: {
        jobLocation: {
          hospitalName: "Sir Ganga Ram Hospital",
          address: { city: "Delhi" },
        },
        organization: {
          logo: { url: SGRH },
        },
      },
      consequtiveTime: {
        start: { ts: sameDay },
        end: { ts: sameDay },
      },
      numberOfDays: "1",
      image: SGRH,
    },
    {
      department: {
        departmentType: {
          name: "NICU",
        },
      },
      candidateType: {
        name: "Nurse",
      },
      cost: "3000",
      branch: {
        jobLocation: {
          hospitalName: "CK Birla",
          address: { city: "Gurgaon" },
        },
        organization: {
          logo: { url: CKBirla },
        },
      },
      consequtiveTime: {
        start: { ts: sameDay },
        end: { ts: afterOneDay },
      },
      numberOfDays: "2",
      image: SGRH,
    },
    {
      department: {
        departmentType: {
          name: "CCU",
        },
      },
      candidateType: {
        name: "Nurse",
      },
      cost: "2800",
      branch: {
        jobLocation: {
          hospitalName: "Motherland",
          address: { city: "Bangalore" },
        },
        organization: {
          logo: { url: Motherland },
        },
      },
      consequtiveTime: {
        start: { ts: afterOneDay },
        end: { ts: afterTwoDay },
      },
      numberOfDays: "2",
    },
    {
      department: {
        departmentType: {
          name: "Gynaecologist",
        },
      },
      candidateType: {
        name: "Doctor",
      },
      cost: "11000",
      branch: {
        jobLocation: {
          hospitalName: "CK Birla",
          address: { city: "Gurgaon" },
        },
        organization: {
          logo: { url: CKBirla },
        },
      },
      consequtiveTime: {
        start: { ts: sameDay },
        end: { ts: afterTwoDay },
      },
      numberOfDays: "3",
      image: SGRH,
    },
  ];

  let fetchLandingJobsData = () => {
    const query = `
    query{
    listLandingJob(limit: 10, offset: 0) {
      id
      state
      code
      startTime
      endTime
      createdAt
      department {
        id
        departmentType {
          name
        }
      }
      maxRate
      minRate
      cost
      candidateType {
        name
      }
      numberOfJobs
      timingsType
      numberOfDays
      branch {
        id
        brand
        subBrand
        organization {
          logo {
            url
          }
        }
        jobLocation {
          hospitalName
          branchName
          address {
            address
            city
            state
            coords
          }
        }
      }
      numExperience {
        name
      }
      nonConsequtiveTime {
        start {
          ts
        }
        end {
          ts
        }
        weekdays
      }
      consequtiveTime {
        start {
          year
          month
          date
          hour
          ts
        }
        end {
          year
          month
          date
          hour
          ts
        }
      }
    }
  }


`;
    graphqlRequest(query).then((response) => {
      // console.log("jobDetails>>", response);
      // console.log(">>>>2>>",response.length);
      setLandingJobsData(response?.data?.listLandingJob || data);
    });
  };

  useEffect(() => {
    fetchLandingJobsData();
  }, []);

  function formatDate(timestamp) {
    if (!timestamp) return "";

    // console.log("timestamp", timestamp);
    const date = new Date(parseInt(timestamp));
    // console.log("date", date);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Determine the correct suffix for the day
    const suffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Handles 11th, 12th, 13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
  }

  return (
    <>
      {landingJobsData.map((job, index) => (
        <div key={index} className="job_lisiting_card">
          <div className="job_listing_first_block">
            <div className="job_listing_first_block_search_wrappper">
              <div className="search_icon">
                <img src={SearchIcon} alt="magnifying_glass" className="img" />
              </div>
              <p>Actively hiring</p>
            </div>
            <div className="job_listing_first_block_title_wrappper">
              <p className="job_heading">
                {job?.candidateType?.name === "Doctor"
                  ? `${job.department?.departmentType?.name} Doctor`
                  : `${job.department?.departmentType?.name} Nurse`}
              </p>
              <p className="job_pay">₹ {job.cost}</p>
            </div>
          </div>
          <div className="job_listing_second_block">
            <div className="hospital_icon">
              {/* <img src={Manipal} alt="Manipal Hospital" className="img" /> */}
              <img
                src={job.branch?.organization?.logo?.url}
                // alt={job.branch?.jobLocation?.hospitalName}
                alt="logo"
                className="img"
              />
            </div>
            <div className="hospital_address">
              <p className="hospital_name">
                {job.branch?.jobLocation?.hospitalName}
              </p>
              <p className="hospital_location">
                {job.branch?.jobLocation?.address?.city}
              </p>
            </div>
          </div>
          <div className="divider"></div>
          <div className="job_listing_third_block">
            <div className="job_listing_deadline">
              <div className="job_listing_details">
                <div className="job_listing_detail_heading">Start Date</div>
                <div className="job_listing_detail">
                  {" "}
                  {job?.consequtiveTime?.start?.ts
                    ? formatDate(job?.consequtiveTime?.start?.ts)
                    : formatDate(job?.nonConsequtiveTime?.start?.ts)}
                </div>
              </div>
              <div className="job_listing_details">
                <div className="job_listing_detail_heading">End Date</div>
                <div className="job_listing_detail">
                  {" "}
                  {job?.consequtiveTime?.end?.ts
                    ? formatDate(job?.consequtiveTime?.end?.ts)
                    : formatDate(job?.nonConsequtiveTime?.end?.ts)}
                </div>
              </div>
              <div className="job_listing_details">
                <div className="job_listing_detail_heading">Duration</div>
                <div className="job_listing_detail">
                  {job.numberOfDays} days
                </div>
              </div>
            </div>
            {/* <div className="job_listing_employer">
          <div className="job_listing_employer_details">
            <p className="job_listing_employer_details_heading">Posted by</p>
            <p className="detail">Ifanglobal</p>
          </div>
          <div className="job_listing_employer_view_details">
            <p>View Details</p>
            <div className="right_arrow">
              <img src={RightArrowBlue} alt="next_arrow" className="img" />
            </div>
          </div>
        </div> */}
          </div>
        </div>
      ))}
    </>
  );
};

export default Index;

// import React, { useEffect, useState } from "react";
// import "./jobListingCard.scss";
// import graphqlRequest from "../../requestMethod";
// import SearchIcon from "../../asset/footer/search-job.svg";
// import RightArrowBlue from "../../asset/footer/arrow-right_blue.svg";

// const Index = () => {
//   const [landingJobsData, setLandingJobsData] = useState([]);

//   const fetchLandingJobsData = () => {
//     let data = localStorage.getItem("auth");
//     let token = JSON.parse(data)?.accessToken;
//     const query = `{
//       withAuth(token: "${token}") {
//         listLandingJob(limit: 10, offset: 0) {
//           id
//           state
//           code
//           startTime
//           endTime
//           createdAt
//           department {
//             id
//             departmentType {
//               name
//             }
//           }
//           maxRate
//           minRate
//           cost
//           candidateType {
//             name
//           }
//           numberOfJobs
//           timingsType
//           numberOfDays
//           branch {
//             id
//             brand
//             subBrand
//             organization {
//               logo {
//                 url
//               }
//             }
//             jobLocation {
//               hospitalName
//               branchName
//               address {
//                 address
//                 city
//                 state
//                 coords
//               }
//             }
//           }
//           numExperience {
//             name
//           }
//           nonConsequtiveTime {
//             start {
//               ts
//             }
//             end {
//               ts
//             }
//             weekdays
//           }
//           consequtiveTime {
//             start {
//               year
//               month
//               date
//               hour
//               ts
//             }
//             end {
//               year
//               month
//               date
//               hour
//               ts
//             }
//           }
//         }
//       }
//     }`;
//     graphqlRequest(query).then((response) => {
//       console.log("jobDetails>>", response);
//       setLandingJobsData(response?.data?.withAuth?.listLandingJob || []);
//     });
//   };

//   useEffect(() => {
//     fetchLandingJobsData();
//   }, []);

//   return (
//     <>
//       {landingJobsData.map((job, index) => (
//         <div key={index} className="job_listing_card">
//           <div className="job_listing_first_block">
//             <div className="job_listing_first_block_search_wrapper">
//               <div className="search_icon">
//                 <img src={SearchIcon} alt="magnifying_glass" className="img" />
//               </div>
//               <p>Actively hiring</p>
//             </div>
//             <div className="job_listing_first_block_title_wrapper">
//               <p className="job_heading">{job.department?.departmentType?.name}</p>
//               <p className="job_pay">₹ {job.cost}</p>
//             </div>
//           </div>
//           <div className="job_listing_second_block">
//             <div className="hospital_icon">
//               <img
//                 src={job.branch?.organization?.logo?.url}
//                 alt={job.branch?.jobLocation?.hospitalName}
//                 className="img"
//               />
//             </div>
//             <div className="hospital_address">
//               <p className="hospital_name">{job.branch?.jobLocation?.hospitalName}</p>
//               <p className="hospital_location">{job.branch?.jobLocation?.address?.city}</p>
//             </div>
//           </div>
//           <div className="divider"></div>
//           <div className="job_listing_third_block">
//             <div className="job_listing_deadline">
//               <div className="job_listing_details">
//                 <div className="job_listing_detail_heading">Start Date</div>
//                 <div className="job_listing_detail">{job.startTime}</div>
//               </div>
//               <div className="job_listing_details">
//                 <div className="job_listing_detail_heading">End Date</div>
//                 <div className="job_listing_detail">{job.endTime}</div>
//               </div>
//               <div className="job_listing_details">
//                 <div className="job_listing_detail_heading">Duration</div>
//                 <div className="job_listing_detail">{job.numberOfDays} days</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </>
//   );
// };

// export default Index;

import React, { useState , useEffect} from "react";
import "./JobMatches.scss";
import completed from "../../../asset/postjob/darkcompleted.svg";
import user from "../../../asset/postjob/jobMatchUser.svg";
import star from "../../../asset/postjob/star.svg";
import { Link, useNavigate } from "react-router-dom";
import CrossIcon from "../../../asset/jobs/close-circle.svg";
import Avtar from "../../../asset/avtar.png";
import badge from "../../../asset/badge.svg";
import {
  recommendedUsers,
  recommendedUserAbsolute,
  setActiveTab,
  setOpenJobMatches,
  setProfile,
} from "../../../redux/userReducer";
import { useDispatch, useSelector } from "react-redux";

const getRandomNumber = () => Math.floor(Math.random() * (20 - 5 + 1)) + 5;

function JobMatches(props) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let handleRoute = (data) => {
    dispatch(setProfile(data));
    navigate("/profile/recommendation");
    dispatch(setOpenJobMatches());
  };
  let { recommendedUsers, recommendedUserAbsolute, userJobPostDetails } = useSelector(
    (state) => state?.user
  );



  let handleViewAll = () => {
    dispatch(setProfile([]));
    dispatch(setOpenJobMatches());
  };
  const handleClose = () => {
    dispatch(setOpenJobMatches());
    dispatch(setActiveTab({ index: 3, locumActive: 1, LocumJobToggle: true }));
    navigate("/locum/open-jobs");
  };

  // const totalToShow = 3;
  // const recommendedAbsoluteCount = recommendedUserAbsolute.length;
  // console.log("recommendedAbsoluteCount", recommendedAbsoluteCount);
  
  // const remainingCount = totalToShow - recommendedAbsoluteCount;
  // console.log("remainingCount", remainingCount);
  
  // const finalUsers = recommendedAbsoluteCount >= totalToShow 
  //   ? recommendedUserAbsolute.slice(0, totalToShow) 
  //   : recommendedUserAbsolute.concat(recommendedUsers.slice(0, remainingCount));
  
  // console.log("finalUsers", finalUsers);

//   const totalToShow = 3;
// const recommendedAbsoluteCount = recommendedUserAbsolute.length;
// console.log("recommendedAbsoluteCount", recommendedAbsoluteCount);

// const recommendedUsersCount = recommendedUsers.length;
// console.log("recommendedUsersCount", recommendedUsersCount);

// let finalUsers = [];

// if (recommendedUsersCount === 0) {
//   finalUsers = recommendedUserAbsolute.slice(0, totalToShow);
// } else {
//   const remainingCount = totalToShow - recommendedAbsoluteCount;
//   console.log("remainingCount", remainingCount);

//   finalUsers = recommendedAbsoluteCount >= totalToShow 
//     ? recommendedUserAbsolute.slice(0, totalToShow) 
//     : recommendedUserAbsolute.concat(recommendedUsers.slice(0, remainingCount));
// }

// console.log("finalUsers", finalUsers);

const totalToShow = 3;
let finalUsers = [];

if (recommendedUserAbsolute.length > 0) {
  const recommendedAbsoluteCount = recommendedUserAbsolute.length;
  const remainingCount = totalToShow - recommendedAbsoluteCount;

  if (remainingCount > 0 && recommendedUsers.length > 0) {
    finalUsers = recommendedUserAbsolute.concat(recommendedUsers.slice(0, remainingCount));
  } else {
    finalUsers = recommendedUserAbsolute.slice(0, totalToShow);
  }
} else if (recommendedUsers.length > 0) {
  finalUsers = recommendedUsers.slice(0, totalToShow);
}

console.log("finalUsers", finalUsers);



 

  console.log("recommendedUsers",recommendedUsers)
  console.log("recommendedUserAbsolute",recommendedUserAbsolute)
  return (
    <div className="job-match-card">
      <div className="job-match-card-wrapper">
        <div className="job-match-card-top">
          <div className="successful-top">
            <img src={completed} alt="completed" />
            <p>
              Your Job ID {userJobPostDetails?.code} has been posted
              successfully!
            </p>

            <img
              src={CrossIcon}
              style={{ width: "32px", height: "32px", cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <div className="successful-bottom">
            <span>Here are top few matches! </span>
          </div>
        </div>
        <div className="job-match-card-center">
          {/* {recommendedUsers?.slice(0, 3)?.map((data) => { */}
          {finalUsers?.map((data) => {
            console.log("data>>", data)
            const randomNumber = getRandomNumber();
            return (
              <div
                className="user-info"
                key={data?.id}
                onClick={() => handleRoute(data)}
                style={{ cursor: "pointer" }}
              >
                <div className="user-info-top">
                  {data?.marksPercentage >= 60 && (
                    <div
                      className="dp-badge-hover"
                      data-message="Jobizo Assessment Qualified"
                    >
                      <img src={badge} className="dp-badge" />
                    </div>
                  )}
                  <img
                    src={data?.avatar?.url || Avtar}
                    className={
                      data?.marksPercentage >= 60 ? "badge-border" : ""
                    }
                    alt="user-logo"
                  />
                  {/* <img src={data?.avatar?.url || Avtar} alt="user-logo" /> */}
                  <div className="user-profile">
                    <p>{data?.name?.full}</p>
                  </div>
                  <span>{data?.numExperience?.name} of experience</span>
                </div>
                <div className="user-info-bottom">
                <div className="user-ratting">
  {data?.numberOfRatings >= 5 ? (
    <>
      {typeof data.averageRating === 'number' &&
      data.averageRating >= 0 ? (
        new Array(Math.round(data.averageRating)).fill(1).map((_, index) => (
          <React.Fragment key={index}>
            <img src={star} alt="star" />
            {index === Math.round(data.averageRating) - 1 && (
              <span
                style={{
                  position: 'relative',
                  top: '-25px',
                  left: '88px',
                  fontSize: "0.8em",
                }}
              >
                ({data.numberOfRatings})
              </span>
            )}
          </React.Fragment>
        ))
      ) : (
        <span>Invalid average rating</span>
      )}
    </>
  ) : (
    <>
      {typeof data?.rating === 'number' &&
      data?.rating >= 0 &&
      Number.isInteger(data?.rating) ? (
        <div style={{ display: "inline-block", position: "relative" }}>
      {new Array(data?.rating).fill(1).map((_, index) => (
        <img key={index} src={star} alt="star" />
      ))}
      <span
        style={{
          position: 'absolute',
          top: '40%',
          left: 'calc(100% + 2px)',
          transform: 'translateY(-50%)',
          fontSize: "0.8em",
        }}
      >
        ({randomNumber})
      </span>
    </div>
    ) : (
      <span>Invalid rating</span>  
    )}
  </>
  )}
</div>
                  {/* <span>{data?.numberOfRatings} people rated</span> */}
                  {/* <span>(1234)</span> */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="job-match-card-bottom">
          <Link to="/profile/recommendation" onClick={() => handleViewAll()}>
            <button>View all</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default JobMatches;

// ( // <span>No Rating</span>
// ) }

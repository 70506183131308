import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  Modal,
  Paper,
  Typography,
} from "@mui/material";

// Import Swiper styles
import arrow from "../../../asset/popup/arrow.svg";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setJobDetaialsModal,
  setUserApplication,
} from "../../../redux/userReducer";
import "./JobDetailsModal.scss";
import CrossIcon from "../../../asset/jobs/close-circle.svg";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import graphqlRequest from "../../../requestMethod";
import ProfileList from "../ProfileList/ProfileList";
import user from "../../../asset/popup/user.webp";
import RecommendationUser from "../RecommendationUser/RecommendationUser";
import InterviewSlotInput from "../../PopUp/InterviewSlotInput/InterviewSlotInput";
import Button from "@mui/material/Button";
import { message } from "../../Common/SnakeAlert/SnakeAlert";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avtar from "../../../asset/avtar.png";
import { useNavigate } from "react-router-dom";
import badge from "../../../asset/badge.svg";
import tickMark from "../../../asset/tickMark.svg";
import eventHelpers from "../../../Helpers/Event";

const JobDetailsModal = ({ handleCloseDetail, selectedData, fetchOpenJob }) => {
  let [jobDetails, setJobDetails] = useState({});
  let [applications, setApplications] = useState([]);
  let [recommendedUser, setRecommendedUser] = useState([]);
  let [recommendedUserAbsolute, setRecommendedUserAbsolute] = useState([]);
  let [selectedProfile, setSelectedProfile] = useState({});
  let [selectedRecommendedUser, setSelectedRecommendedUser] = useState({});
  // let [invitedUser, setInvitedUser] = useState({});
  let [invitedUser1, setInvitedUser1] = useState([]);
  let invitedUser = [];
  let [whetherInvitedUser, setWhetherInvitedUser] = useState([]);
  let [isInterviewSlotOpen, setIsInterviewSlotOpen] = useState(false);
  let [prevExpHandleViewMoreValue, setPrevExpHandleViewMoreValue] =
    useState(false);
  let [educationHandleViewMoreValue, setEducationHandleViewMoreValue] =
    useState(false);
  let [isresumeDownloading, setIsResumeDownloading] = useState(false);

  const { AppliedUserApplication } = useSelector((state) => state.user);

  let dispatch = useDispatch();
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;

  function handleJobPost(jobType) {
    dispatch(setJobDetaialsModal());
  }
  // console.log(">>selectedRecommendedUser", selectedRecommendedUser);
  function fetchSelectedJobDetails() {
    const query = `
      {
        withAuth(token: "${token}") {
          getJob(id: "${selectedData?.id}") {
            timingsType
            id
            startTime
            nonConsequtiveTime{weekdays}
            endTime
            recommendedUsers{
              user{id},
              isApplied
            }
            state
            code
            shortListCount
            interviewCount
            rejectedCount
            roleOfferedCount
            jobDescription
            numberOfJobs
            numberOfDays
            organization {
              id
              name
              companyType {
                id
                name
              }
            }
            branch{
              jobLocation {
                hospitalName
                branchName
              }
            }
            department {
              id
              departmentType {
                name
              }
              branch {
                company {
                  id
                  name
                }
                brand
                subBrand
                jobLocation {
                  hospitalName
                  branchName
                }
              }
            }
            qualification {
              id
              name
              description
              profession {
                id
                name
              }
            }
           
            numExperience {
              id
              name
            }
            specialization {
              id
              name
            }
            minRate
            maxRate
            shift
            shiftLogs {
              startTime {
                ts
              }
              endTime {
                ts
              }
            }
            candidateType{
              name
            }
            contactDetail {
              address {
                address
                city
                state
              }
              email
              phone
            }
            consequtiveTime {
              start {
                ts
              }
              end {
                ts
              }
            }
            nonConsequtiveTime {
              start {
                ts
              }
              end {
                ts
              }
            }
          }
        }
      }
    `;
    graphqlRequest(query).then((response) => {
      if (response?.data?.withAuth?.getJob) {
        setJobDetails(response?.data?.withAuth?.getJob);
        setWhetherInvitedUser(
          response?.data?.withAuth?.getJob?.recommendedUsers
        );
        const { roleOfferedCount, numberOfJobs } =
          response?.data?.withAuth?.getJob;
        if (roleOfferedCount == numberOfJobs) {
          // console.log({ newJobDetails: response?.data?.withAuth?.getJob });
          handleCloseDetail();
        }
        // message.success("Data get successfully");
      } else {
        // message.error("Something went wrong");
      }
    });
  }
  let fetchApplications = () => {
    const query = `
      query {
        withAuth(token: "${token}") {
          listNurseApplications(job: "${selectedData?.id}") {
            id
            state
            professional{
             marksPercentage
               }
               professional{
                profileCompleted
                  }
            organization {
              id
              name
            }
            appliedAt {
              month
            }
            professional {
              id
              status
              name {
                salutation
                first
                full
              }
              phoneNumbers {
                number
              }
              emails {
                address
              }
              role {
                id
                name
              }
              qualification {
                name
                description
              }
            }
            rejectedAt {
              ts
            }
            rejectReason
            createdAt
            professional {
              id
              emails {
                isPrimary
                address
              }
              phoneNumbers {
                isPrimary
                number
              }
              name {
                full
              }
              address {
                state
                address
                country
                city
                country
                pin
              }
              specialization {
                name
                id
              }
              numExperience {
                id
                name
              }
              workCity {
                id
                name
              }
              qualification {
                id
                name
              }
              professionalType {
                id
                name
              }
              profile {
                dob
                gender
                pan
                aadhar
                social {
                  facebook
                  linkedin
                }
                covid
                jobHistory {
                  specialisationName
                  hospital
                  department
                  specialisation
                  start
                  end
                }
                education {
                  specialisationName
                  course
                  specialisation
                  name
                  year
                }
                references {
                  name
                  hospital
                  designation
                  phone
                  email
                }
              }
              languageKnownNew
              additionalTrainingNew
              workedOn {
                id
                softwarename
              }
              professionalRegistration {
                number
                registrationType {
                  id
                  name
                }
              }
              avatar {
                location
                fileName
                uri
                bucket
                region
                mimeType
                secureUrl
                url
                isSecured
                cdnUrl
              }
              correspondenceAddress {
                address
                city
                state
                pin
                country
              }
              departments {
                id
                name
              }
            }
            job {
              id
              code
              state
              organization {
                id
                name
              }
              company {
                id
                name
              }
              department {
                id
                departmentType {
                  name
                }
              }
              qualification {
                id
                name
                profession{
                  name
                }
              }
              maxRate
              minRate
              specialization {
                name
                description
                id
              }
              shift
              numberOfJobs
              numberOfDays
              jobDescription
              contactDetail {
                address {
                  city
                  state
                  pin
                }
              }
              branch {
                id
                brand
                subBrand
                jobLocation {
                  hospitalName
                  branchName
                }
              }
              numExperience {
                name
                id
              }
              consequtiveTime {
                start {
                  year
                  month
                  date
                  hour
                  ts
                }
                end {
                  year
                  month
                  date
                  hour
                  ts
                }
              }
            }
          }
        }
    }
    `;
    graphqlRequest(query).then((response) => {
      // console.log("listApp", response)
      dispatch(
        setUserApplication(response.data.withAuth.listNurseApplications)
      );
      setApplications(response.data.withAuth.listNurseApplications);
      if (Object.keys(selectedProfile).length > 0) {
        setSelectedProfile(
          response.data.withAuth.listNurseApplications.filter(
            (data) => selectedProfile.id === data.id && data
          )[0]
        );
      }
    });
  };

  // let fetchRecommendedUser = () => {
  //   const query = `
  //     query{
  //       getRecommendedUser(jobId:"${selectedData?.id}") {
  //         id
  //         marksPercentage
  //         avatar {
  //           url
  //         }
  //         emails {
  //           isPrimary
  //           address
  //         }
  //         phoneNumbers {
  //           isPrimary
  //           number
  //         }
  //         name {
  //           full
  //         }
  //         address {
  //           state
  //           address
  //           country
  //           city
  //           country
  //           pin
  //         }
  //         specialization {
  //           name
  //           id
  //         }
  //         numExperience {
  //           id
  //           name
  //         }
  //         workCity {
  //           id
  //           name
  //         }
  //         qualification {
  //           id
  //           name
  //         }
  //         professionalType {
  //           id
  //           name
  //         }
  //         profile {
  //           dob
  //           gender
  //           pan
  //           aadhar
  //           social {
  //             facebook
  //             linkedin
  //           }
  //           covid
  //           jobHistory {
  //             hospital
  //             department
  //             specialisation
  //             specialisationName
  //             start
  //             end
  //           }
  //           education {
  //             course
  //             specialisation
  //             specialisationName
  //             name
  //             year
  //           }
  //           references {
  //             name
  //             hospital
  //             designation
  //             phone
  //             email
  //           }
  //         }
  //         languageKnownNew
  //         additionalTrainingNew
  //         workedOn {
  //           id
  //           softwarename
  //         }
  //         professionalRegistration {
  //           number
  //           registrationType {
  //             id
  //             name
  //           }
  //         }
  //         avatar {
  //           location
  //           fileName
  //           uri
  //           bucket
  //           region
  //           mimeType
  //           secureUrl
  //           url
  //           isSecured
  //           cdnUrl
  //         }
  //         correspondenceAddress {
  //           address
  //           city
  //           state
  //           pin
  //           country
  //         }
  //         departments {
  //           id
  //           name
  //         }
  //         averageRating
  //         numberOfRatings
  //       }
  //   }
  //     `;
  //   graphqlRequest(query).then((response) => {
  //     // console.log("2>>>>>>>>>>>",response);
  //     // console.log(">>>>2>>",response.length);
  //     setRecommendedUser(response?.data?.getRecommendedUser);
  //   });
  // };

  let fetchRecommendedUser = () => {
    const query = `
      query{
        getRecommendedUser(jobId:"${selectedData?.id}") {
          absoluteMatch {
            id
            marksPercentage
            avatar {
              url
            }
            emails {
              isPrimary
              address
            }
            phoneNumbers {
              isPrimary
              number
            }
            name {
              full
            }
            address {
              state
              address
              country
              city
              country
              pin
            }
            specialization {
              name
              id
            }
            numExperience {
              id
              name
            }
            workCity {
              id
              name
            }
            qualification {
              id
              name
            }
            professionalType {
              id
              name
            }
            profile {
              dob
              gender
              pan
              aadhar
              social {
                facebook
                linkedin
              }
              covid
              jobHistory {
                hospital
                department
                specialisation
                start
                end
              }
              education {
                course
                specialisation
                name
                year
              }
              references {
                name
                hospital
                designation
                phone
                email
              }
            }
            languageKnownNew
            additionalTrainingNew
            workedOn {
              id
              softwarename
            }
            professionalRegistration {
              number
              registrationType {
                id
                name
              }
            }
            avatar {
              location
              fileName
              uri
              bucket
              region
              mimeType
              secureUrl
              url
              isSecured
              cdnUrl
            }
            correspondenceAddress {
              address
              city
              state
              pin
              country
            }
            departments {
              id
              name
            }
            averageRating
            numberOfRatings
          }
          similarMatch {
            id
            marksPercentage
            avatar {
              url
            }
            emails {
              isPrimary
              address
            }
            phoneNumbers {
              isPrimary
              number
            }
            name {
              full
            }
            address {
              state
              address
              country
              city
              country
              pin
            }
            specialization {
              name
              id
            }
            numExperience {
              id
              name
            }
            workCity {
              id
              name
            }
            qualification {
              id
              name
            }
            professionalType {
              id
              name
            }
            profile {
              dob
              gender
              pan
              aadhar
              social {
                facebook
                linkedin
              }
              covid
              jobHistory {
                hospital
                department
                specialisation
                start
                end
              }
              education {
                course
                specialisation
                name
                year
              }
              references {
                name
                hospital
                designation
                phone
                email
              }
            }
            languageKnownNew
            additionalTrainingNew
            workedOn {
              id
              softwarename
            }
            professionalRegistration {
              number
              registrationType {
                id
                name
              }
            }
            avatar {
              location
              fileName
              uri
              bucket
              region
              mimeType
              secureUrl
              url
              isSecured
              cdnUrl
            }
            correspondenceAddress {
              address
              city
              state
              pin
              country
            }
            departments {
              id
              name
            }
            averageRating
            numberOfRatings
          }
        }
    }
      `;
    graphqlRequest(query).then((response) => {
      console.log("jobDetails>>",response);
      // console.log(">>>>2>>",response.length);
      setRecommendedUser(response?.data?.getRecommendedUser?.similarMatch);
      setRecommendedUserAbsolute(response?.data?.getRecommendedUser?.absoluteMatch);
    });
  };

  const getProfileList = () => {
    const absoluteMatchCount = recommendedUserAbsolute.length;
    if (absoluteMatchCount >= 5) {
      return recommendedUserAbsolute.slice(0, 5);
    } else {
      const needed = 5 - absoluteMatchCount;
      return [
        ...recommendedUserAbsolute,
        ...recommendedUser.slice(0, needed)
      ];
    }
  };

  const navigate = useNavigate();

  // window.history.pushState(null, null, window.location.href);
  // window.onpopstate = function () {
  //   window.history.go(1);
  // };

  useEffect(() => {
    window.location.hash = "#";
    window.location.hash = "##"; //again because google chrome don't insert first hash into history
    window.onhashchange = function () {
      window.location.hash = "#";
    };
  }, []);

  useEffect(() => {
    fetchSelectedJobDetails();
    fetchApplications();
    fetchRecommendedUser();
  }, [selectedData]);


  let handleSelect = (data) => {
    setSelectedProfile(data);
    setSelectedRecommendedUser([]);
  };

  let handleRecommendedUserSelect = (data) => {
    setSelectedRecommendedUser(data);
    setSelectedProfile({});

    // setLink1((link1) => {
    //   return link1.filter((value, i) => i !== index);
    // });

    // invitedUser = recommendedUser.filter(
    //   (value) => {
    //     console.log(selectedRecommendedUser.id, "Main hun gian");
    //   }
    //   // value?.id !== selectedRecommendedUser.id
    // );
  };

  const [inviting, setInviting] = useState(false);
  let valueToBeMatched;

  useEffect(() => {
    if (
      whetherInvitedUser.find(
        (value) => value?.user?.id === selectedRecommendedUser.id
      )
    ) {
      invitedUser.push({
        [selectedRecommendedUser.id]: true,
      });
      setInvitedUser1(invitedUser);
    } else {
      invitedUser.push({
        [selectedRecommendedUser.id]: false,
      });
      setInvitedUser1(invitedUser);
    }
    // console.log(whetherInvitedUser, "Hello hehe");
    // console.log(invitedUser, "Hello hehe again");

    fetchRecommendedUser();
  }, [selectedRecommendedUser, whetherInvitedUser]);

  // console.log(invitedUser1, "Hello hehe 2");

  // console.log(recommendedUser, "hello hehe 4");

  // console.log(
  //   new Date(Number(selectedRecommendedUser?.profile?.jobHistory[0]?.start)),
  //   selectedRecommendedUser?.profile?.jobHistory[0]?.start,
  //   "Main hun gian"
  // );
  // console.log(
  //   invitedUser1.map((x) => Object.keys(x).find((value) => x[value])),
  //   "Main hun gian 2"
  // );

  for (var i = 0; i <= invitedUser1.length; i++) {
    for (var key in invitedUser1[i]) {
      // console.log(invitedUser1[i][key], "o");
      valueToBeMatched = invitedUser1[i][key];
    }
  }

  // const keyToBeMatched = invitedUser1.map((x) => Object.keys(x)[0]);
  // console.log(keyToBeMatched[0], "Main hun gian 2");

  let handleReject = () => {
    let query = `
      mutation{
        mutationWithAuth(token:"${token}"){
          update{
            cancelMiniJobApplication(application:"${selectedProfile?.id}"
            )
          }
        }
      }
      `;

    graphqlRequest(query).then((response) => {
      if (response) {
        message.success("Candidate Rejected!");
        fetchApplications();
        fetchSelectedJobDetails();
      }
    });
  };

  let handleAccept = () => {
    let query = `
      mutation{       
        mutationWithAuth(token: "${token}")
        {         
           update{   
            approveApplicationV1(application: "${selectedProfile.id}")
          } 
        } 
      }
      `;

    graphqlRequest(query).then((response) => {
      message.success("Accept Successfully!");
      fetchApplications();
      fetchSelectedJobDetails();

      if (jobDetails?.numberOfJobs === jobDetails?.roleOfferedCount) {
        handleCloseDetail();
        fetchOpenJob();

        navigate("/locum/open-jobs");
      }
    });
  };
  let handleShortlist = () => {
    let query = `
    mutation{
      mutationWithAuth(token: "${token}"){
        update{
          shortlistApplication(application: "${selectedProfile.id}")
        }
      }
    }
      `;

    graphqlRequest(query).then((response) => {
      if (response?.errors) {
        message?.error("Application is already shortlisted!!");
      } else {
        message.success("Shortlist Successfully!");
        fetchApplications();
        fetchSelectedJobDetails();
      }
      // if (response?.data?.withAuth?.inviteUser) {
      //   message?.success("Email has been sent");
      //   setInviting(!inviting);
      //   fetchRecommendedUser();
      //   fetchSelectedJobDetails();
      // }

      // message.success("Shortlist Successfully!");
      // fetchApplications();
      // fetchSelectedJobDetails();
    });
  };
  let handleInterview = () => {
    setIsInterviewSlotOpen(!isInterviewSlotOpen);
  };

  //action for recommandation
  let handlejobInvite = () => {
    let query = `
      query{
        withAuth(token:"${token}"){
          inviteUser(jobId:"${selectedData.id}",userId:"${selectedRecommendedUser.id}")
        }
      }
      `;

    graphqlRequest(query).then((response) => {
      if (response?.errors) {
        // console.log("JOD DATA", response?.errors);
        message?.error("Already invited!");
      }
      if (response?.data?.withAuth?.inviteUser) {
        message?.success("Email has been sent");
        setInviting(!inviting);
        fetchRecommendedUser();
        fetchSelectedJobDetails();
      }
    });
  };

  let handleInterviewSlotClose = () => {
    setIsInterviewSlotOpen(!isInterviewSlotOpen);
  };

  const getFileExtension = (url) => {
    const parts = url.split(".");
    // console.log(">>>>>>>1>>>>>>>",parts)
    // const data1 = parts[parts.length - 1].split("?")[0];
    // console.log(">>>>>>>2>>>>>>>",data1)
    // return data1
    return parts[parts.length - 1].split("?")[0];
  };
  const handleDownLoadResume = (type) => {
    // setIsResumeDownloading(true);
    // const query = `{
    //       withAuth(token:"${token}"){
    //        getUserPdf(id: "${selectedRecommendedUser?.id}")
    //       }
    //     }`;

    // graphqlRequest(query).then((response) => {
    //   if (response.data.withAuth.getUserPdf) {
    //     setIsResumeDownloading(false);
    //     const data = response?.data?.withAuth?.getUserPdf;
    //     const linkSource = `data:application/pdf;base64,${data}`;
    //     let a = document.createElement("a");
    //     a.href = linkSource;
    //     a.download = `${selectedRecommendedUser.name.full}_resume.pdf`;
    //     a.click();
    //     message.success("Download Completed.");
    //   } else {
    //     setIsResumeDownloading(false);
    //     message.error("Currently we don't have File to Download.");
    //   }
    // });

    setIsResumeDownloading(true);
    const query = `{
          withAuth(token:"${token}"){
           getUserPdf(id: "${selectedRecommendedUser?.id}")
          }
        }`;

    graphqlRequest(query).then((response) => {
      console.log("response>>>", response);
      if (response.data.withAuth.getUserPdf) {
        setIsResumeDownloading(false);
        const data = response?.data?.withAuth?.getUserPdf;
        const linkSource = `data:application/pdf;base64,${data}`;
        let a = document.createElement("a");
        a.href = linkSource;
        a.download = `${selectedRecommendedUser.name.full}_resume.pdf`;
        a.click();
        message.success("Download Completed.");
      } else {
        setIsResumeDownloading(false);
        message.error("Currently we don't have File to Download.");
      }
      //   else{
      //     setIsResumeDownloading(true);
      // const query = `{
      //   withAuth(token:"${token}"){
      //    getUser(id: "${selectedRecommendedUser?.id}"){
      //     documents {
      //       docType {
      //         name
      //       }
      //       files {
      //         secureUrl
      //         uri
      //       }
      //     }
      //    }
      //   }
      // }`;

      // graphqlRequest(query).then((response) => {
      //   // console.log("46-->", response)
      //   if (response?.data?.withAuth?.getUser?.documents?.length >= 0) {
      //     setIsResumeDownloading(false);
      //     const data = response.data.withAuth.getUser.documents.find((doc) => {
      //       return doc.docType?.name === "CV";
      //     });

      //     // console.log("data--->", data);
      //     // if (!data) {
      //     //   setIsResumeDownloading(false);
      //     //   message.error("Professional CV Not Available");
      //     // }

      //     const linkSource = data.files[0].uri;

      //     fetch(linkSource)
      //       .then((response) => response.blob())
      //       .then((blob) => {
      //         const filename = `${
      //           selectedRecommendedUser.name.full
      //         }_resume.${getFileExtension(linkSource)}`;
      //         // console.log("fileName----->", filename)
      //         const url = window.URL.createObjectURL(blob);
      //         const a = document.createElement("a");
      //         a.href = url;
      //         a.download = filename;
      //         a.style.display = "none";
      //         document.body.appendChild(a);
      //         a.click();
      //         window.URL.revokeObjectURL(url);
      //       })
      //       .catch((error) => {
      //         console.error("Error downloading the file:", error);
      //       });
      //   } else {
      //     setIsResumeDownloading(false);
      //     message.error("Currently we don't have File to Download.");
      //   }
      // });
      //   }
    });
  };

  const handleDownLoadResumeApplicant = (type) => {
    // setIsResumeDownloading(true);
    setIsResumeDownloading(true);
    const query = `{
          withAuth(token:"${token}"){
           getUserPdf(id: "${selectedProfile?.professional?.id}")
          }
        }`;

    graphqlRequest(query).then((response) => {
      console.log("responseapplicant: ", response);
      if (response.data.withAuth.getUserPdf) {
        setIsResumeDownloading(false);
        const data = response?.data?.withAuth?.getUserPdf;
        const linkSource = `data:application/pdf;base64,${data}`;
        let a = document.createElement("a");
        a.href = linkSource;
        a.download = `${selectedProfile?.professional?.name?.full}_resume.pdf`;
        a.click();
        message.success("Download Completed.");
      } else {
        setIsResumeDownloading(false);
        message.error("Currently we don't have File to Download.");
      }
      //    else {
      //     setIsResumeDownloading(true);
      //     const query = `{
      //       withAuth(token:"${token}"){
      //        getUser(id: "${selectedProfile?.professional?.id}"){
      //         documents {
      //           docType {
      //             name
      //           }
      //           files {
      //             secureUrl
      //             uri
      //           }
      //         }
      //        }
      //       }
      //     }`;

      //     graphqlRequest(query).then((response) => {
      //       // console.log("46-->", response)
      //       if (response?.data?.withAuth?.getUser?.documents?.length >= 0) {
      //         setIsResumeDownloading(false);
      //         const data = response.data.withAuth.getUser.documents.find((doc) => {
      //           return doc.docType?.name === "CV";
      //         });
      // // console.log("1-->>>", selectedProfile?.professional?.id)
      //         // if (!data) {
      //         //   setIsResumeDownloading(false);
      //         //   message.error("Professional CV Not Available");
      //         // }

      //         const linkSource = data.files[0].uri;

      //         fetch(linkSource)
      //           .then((response) => response.blob())
      //           .then((blob) => {
      //             const filename = `${
      //               selectedProfile?.professional?.name?.full
      //             }_resume.${getFileExtension(linkSource)}`;
      //             // console.log("filenaame>>>", filename);
      //             const url = window.URL.createObjectURL(blob);
      //             const a = document.createElement("a");
      //             a.href = url;
      //             a.download = filename;
      //             a.style.display = "none";
      //             document.body.appendChild(a);
      //             a.click();
      //             window.URL.revokeObjectURL(url);
      //           })
      //           .catch((error) => {
      //             console.error("Error downloading the file:", error);
      //           });
      //       } else {
      //         setIsResumeDownloading(false);
      //         message.error("Currently we don't have File to Download.");
      //       }
      //     });
      //   }
    });
  };

  const timeString = "14:00";
  const formattedTime = moment(timeString, "hh:mm").format("hh:a");
  function setStateColor(state) {
    if (state === "OPEN") {
      return "#FAEDCD";
    }
    if (state === "ACCEPTED") {
      return "#BBD6B8";
    }
    if (state === "REJECTED") {
      return "#FFB4B4";
    }
    if (state === "PENDING_TEST") {
      return "#FFB4B4";
    }
    if (state === "REVIEW") {
      return "#F3DEBA"; //yellow
    }
    if (state === "PENDING_INTERVIEW") {
      return "#C9EEFF";
    }
    if (state === "WITHDRAW") {
      return "#FFB4B4";
    }
  }

  // console.log(
  //   jobDetails?.numberOfJobs,
  //   "gian",
  //   "sunio",
  //   jobDetails?.roleOfferedCount
  // );

  // console.log("1---", selectedProfile?.professional);

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackLocumPostRejected = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;
    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);
      //set event data
      window.Moengage.track_event(
        eventHelpers.LOCUM_RJCT
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackLocumPostAccepted = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;
    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);
      //set event data
      window.Moengage.track_event(
        eventHelpers.LOCUM_ACPT
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackLocumPostShortlisted = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;
    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.LOCUM_INTRV
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackLocumPostInterview = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.LOCUM_SHRTLST
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackInviteToApply = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.INVT_2_APPLY
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackDownloadResume = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DOWNLD_RESUME
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  // console.log("1047-->", jobDetails);
  console.log("1090>>>", recommendedUser)
  console.log("1091>>>", recommendedUserAbsolute)

  return (
    <div className="jobDetails-container">
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
          marginBottom: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          width: "97%",
        }}
      >
        <Typography
          sx={{
            // marginLeft: "4rem",
            fontWeight: "600 !important",
            // right: "14.8rem",
            color: "#4D4D4D",
          }}
        >
          Job Summary{" "}
          {jobDetails?.timingsType === "NON_CONSEQUTIVE"
            ? "(NON CONSECUTIVE)"
            : "(CONSECUTIVE)"}
          <div className="nc-display">
            {jobDetails?.timingsType === "NON_CONSEQUTIVE" ? (
              <>
                {jobDetails?.nonConsequtiveTime?.weekdays.map((data) => (
                  <p>{data}</p>
                ))}
              </>
            ) : null}
          </div>
        </Typography>
        {/* <HighlightOffOutlinedIcon
          sx={{ cursor: "pointer",  }}
          onClick={handleCloseDetail}
        /> */}
        <img
          src={CrossIcon}
          onClick={() => {
            handleCloseDetail();
            // fetchOpenJob();
            // navigate(0);
          }}
          style={{ cursor: "pointer" }}
        />
      </Grid>

      <Box>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            display: "flex",
            justifyContent: "space-between",
            pb: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingLeft: "20px",
            }}
          >
            <h3
              style={{
                color: "#000",
                fontWeight: 800,
                fontSize: "16px",
                margin: "0px",
              }}
            >
              Job ID:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {selectedData.jobId}
              </span>{" "}
            </h3>
            <h3
              style={{
                color: "#000",
                fontWeight: 800,
                fontSize: "16px",
                margin: "0px",
              }}
            >
              Hospital:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.branch?.jobLocation?.hospitalName}
              </span>{" "}
            </h3>
            <h3
              style={{
                color: "#000",
                fontWeight: 800,
                fontSize: "16px",
                margin: "0px",
              }}
            >
              Department:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.department?.departmentType?.name}
              </span>{" "}
            </h3>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              Qualification:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.qualification?.name}
              </span>{" "}
            </h2>
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              Specialization:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.specialization?.name}
              </span>{" "}
            </h2>
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              Type of Professional:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.candidateType?.name}
              </span>{" "}
            </h2>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              Shift Start Date :{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.timingsType === "CONSEQUTIVE" &&
                jobDetails?.consequtiveTime?.start?.ts &&
                !isNaN(Number(jobDetails.consequtiveTime.start.ts))
                  ? (() => {
                      const date = new Date(
                        Number(jobDetails.consequtiveTime.start.ts)
                      );
                      const dateParts = date.toDateString().split(" ");
                      if (dateParts.length >= 3) {
                        dateParts.splice(1, 0, ",");
                      }
                      return dateParts.join(" ");
                    })()
                  : jobDetails?.nonConsequtiveTime?.start?.ts &&
                    !isNaN(Number(jobDetails.nonConsequtiveTime.start.ts))
                  ? (() => {
                      const date = new Date(
                        Number(jobDetails.nonConsequtiveTime.start.ts)
                      );
                      const dateParts = date.toDateString().split(" ");
                      if (dateParts.length >= 3) {
                        dateParts.splice(1, 0, ",");
                      }
                      return dateParts.join(" ");
                    })()
                  : ""}
                {/* // ? new Date(
                  //     Number(jobDetails.consequtiveTime.start.ts)
                  //   ).toDateString().split(' ').join(', ')
                  // : jobDetails?.nonConsequtiveTime?.start?.ts &&
                  //   !isNaN(Number(jobDetails.nonConsequtiveTime.start.ts))
                  // ? new Date(
                  //     Number(jobDetails.nonConsequtiveTime.start.ts)
                  //   ).toDateString().split(' ').join(', ')
                  // : ""} */}
              </span>{" "}
            </h2>
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              Shift End Date:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.timingsType === "CONSEQUTIVE" &&
                jobDetails?.consequtiveTime?.start?.ts &&
                !isNaN(Number(jobDetails.consequtiveTime.start.ts))
                  ? (() => {
                      const date = new Date(
                        Number(jobDetails.consequtiveTime.end.ts)
                      );
                      const dateParts = date.toDateString().split(" ");
                      if (dateParts.length >= 3) {
                        dateParts.splice(1, 0, ",");
                      }
                      return dateParts.join(" ");
                    })()
                  : jobDetails?.nonConsequtiveTime?.start?.ts &&
                    !isNaN(Number(jobDetails.nonConsequtiveTime.start.ts))
                  ? (() => {
                      const date = new Date(
                        Number(jobDetails.nonConsequtiveTime.end.ts)
                      );
                      const dateParts = date.toDateString().split(" ");
                      if (dateParts.length >= 3) {
                        dateParts.splice(1, 0, ",");
                      }
                      return dateParts.join(" ");
                    })()
                  : ""}
                {/* // ? new Date(
                  //     Number(jobDetails.consequtiveTime.end.ts)
                  //   ).toDateString()
                  // : jobDetails?.nonConsequtiveTime?.start?.ts &&
                  //   !isNaN(Number(jobDetails.nonConsequtiveTime.start.ts))
                  // ? new Date(
                  //     Number(jobDetails.nonConsequtiveTime.end.ts)
                  //   ).toDateString()
                  // : ""} */}
              </span>{" "}
            </h2>
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              Experience:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.numExperience?.name}
              </span>{" "}
            </h2>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingRight: "20px",
            }}
          >
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              Shift Start Time:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.startTime &&
                  moment(jobDetails.startTime, "hh:mm").isValid() &&
                  moment(jobDetails.startTime, "hh:mm").format("hh:mm A")}
              </span>{" "}
            </h2>
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              Shift End Time:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.endTime &&
                  moment(jobDetails.endTime, "hh:mm").isValid() &&
                  moment(jobDetails.endTime, "hh:mm").format("hh:mm A")}
              </span>{" "}
            </h2>
            <h2
              style={{
                color: "#000",
                fontWeight: 800,
                margin: "0",
                fontSize: "16px",
              }}
            >
              No. of Professionals:{" "}
              <span style={{ color: "#808080", fontWeight: "400" }}>
                {jobDetails?.numberOfJobs}
              </span>{" "}
            </h2>
          </Box>
        </Box>
        <Box style={{ borderBottom: "2px solid #000", padding: "5px 0" }}>
          <p
            style={{
              margin: "0",
              fontSize: "16px",
              fontWeight: "700",
              color: "#034585",
              textAlign: "center",
            }}
          >
            Complete Job Details
          </p>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 0",
            borderBottom: "2px solid #000",
          }}
        >
          <Typography sx={{ paddingLeft: "20px", fontSize: "16px !important" }}>
            Total Applicants:<span>{applications?.length}</span>
          </Typography>
          <Typography sx={{ fontSize: "16px !important" }}>
            Role Offered:<span>{jobDetails?.roleOfferedCount}</span>
          </Typography>
          <Typography sx={{ fontSize: "16px !important" }}>
            Shortlisted:<span>{jobDetails?.shortListCount}</span>
          </Typography>
          <Typography sx={{ fontSize: "16px !important" }}>
            Interview Scheduled:<span>{jobDetails?.interviewCount}</span>
          </Typography>
          <Typography
            sx={{ paddingRight: "20px", fontSize: "16px !important" }}
          >
            Rejected:<span>{jobDetails?.rejectedCount}</span>
          </Typography>
        </div>
      </Box>

      <div className="overview-bottom">
        <div className="overview-userlist-wrapper">
          <div className="userlist-top">
            <div className="header-container">
              <h3>Applications</h3>
            </div>
            {AppliedUserApplication?.length === 0 && (
              <h3 style={{ textAlign: "center" }}>No Data Available</h3>
            )}
            <div className="scroll-user">
              {AppliedUserApplication?.map((data) => {
                return (
                  <div onClick={() => handleSelect(data)}>
                    {" "}
                    <ProfileList
                      selectedProfile={selectedProfile || Avtar}
                      key={data.id}
                      data={data}
                      img={user}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="userlist-bottom">
            <div className="header-container">
              <h3>Suggested profiles</h3>
            </div>
            <div className="scroll-user">
              {getProfileList()?.length === 0 && (
                <h3 style={{ textAlign: "center" }}>No Data Available</h3>
              )}
              {getProfileList()?.map((data) => {
                return (
                  <div onClick={() => handleRecommendedUserSelect(data)}>
                    {" "}
                    <RecommendationUser
                      key={data.id}
                      data={data}
                      img={user}
                      selectedRecommendedUser={selectedRecommendedUser}
                    />{" "}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="overview-profile-wrapper">
          {Object.keys(selectedProfile).length == 0 &&
            Object.keys(selectedRecommendedUser).length == 0 && (
              <div
                style={{
                  position: "relative",
                  top: "50%",
                  left: "40%",
                  fontWeight: "700",
                  width: "50%",
                }}
              >
                Please select a profile to view details
              </div>
            )}
          {/* for applied application */}
          {Object.keys(selectedProfile).length > 0 && (
            <>
              <div className="profile-top">
                <div className="ov-wrapper">
                  <h3>Profile Details</h3>
                  <div className="ov-down">
                    <div className="ov-top">
                      <div className="profileov-left">
                        <div className="dp-img">
                          {selectedProfile?.professional?.marksPercentage >=
                            60 && (
                            <div
                              className="dp-badge-hover"
                              data-message="Jobizo Assessment Qualified"
                            >
                              <img src={badge} className="dp-badge" />
                            </div>
                          )}
                          <img
                            src={
                              selectedProfile?.professional?.avatar?.url ||
                              Avtar
                            }
                            className={
                              selectedProfile?.professional?.marksPercentage >=
                              60
                                ? "badge-border"
                                : ""
                            }
                          />
                        </div>

                        <div className="user-info-right">
                          <div>
                            <h3>
                              {/* {selectedProfile?.professional?.name?.salutation}{" "} */}
                              {selectedProfile?.professional?.name?.full}
                              <img src={tickMark} className="dp-tickmark" />
                            </h3>

                            <button
                              style={{
                                color: "black",
                                backgroundColor: `${setStateColor(
                                  selectedProfile?.state
                                )}`,
                              }}
                            >
                              {selectedProfile?.state === "OPEN"
                                ? "APPLIED"
                                : selectedProfile?.state === "PENDING_INTERVIEW"
                                ? "PENDING INTERVIEW"
                                : selectedProfile?.state === "PENDING_TEST"
                                ? "PENDING_TEST"
                                : selectedProfile?.state}
                            </button>
                          </div>
                          <div className="userData-right">
                            <p>
                              Qualification:{" "}
                              <span>
                                {
                                  selectedProfile?.professional?.qualification
                                    ?.name
                                }
                              </span>
                            </p>
                            <p>
                              Exp:{" "}
                              <span>
                                {
                                  selectedProfile?.professional?.numExperience
                                    ?.name
                                }{" "}
                              </span>
                              {/* years */}
                            </p>
                            {/* <p>
                              Gender:{" "}
                              <span>
                                {selectedProfile?.professional?.profile?.gender}
                              </span>
                            </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="profileov-right">
                        {selectedProfile?.state === "OPEN" && (
                          <>
                            <button
                              className="reject"
                              onClick={() => {
                                handleReject();
                                trackLocumPostRejected();
                              }}
                            >
                              Reject
                            </button>
                            <button
                              className="accept"
                              onClick={() => {
                                handleAccept();
                                trackLocumPostAccepted();
                              }}
                            >
                              Accept
                            </button>

                            <button
                              className="schedule-interview"
                              onClick={() => {
                                handleInterview();
                                trackLocumPostShortlisted();
                              }}
                            >
                              Schedule Interview
                            </button>

                            <button
                              className="accept"
                              onClick={() => {
                                handleShortlist();
                                trackLocumPostInterview();
                              }}
                            >
                              Shortlist
                            </button>
                          </>
                        )}
                        {/* done */}
                        {selectedProfile?.state === "REJECTED" && (
                          <>
                            <button className="reject-disabled" disabled>
                              Reject
                            </button>
                            <button className="accept-disabled" disabled>
                              Accept
                            </button>

                            <button
                              className="schedule-interview-disabled"
                              disabled
                            >
                              Schedule Interview
                            </button>

                            <button className="accept-disabled" disabled>
                              Shortlist
                            </button>
                          </>
                        )}
                        {/* done */}
                        {selectedProfile?.state === "ACCEPTED" && (
                          <>
                            <button className="reject-disabled" disabled>
                              Reject
                            </button>
                            <button className="accept-disabled" disabled>
                              Accept
                            </button>

                            <button
                              className="schedule-interview-disabled"
                              disabled
                            >
                              Schedule Interview
                            </button>

                            <button className="accept-disabled" disabled>
                              Shortlist
                            </button>
                          </>
                        )}
                        {/* done */}
                        {selectedProfile?.state === "PENDING_TEST" && null}
                        {selectedProfile?.state === "PENDING_INTERVIEW" && (
                          <>
                            <button
                              className="reject"
                              onClick={() => handleReject()}
                            >
                              Reject
                            </button>
                            <button
                              className="accept"
                              onClick={() => handleAccept()}
                            >
                              Accept
                            </button>

                            <button
                              className="schedule-interview-disabled"
                              disabled
                            >
                              Schedule Interview
                            </button>

                            <button className="accept-disabled" disabled>
                              Shortlist
                            </button>
                          </>
                        )}
                        {selectedProfile?.state === "REVIEW" && (
                          <>
                            <button
                              className="reject"
                              onClick={() => handleReject()}
                            >
                              Reject
                            </button>
                            <button
                              className="accept"
                              onClick={() => handleAccept()}
                            >
                              Accept
                            </button>

                            <button
                              className="schedule-interview"
                              onClick={() => handleInterview()}
                            >
                              Schedule Interview
                            </button>

                            <button
                              className="accept"
                              onClick={() => handleShortlist()}
                            >
                              Shortlist
                            </button>
                          </>
                        )}

                        {selectedProfile?.state === "SCHEDULED_INTERVIEW" && (
                          <>
                            <button
                              className="reject"
                              onClick={() => handleReject()}
                            >
                              Reject
                            </button>
                            <button
                              className="accept"
                              onClick={() => handleAccept()}
                            >
                              Accept
                            </button>

                            <button
                              className="schedule-interview-disabled"
                              onClick={() => handleInterview()}
                            >
                              Schedule Interview
                            </button>

                            <button
                              className="accept-disabled"
                              onClick={() => handleShortlist()}
                            >
                              Shortlist
                            </button>
                          </>
                        )}

                        {selectedProfile?.state === "FINISHED" && null}
                        {selectedProfile?.state === "WITHDRAW" && null}
                      </div>
                    </div>
                    <div style={{ padding: "30px", marginTop: "30px" }}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography
                            sx={{
                              fontWeight: "700 !important",
                              fontSize: "22px !important",
                            }}
                          >
                            Previous Experience
                          </Typography>{" "}
                        </AccordionSummary>
                        <AccordionDetails>
                          {selectedProfile?.professional?.profile?.jobHistory?.map(
                            (item) => (
                              <div className="previous-experience-container">
                                <h3
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.hospital}
                                </h3>
                                <div className="pe-info-details">
                                  <ul>
                                    <li>
                                      <p
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      >
                                        {/* Department : {item?.specialisationName} */}
                                        Department : {item?.department}
                                      </p>
                                    </li>

                                    {/* <hr /> */}
                                    <li>
                                      <p
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      >
                                        Job Start Month/Year :
                                        {new Date(
                                          Number(item?.start)
                                        ).toLocaleDateString("en-IN", {
                                          month: "2-digit",
                                          year: "numeric",
                                        })}
                                      </p>
                                    </li>

                                    <li>
                                      <p
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      >
                                        Job End Month/Year :
                                        {new Date(
                                          Number(item?.end)
                                        ).toLocaleDateString("en-IN", {
                                          month: "2-digit",
                                          year: "numeric",
                                        })}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )
                          )}
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography
                            sx={{
                              fontWeight: "700 !important",
                              fontSize: "22px !important",
                            }}
                          >
                            Education
                          </Typography>{" "}
                        </AccordionSummary>
                        <AccordionDetails>
                          {selectedProfile?.professional?.profile?.education?.map(
                            (item) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                }}
                              >
                                <ul style={{ margin: "0" }}>
                                  <li>
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {item?.name}: {item?.year}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            )
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="ov-bottom">
                      {/* <h3 style={{ marginTop: "5px" }}>Previous Experience1</h3> */}
                      {/* <div
                        className="pe-container"
                        style={{
                          overflowY: !prevExpHandleViewMoreValue
                            ? "hidden"
                            : "auto",
                        }}
                      >
                        {selectedProfile?.professional?.profile?.jobHistory?.map((item)=> 
                          
                          <div className="previous-experience-container">
                          <h3 style={{ fontSize: "16px" }}>{item?.hospital}</h3>
                          <div className="pe-info-details">
                            <p>{item?.specialisation}</p>
                            <hr />
                            <p>{item?.start} {item?.end}</p>
                          </div>
                        </div>
                       
                        )}
                      </div> */}
                      {/* <div className="button-view-more">
                        <button
                          onClick={() =>
                            setPrevExpHandleViewMoreValue(
                              !prevExpHandleViewMoreValue
                            )
                          }
                        >
                          {!prevExpHandleViewMoreValue
                            ? "View More"
                            : "View less"}
                          <img
                            style={{
                              transform: !prevExpHandleViewMoreValue
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                              marginInlineStart: "8px",
                            }}
                            src={arrow}
                            alt="arrow"
                          />{" "}
                        </button>
                      </div> */}
                      {/* <h3>Education</h3> */}
                      {/* <div
                        className="profile-bottom"
                        style={{
                          overflowY: !educationHandleViewMoreValue
                            ? "hidden"
                            : "auto",
                        }}
                      > */}
                      {/* <div className="previous-experience-container">
                          <div className="pe-info-details">
                           { selectedProfile?.professional?.profile?.education?.map((item)=>
                           <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}
                            >
                              <span>{item?.name}</span>
                              <span>{item?.year}</span>
                            </div>)}
                           
                          </div>
                        </div> */}

                      {/* <div className="previous-experience-container">
          <h3>Hospital Name</h3>
          <div className="pe-info-details">
            <p>Department</p>
            <hr/>
            <p>Start Date - End Date</p>
          </div>
        </div> */}
                      {/* </div> */}
                      {/* <div
                        className="button-view-more"
                        style={{ paddingBottom: "20px" }}
                      >
                        <button
                          onClick={() =>
                            setEducationHandleViewMoreValue(
                              !educationHandleViewMoreValue
                            )
                          }
                        >
                          {!educationHandleViewMoreValue
                            ? "View More"
                            : "View less"}
                          <img
                            style={{
                              transform: !educationHandleViewMoreValue
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                              marginInlineStart: "8px",
                            }}
                            src={arrow}
                            alt="arrow"
                          />{" "}
                        </button>
                      </div> */}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleDownLoadResumeApplicant("SEL_USER")
                          }
                          sx={{
                            width: "220px",
                            height: "50px",
                            padding: "10px 30px",
                            marginBottom: "30px",
                          }}
                          variant="outlined"
                        >
                          {isresumeDownloading ? (
                            <CircularProgress
                              style={{ width: "30px", height: "30px" }}
                            />
                          ) : (
                            "Download Resume"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* for recommended user */}
          {Object.keys(selectedRecommendedUser).length > 0 && (
            <>
              <div className="profile-top">
                <div className="ov-wrapper">
                  <h3>Profile Details</h3>
                  <div className="ov-down">
                    <div className="ov-top">
                      <div className="profileov-left">
                        <div className="dp-img-User">
                          {selectedRecommendedUser?.marksPercentage >= 60 && (
                            <div
                              className="dp-badge-hover-User"
                              data-message="Jobizo Assessment Qualified"
                            >
                              <img src={badge} className="dp-badge" />
                            </div>
                          )}
                          <img
                            src={selectedRecommendedUser?.avatar?.url || Avtar}
                            className={
                              selectedRecommendedUser?.marksPercentage >= 60
                                ? "badge-border"
                                : ""
                            }
                          />

                          {/* <img
                            src={selectedRecommendedUser?.avatar?.url || Avtar}
                          /> */}
                        </div>
                        <div className="user-info-right">
                          <h3>
                            {selectedRecommendedUser?.name?.full}
                            <img src={tickMark} className="dp-tickmark-User" />
                          </h3>
                          <div>
                            <p
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Qualification:
                              <p>
                                {selectedRecommendedUser?.qualification?.name}
                              </p>
                            </p>
                            <p
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Experience:
                              <span>
                                {selectedRecommendedUser?.numExperience?.name}
                              </span>
                            </p>
                            {/* <p
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Gender:
                              <span>
                                {selectedRecommendedUser?.profile?.gender}
                              </span>
                            </p> */}
                          </div>
                        </div>
                      </div>
                      {valueToBeMatched === true ? (
                        <div className="profileov-right">
                          <button className="invite-sent" disabled>
                            Invited
                          </button>
                        </div>
                      ) : (
                        <div className="profileov-right">
                          <button
                            className="schedule-interview-invite"
                            onClick={() => {
                              handlejobInvite();
                              trackInviteToApply();
                            }}
                          >
                            Invite to Apply
                          </button>
                        </div>
                      )}
                    </div>
                    <div>
                      <div style={{ padding: "30px" }}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Typography
                              sx={{
                                fontWeight: "700 !important",
                                fontSize: "22px !important",
                              }}
                            >
                              Previous Experience
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {selectedRecommendedUser?.profile?.jobHistory?.map(
                              (item) => (
                                <div className="previous-experience-container">
                                  <h3
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item?.hospital}
                                  </h3>
                                  <div className="pe-info-details">
                                    <ul>
                                      <li>
                                        <p
                                          style={{
                                            fontSize: "18px",
                                          }}
                                        >
                                          Department :{" "}
                                          {/* {item?.specialisationName} */}
                                          {item?.department}
                                        </p>
                                      </li>

                                      {/* <hr /> */}
                                      <li>
                                        <p
                                          style={{
                                            fontSize: "18px",
                                          }}
                                        >
                                          Job Start Month/Year :
                                          {new Date(
                                            Number(item?.start)
                                          ).toLocaleDateString("en-IN", {
                                            month: "2-digit",
                                            year: "numeric",
                                          })}
                                        </p>
                                      </li>

                                      <li>
                                        <p
                                          style={{
                                            fontSize: "18px",
                                          }}
                                        >
                                          Job End Month/Year :
                                          {new Date(
                                            Number(item?.end)
                                          ).toLocaleDateString("en-IN", {
                                            month: "2-digit",
                                            year: "numeric",
                                          })}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )
                            )}
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              sx={{
                                fontWeight: "700 !important",
                                fontSize: "22px !important",
                              }}
                            >
                              Education
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {selectedRecommendedUser?.profile?.education?.map(
                              (item) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "5px",
                                  }}
                                >
                                  <ul style={{ margin: "0" }}>
                                    <li>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "500",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        {item?.name}:
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {item?.year}
                                      </span>{" "}
                                    </li>
                                  </ul>
                                </div>
                              )
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      <div></div>
                    </div>
                    <div className="ov-bottom">
                      {/* <h3 style={{ marginTop: "5px" }}>Previous Experience</h3>
                      <div
                        className="pe-container"
                        style={{
                          overflowY: !prevExpHandleViewMoreValue
                            ? "hidden"
                            : "auto",
                        }}
                      >

                       { selectedRecommendedUser?.profile?.jobHistory?.map((item)=>
                       <div className="previous-experience-container">
                          <h3 style={{ fontSize: "16px" }}>{item?.hospital}</h3>
                          <div className="pe-info-details">
                            <p>{item?.specialisation}</p>
                            <hr />
                            <p>{item?.start} "" {item?.end}</p>
                          </div>
                        </div>)}

                      </div> */}
                      {/* <div className="button-view-more">
                        <button
                          onClick={() =>
                            setPrevExpHandleViewMoreValue(
                              !prevExpHandleViewMoreValue
                            )
                          }
                        >
                          {!prevExpHandleViewMoreValue
                            ? "View More"
                            : "View less"}
                          <img
                            style={{
                              transform: !prevExpHandleViewMoreValue
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                              marginInlineStart: "8px",
                            }}
                            src={arrow}
                            alt="arrow"
                          />{" "}
                        </button>
                      </div> */}

                      {/* <h3>Education</h3>
                      <div
                        className="profile-bottom"
                        style={{
                          overflowY: !educationHandleViewMoreValue
                            ? "hidden"
                            : "auto",
                        }}
                      >
                        <div className="previous-experience-container">
                          <div className="pe-info-details">
                            {selectedRecommendedUser?.profile?.education?.map((item)=>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}
                            >
                              <span>{item?.name}</span>
                              <span>{item?.year}</span>
                            </div>)}
                           
                          </div>
                        </div>

                        
                      </div> */}
                      {/* <div
                        className="button-view-more"
                        style={{ paddingBottom: "20px" }}
                      >
                        <button
                          onClick={() =>
                            setEducationHandleViewMoreValue(
                              !educationHandleViewMoreValue
                            )
                          }
                        >
                          {!educationHandleViewMoreValue
                            ? "View More"
                            : "View less"}
                          <img
                            style={{
                              transform: !educationHandleViewMoreValue
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                              marginInlineStart: "8px",
                            }}
                            src={arrow}
                            alt="arrow"
                          />{" "}
                        </button>
                      </div> */}

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={() => {
                            handleDownLoadResume("REC_USER");
                            trackDownloadResume();
                          }}
                          sx={{
                            width: "220px",
                            height: "50px",
                            padding: "10px 30px",
                            marginBottom: "30px",
                          }}
                          variant="outlined"
                        >
                          {isresumeDownloading ? (
                            <CircularProgress
                              style={{ width: "30px", height: "30px" }}
                            />
                          ) : (
                            "Download Resume"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        // open={true}
        open={isInterviewSlotOpen}
        onClose={handleInterviewSlotClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InterviewSlotInput
          fetchSelectedJobDetails={fetchSelectedJobDetails}
          handleInterviewSlotClose={handleInterviewSlotClose}
          fetchApplications={fetchApplications}
          selectedProfile={selectedProfile}
          jobDetails={jobDetails}
        />
      </Modal>
    </div>
  );
};

export default JobDetailsModal;

import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../components/Common/JobsHeader/JobsHeader";
import completed from "../../asset/postjob/darkcompleted.svg";
import pending from "../../asset/postjob/pending.svg";
import calendar from "../../asset/postjob/calendar.svg";
import check from "../../asset/postjob/check.svg";
import uncheck from "../../asset/postjob/uncheck.svg";
import * as locales from "react-date-range/dist/locale";
import { Calendar } from "react-date-range";
import { format } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import InfoIcon from "@mui/icons-material/Info";
import SuggestionBox from "./SuggestionBox";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Modal as AntModal, Button } from "antd";

import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  TextareaAutosize
} from "@mui/material";
import "./LocumJobForm.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenJobMatches,
  setActiveTab,
  setRecommendedUsers,
  setRecommendedUserAbsolute,
  setUserJobPost,
} from "../../redux/userReducer";
import { Link, useNavigate } from "react-router-dom";
import graphqlRequest from "../../requestMethod";
import Jobpopup from "../../components/PopUp/Jobpopup/Jobpopup";
import moment from "moment/moment";
import Slote from "./Slote/Slote";
import ToggleDays from "../../components/Common/ToogleDays";
import { StaticTimePicker } from "@mui/x-date-pickers";
import eventHelpers from "../../Helpers/Event";
import useWindowDimensions from "../../components/Common/WindowDimention/windowDimention";
import { toast } from "react-toastify";

function LocumJobForm(props) {
  let dispatch = useDispatch();
  let [openStartDate, setOpenStartDate] = useState(false);
  let [openEndDate, setOpenEndDate] = useState(false);
  let [hospitals, setHospitals] = useState([]);
  let [profession, setProfession] = useState([]);
  let [qualification, setQualification] = useState([]);
  let [department, setDepartment] = useState([]);
  let [specialization, setSpecialization] = useState([]);
  let [experience, setExperience] = useState([]);
  let [additionalSkill, setAdditionalSkill] = useState([]);
  let [skillDepartmentID, setSkillDepartmentID] = useState([]);
  let [skillProfessionID, setSkillProfessionID] = useState([]);

  const [selectedSkills, setSelectedSkills] = useState([]);
  let [creditLimitData, setCreditLimitData] = useState("");
  let [utilizedLimitData, setUtilizedLimitData] = useState("");
  let [limitExceeded, setLimitExceeded] = useState(false);
  let [chooseBranchId, setChooseBranchId] = useState([]);
  const [visible, setVisible] = useState(false);

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let [professionalDetails, setProfessionalDetails] = useState({
    hospitals: "",
    profession: "",
    department: "",
    qualification: "",
    specialization: "",
    experience: "",
    additionalSkill: "",
  });

  let [formData, setFormData] = useState({
    hospitals: "",
    hospitalFormId: "",
    department: "",
    departmentFormId: "",
    departmentTypeFormId: "",
    profession: "",
    professionFormId: "",
    qualification: "",
    qualificationFormId: "",
    specialization: "",
    specializationFormId: "",
    experience: "",
    experienceFormId: "",
    additionalSkill: "",
    additionalSkillIDs: "",
    isFood: false,
    isAccommodation: false,
    isTransport: false,
    others: "",
    name: "",
    email: "",
    contact: null,
    jobDescription: "",
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    noOfProfessional: "",
    rateOfferedPerHour: "",
    creditLimitF: "",
    utilizedLimitF: "",
  });

  const [isOthersSelected, setIsOthersSelected] = useState(false);
  // const [othersText, setOthersText] = useState("");
  // let [contactDetails, setContactDetails] = useState({
  //   name: "",
  //   email: "",
  //   contact: null,
  // });

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { height, width } = useWindowDimensions();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const fillForm = (suggestion) => {
    //  console.log("72-->", suggestion);
    //  const amit = suggestion.additionalSkill
    //  console.log("amit", amit);
    const additionalSkills = suggestion.additionalSkill.map(
      (skill) => skill.name
    );
    const allAdditionalSkills = additionalSkills.join(", ");
    // console.log("1-->",allAdditionalSkills)
    const additionalSkillsIDs = suggestion.additionalSkill.map(
      (skill) => skill.id
    );
    const allAdditionalSkillsIDs = additionalSkillsIDs.join(", ");
    // console.log("2-->",allAdditionalSkillsIDs)
    setFormData({
      hospitals: suggestion.branch.jobLocation.hospitalName,
      hospitalFormId: suggestion.branch.id,
      department: suggestion.department.departmentType.name,
      departmentFormId: suggestion.department.id,
      departmentTypeFormId: suggestion.department.departmentType.id,
      profession: suggestion.candidateType.name,
      professionFormId: suggestion.candidateType.id,
      qualification: suggestion.qualification.name,
      qualificationFormId: suggestion.qualification.id,
      specialization: suggestion.specialization.name,
      specializationFormId: suggestion.specialization.id,
      experience: suggestion.numExperience.name,
      experienceFormId: suggestion.numExperience.id,
      additionalSkill: allAdditionalSkills,
      additionalSkillIDs: allAdditionalSkillsIDs,
      isAccommodation: suggestion.isAccommodation,
      others: suggestion.others,
      isFood: suggestion.isFood,
      isTransport: suggestion.isTransport,
      name: suggestion.contactDetail.contactPerson,
      email: suggestion.contactDetail.email,
      contact: suggestion.contactDetail.phone,
      jobDescription: suggestion.jobDescription,
      startDate: `${suggestion.consequtiveTime.start.year}/${suggestion.consequtiveTime.start.month}/${suggestion.consequtiveTime.start.date}`,
      endDate: `${suggestion.consequtiveTime.end.year}/${suggestion.consequtiveTime.end.month}/${suggestion.consequtiveTime.end.date}`,
      startTime: `${suggestion.consequtiveTime.start.hour}`,
      endTime: `${suggestion.consequtiveTime.end.hour}`,
      noOfProfessional: suggestion.numberOfJobs,
      rateOfferedPerHour: suggestion.maxRate,
      creditLimitF: suggestion.branch.creditLimit,
      utilizedLimitF: suggestion.branch.utilizedLimit,
    });
  };

  const reset = () => {
    // console.log("RESET");
    //Professional Details Reset
    professionalDetails.hospitals = "";
    professionalDetails.profession = "";
    professionalDetails.department = "";
    professionalDetails.qualification = "";
    professionalDetails.specialization = "";
    professionalDetails.experience = "";
  };

  const now = new Date();
  now.setMinutes(0);
  now.setSeconds(0);
  let [jobDetails, setJobDetails] = useState({
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    noOfProfessional: "",
    rateOfferedPerHour: "",
  });
  let [jobDetailsError, setJobDetailsError] = useState({
    test: 1,
  });
  let [professionalDetailsError, setProfessionalDetailsError] = useState({
    test: 1,
  });
  let [additionalFacilities, setAdditionalFacilities] = useState({
    isFood: false,
    isAccommodation: false,
    isTransport: false,
    // others: "",
  });
  let [jobDescription, setJobDescription] = useState("");
  let [othersAdditional, setOthersAdditional] = useState("");
  let [jobDescriptionList, setJobDescriptionList] = useState([]);
  let [FetchContactDetails, setFetchContactDetails] = useState([]);
  let [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    contact: null,
  });
  let [openModel, setOpenModel] = useState(false);
  let [totalCost, setTotalCost] = useState({});
  let [recommendedUser, setRecommendedUser] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabled1, setDisabled1] = useState(true);
  const [todatDate, setTodayDate] = useState(new Date().toDateString());
  const [minJobRate, setMinJobRate] = useState(0);
  const [shiftList, setShiftList] = useState([]);
  const [weekdays, setWeekDays] = useState([]);
  const [type, setType] = useState("CONSECUTIVE");
  const [isReccuring, SetIsReccuring] = useState(false);
  const [totalDays, setTotalDays] = useState(null);
  const [weekDaysResultDay, setWeekDaysResultDay] = useState(0);
  const [isJobPostLoading, setJobPostLoading] = useState(false);
  const [weekdaysError, setWeekdaysError] = useState({});
  // console.log("todatDate",todatDate,jobDetails?.endDate);
  const [displayedSkills, setDisplayedSkills] = useState(10);
  const [selectedSkills1, setSelectedSkills1] = useState([]);
  let navigate = useNavigate();

  // let { token } = useSelector((state) => state.user);
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;

  function handleProfessionalState(except) {
    for (let key of Object.keys(professionalDetails)) {
      if (!except.includes(key)) {
        professionalDetails[key] = "";
        // console.log({ key, value: professionalDetails[key] });
      }
    }
  }

  let handleDropdown = (value, name) => {
    setProfessionalDetails({ ...professionalDetails, [name]: value });
    // setFormData({ ...formData, [name]: value });

    setJobDetails({
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      noOfProfessional: "",
      rateOfferedPerHour: "",
    });
    setJobDescription("");
    setContactDetails({
      name: "",
      email: "",
      contact: "",
    });
  };

  let handleContactDetails = (e) => {
    let selectedData = FetchContactDetails.filter(
      (data) => data?.name === e.target.value
    );
    // setDisabled1(selectedData[0].contact === null ? false : true);
    setContactDetails({
      name: e.target.value,
      email: selectedData[0].email,
      contact: selectedData[0].contact === null ? "" : selectedData[0].contact,
    });
  };

  function fetchHospitalDropdownData() {
    const query = `
    query{
      withAuth(token:"${token}"){
        listMiniJobBranches{
          id
          jobLocation {
            hospitalName
            branchName
          }
          creditLimit
          utilizedLimit
       }
       }
     }
  `;
    graphqlRequest(query).then((response) => {
      // console.log("responseBranch>>", response);
      if (response.data.withAuth.listMiniJobBranches.length === 1) {
        setHospitals(response.data.withAuth.listMiniJobBranches);
        setProfessionalDetails({
          ...professionalDetails,
          hospitals: response.data.withAuth.listMiniJobBranches[0]?.name,
        });
      } else {
        // setCreditLimitData(response.data.withAuth.listMiniJobBranches);
        setHospitals(response.data.withAuth.listMiniJobBranches);
        setProfessionalDetails({
          ...professionalDetails,
          hospitals: "",
          profession: "",
          department: "",
          qualification: "",
          specialization: "",
          experience: "",
        });
        setFormData({
          ...formData,
          hospitals: "",
          profession: "",
          department: "",
          qualification: "",
          specialization: "",
          experience: "",
        });
      }
    });
  }
  function fetchContactDetailsUser() {
    const query = `
    query{
      withAuth(token:"${token}"){
        listOrganizationUsers {
          id
          email
          contact
          name
        }
       }
     }
  `;
    graphqlRequest(query).then((response) => {
      setFetchContactDetails(response.data.withAuth.listOrganizationUsers);
    });
  }
  let fetchJobDescription = () => {
    const query = `
    {
      withAuth(token: "${token}") {
        listJobDescription {
          profession
          experience
          specialization
          description
        }
      }
    }
    `;
    graphqlRequest(query).then((response) => {
      setJobDescriptionList(response.data.withAuth.listJobDescription);
    });
  };

  let PostLocumJob = () => {
    setJobPostLoading(true);

    let hospitalId =
      hospitals.filter(
        (data) =>
          data.jobLocation.hospitalName === professionalDetails.hospitals
      )[0]?.id || formData.hospitalFormId;
    //  console.log("322-->", hospitalId)
    let departmentId =
      department.filter(
        (data) => data.departmentType?.name === professionalDetails.department
      )[0]?.id || formData.departmentFormId;

    let professionId =
      profession.filter(
        (data) => data?.name === professionalDetails.profession
      )[0]?.id || formData.professionFormId;

    let qualificationId =
      qualification.filter(
        (data) => data?.name === professionalDetails.qualification
      )[0]?.id || formData.qualificationFormId;

    let specializationId =
      specialization.filter(
        (data) => data?.name === professionalDetails.specialization
      )[0]?.id || formData.specializationFormId;

    let experienceId =
      experience.filter(
        (data) => data?.name === professionalDetails.experience
      )[0]?.id || formData.experienceFormId;

    // let additionalSkillsID =
    // additionalSkill.map(
    //   (data) => data?.name === professionalDetails.additionalSkill
    // )[0]?.id

    // let additionalSkillsID = professionalDetails.additionalSkill?.map(skill =>
    //   additionalSkill.find(data => data.name === skill)?.id
    // ).filter(Boolean);

    // let additionalSkillsString = JSON.stringify(additionalSkillsID);

    // console.log("professionalDetails>>>", professionalDetails);
    // console.log("formDetails>>>", formData);
    // console.log("additionalSkill388>>>", additionalSkill);
    let additionalSkillsID = [];
    //  var temp =
    //   formData?.additionalSkill || professionalDetails?.additionalSkill;
    // // console.log("temp>>", temp)
    // if (formData?.additionalSkill) {
    //   additionalSkillsID = temp
    //     ?.map(
    //       (skill) => additionalSkill.find((data) => data.name === skill)?.id
    //     )
    //     .filter(Boolean);
    // }
    var temp =
      formData?.additionalSkill || professionalDetails?.additionalSkill;
    // console.log("temp>>", temp);
    // if (temp) {
    //   additionalSkillsID = temp
    //     ?.map((skill) => {
    //       // console.log("skill", skill);
    //       const matchedSkill = additionalSkill.find(
    //         (data) => data.name === skill.name
    //       );
    //       // console.log("matchedSkill", matchedSkill);
    //       return matchedSkill ? matchedSkill.id : null;
    //     })

    //     .filter(Boolean);
    // }

    if (Array.isArray(temp)) {
      additionalSkillsID = temp
        ?.map((skill) => {
          // console.log("skill", skill);
          const matchedSkill = additionalSkill?.find(
            (data) => data.name === skill.name
          );
          // console.log("matchedSkill", matchedSkill);
          return matchedSkill ? matchedSkill.id : null;
        })
        .filter(Boolean);
    }

    // console.log("additionalSkillsID", additionalSkillsID);
    let additionalSkillsString = JSON.stringify(additionalSkillsID);

    // additionalSkill: [${additionalSkillsID.join(', ')}],

    // console.log("additionalSkillsID", additionalSkillsID);
    // console.log("additionalSkillsString", additionalSkillsString);

    let name = contactDetails.name || formData.name;
    let email = contactDetails.email || formData.email;
    let contact = contactDetails.contact || formData.contact;

    // startTime:"${new Date(jobDetails?.startTime).getHours()}:00",
    // endTime:"${new Date(jobDetails?.endTime).getHours()}:00",

    const startTimeInMinutes = new Date(jobDetails?.startTime).getHours() * 60 + new Date(jobDetails?.startTime).getMinutes();
  const endTimeInMinutes = new Date(jobDetails?.endTime).getHours() * 60 + new Date(jobDetails?.endTime).getMinutes();

    let query = `
    mutation postMiniJobV1($JD:String!){
      mutationWithAuth(token:"${token}"){
        fabricate{
          postMiniJobV1(
          branch: "${hospitalId}"
          department: "${departmentId}"
          profession: "${professionId}"
          qualification: "${qualificationId}"
          specialization: "${specializationId}"
          experience: "${experienceId}"
          additionalSkill: ${additionalSkillsString},
          maxRate: ${jobDetails.rateOfferedPerHour}
          applicants: ${jobDetails.noOfProfessional}
          description: $JD
          timing: {
            type: ${type},
            start: "${format(jobDetails.startDate, "yyyy-MM-dd")}",
            end: "${format(jobDetails.endDate, "yyyy-MM-dd")}",
            weekdays: [${convertToWeekName()}],
            startTime: "${format(new Date(jobDetails.startTime), "HH:mm")}",
            endTime: "${format(new Date(jobDetails.endTime), "HH:mm")}",
          }
          isFood: ${additionalFacilities.isFood},
          isAccommodation: ${additionalFacilities.isAccommodation},
          isTransport:${additionalFacilities.isTransport},
          others:"${othersAdditional}",
          contact:{
            contactPerson:"${name}"
            email:"${email}"
            phone:"${contact}"
          }
          ){
            id
            code
            state
          }
        }
      }
    }
    `;

    graphqlRequest(query, {
      JD: jobDescription || formData.jobDescription,
    }).then((response) => {
      // console.log("404>>>>>", response);
      if (response?.data?.mutationWithAuth?.fabricate?.postMiniJobV1) {
        setJobPostLoading(false);
        message.success("Job Post Done");

        window.dataLayer.push({
          event: "purchase",
          department: professionalDetails?.department,
          profession: professionalDetails?.profession,
          qualification: professionalDetails?.qualification,
          experience: professionalDetails?.experience,
          professional_charges: totalCost?.hospital,
        });
        dispatch(
          setUserJobPost(response.data.mutationWithAuth.fabricate.postMiniJobV1)
        );
        getRecommendedUser(
          response.data.mutationWithAuth.fabricate.postMiniJobV1.id
        );
      } else {
        setJobPostLoading(false);
        if (response.errors[0].message === "Credit limit exceeded!") {
          // message.error("Credit Limit Exceeded, Please pay pending bills to use further!");
          setVisible(true);
        }else if(response.errors[0].message === "No object found for Rate"){
          message.error("Please check minimum Rate for posting");
        } else {
          message.error(response.errors[0].message);
        }
      }
      // dispatch(setRecommendedUser([]));
    });
  };
  // console.log("424-->", totalCost)
  // function getRecommendedUser(jobId) {
  //   let query = `
  //   {
  //     getRecommendedUser(jobId:"${jobId}") {
  //       id
  //       marksPercentage
  //       avatar {
  //         url
  //       }
  //       emails {
  //         isPrimary
  //         address
  //       }
  //       phoneNumbers {
  //         isPrimary
  //         number
  //       }
  //       name {
  //         full
  //       }
  //       address {
  //         state
  //         address
  //         country
  //         city
  //         country
  //         pin
  //       }
  //       specialization {
  //         name
  //         id
  //       }
  //       numExperience {
  //         id
  //         name
  //       }
  //       workCity {
  //         id
  //         name
  //       }
  //       qualification {
  //         id
  //         name
  //       }
  //       professionalType {
  //         id
  //         name
  //       }
  //       profile {
  //         dob
  //         gender
  //         pan
  //         aadhar
  //         social {
  //           facebook
  //           linkedin
  //         }
  //         covid
  //         jobHistory {
  //           hospital
  //           department
  //           specialisation
  //           start
  //           end
  //         }
  //         education {
  //           course
  //           specialisation
  //           name
  //           year
  //         }
  //         references {
  //           name
  //           hospital
  //           designation
  //           phone
  //           email
  //         }
  //       }
  //       languageKnownNew
  //       additionalTrainingNew
  //       workedOn {
  //         id
  //         softwarename
  //       }
  //       professionalRegistration {
  //         number
  //         registrationType {
  //           id
  //           name
  //         }
  //       }
  //       avatar {
  //         location
  //         fileName
  //         uri
  //         bucket
  //         region
  //         mimeType
  //         secureUrl
  //         url
  //         isSecured
  //         cdnUrl
  //       }
  //       correspondenceAddress {
  //         address
  //         city
  //         state
  //         pin
  //         country
  //       }
  //       departments {
  //         id
  //         name
  //       }
  //       averageRating
  //       numberOfRatings
  //     }
  // }
  //   `;
  //   graphqlRequest(query, {}).then((response) => {
  //     // console.log("1-->", response)
  //     // setRecommendedUser(response.data.getRecommendedUser);
  //     if (response.data.getRecommendedUser.length === 0) {
  //       // dispatch(setRecommendedUsers(profile));
  //       // dispatch(setOpenJobMatches());
  //       dispatch(
  //         setActiveTab({ index: 3, locumActive: 1, LocumJobToggle: true })
  //       );
  //       navigate("/locum/open-jobs");
  //     } else {
  //       dispatch(
  //         setRecommendedUsers(addRating(response.data.getRecommendedUser))
  //       );
  //       dispatch(setOpenJobMatches());
  //       // navigate("/locum/open-jobs");
  //     }
  //   });
  // }
  function getRecommendedUser(jobId) {
    let query = `
    {
      getRecommendedUser(jobId:"${jobId}") {
        absoluteMatch {
          id
          marksPercentage
          avatar {
            url
          }
          emails {
            isPrimary
            address
          }
          phoneNumbers {
            isPrimary
            number
          }
          name {
            full
          }
          address {
            state
            address
            country
            city
            country
            pin
          }
          specialization {
            name
            id
          }
          numExperience {
            id
            name
          }
          workCity {
            id
            name
          }
          qualification {
            id
            name
          }
          professionalType {
            id
            name
          }
          profile {
            dob
            gender
            pan
            aadhar
            social {
              facebook
              linkedin
            }
            covid
            jobHistory {
              hospital
              department
              specialisation
              start
              end
            }
            education {
              course
              specialisation
              name
              year
            }
            references {
              name
              hospital
              designation
              phone
              email
            }
          }
          languageKnownNew
          additionalTrainingNew
          workedOn {
            id
            softwarename
          }
          professionalRegistration {
            number
            registrationType {
              id
              name
            }
          }
          avatar {
            location
            fileName
            uri
            bucket
            region
            mimeType
            secureUrl
            url
            isSecured
            cdnUrl
          }
          correspondenceAddress {
            address
            city
            state
            pin
            country
          }
          departments {
            id
            name
          }
          averageRating
          numberOfRatings
        }
        similarMatch {
          id
          marksPercentage
          avatar {
            url
          }
          emails {
            isPrimary
            address
          }
          phoneNumbers {
            isPrimary
            number
          }
          name {
            full
          }
          address {
            state
            address
            country
            city
            country
            pin
          }
          specialization {
            name
            id
          }
          numExperience {
            id
            name
          }
          workCity {
            id
            name
          }
          qualification {
            id
            name
          }
          professionalType {
            id
            name
          }
          profile {
            dob
            gender
            pan
            aadhar
            social {
              facebook
              linkedin
            }
            covid
            jobHistory {
              hospital
              department
              specialisation
              start
              end
            }
            education {
              course
              specialisation
              name
              year
            }
            references {
              name
              hospital
              designation
              phone
              email
            }
          }
          languageKnownNew
          additionalTrainingNew
          workedOn {
            id
            softwarename
          }
          professionalRegistration {
            number
            registrationType {
              id
              name
            }
          }
          avatar {
            location
            fileName
            uri
            bucket
            region
            mimeType
            secureUrl
            url
            isSecured
            cdnUrl
          }
          correspondenceAddress {
            address
            city
            state
            pin
            country
          }
          departments {
            id
            name
          }
          averageRating
          numberOfRatings
        }
      }
  }
    `;
    graphqlRequest(query, {}).then((response) => {
      console.log("1-->", response.data.getRecommendedUser)
      // setRecommendedUser(response.data.getRecommendedUser);
      if (response.data.getRecommendedUser.similarMatch.length === 0) {
        // dispatch(setRecommendedUsers(profile));
        // dispatch(setOpenJobMatches());
        dispatch(
          setActiveTab({ index: 3, locumActive: 1, LocumJobToggle: true })
        );
        navigate("/locum/open-jobs");
      } else {
        dispatch(
          // setRecommendedUsers(addRating(response.data.getRecommendedUser)),
          setRecommendedUsers(addRating(response.data.getRecommendedUser.similarMatch)),
          // setRecommendedUserAbsolute(addRating(response.data.getRecommendedUser.absoluteMatch))
        );
        dispatch(
          // setRecommendedUsers(addRating(response.data.getRecommendedUser)),
          // setRecommendedUsers(addRating(response.data.getRecommendedUser.similarMatch)),
          setRecommendedUserAbsolute(addRating(response.data.getRecommendedUser.absoluteMatch))
        );
        dispatch(setOpenJobMatches());
        // navigate("/locum/open-jobs");
      }
    });
  }
  function randomNumBetween3And5() {
    const nums = [3, 4, 5];
    const randomIndex = Math.floor(Math.random() * nums.length);
    return nums[randomIndex];
  }
  function addRating(arr) {
    return arr.map((obj) => ({ ...obj, rating: randomNumBetween3And5() }));
  }
  function convertToWeekName() {
    let result = weekdays.map((data) => {
      if (data === 0) {
        return "SUNDAY";
      }
      if (data === 1) {
        return "MONDAY";
      }
      if (data === 2) {
        return "TUESDAY";
      }
      if (data === 3) {
        return "WEDNESDAY";
      }
      if (data === 4) {
        return "THURSDAY";
      }
      if (data === 5) {
        return "FRIDAY";
      }
      if (data === 6) {
        return "SATURDAY";
      }
    });

    return result;
  }
  let setmyJobDescription = () => {
    let filterJobDescription = [];
    filterJobDescription = jobDescriptionList?.filter((data) => {
      if (
        data.experience.toLowerCase() ===
          professionalDetails.experience.toLowerCase() &&
        data.specialization.toLowerCase() ===
          professionalDetails.specialization.toLowerCase() &&
        data.profession === professionalDetails.profession
      ) {
        return data;
      }
    });
    filterJobDescription.length > 0 &&
      setJobDescription(filterJobDescription?.[0]?.description);
  };
  let fetchLocumJobRate = () => {
    let hospitalId =
      formData.hospitalFormId ||
      hospitals.filter(
        (data) =>
          data.jobLocation.hospitalName === professionalDetails.hospitals
      )[0]?.id;

    let departmentId =
      formData.departmentFormId ||
      department.filter(
        (data) => data.departmentType?.name === professionalDetails.department
      )[0]?.id;

    let professionId =
      formData.professionFormId ||
      profession.filter(
        (data) => data?.name === professionalDetails.profession
      )[0]?.id;

    let qualificationId =
      formData.qualificationFormId ||
      qualification.filter(
        (data) => data?.name === professionalDetails.qualification
      )[0]?.id;

    let specializationId =
      formData.specializationFormId ||
      specialization.filter(
        (data) => data?.name === professionalDetails.specialization
      )[0]?.id;

    let experienceId =
      formData.experienceFormId ||
      experience.filter(
        (data) => data?.name === professionalDetails.experience
      )[0]?.id;
        // startTime:"${new Date(jobDetails?.startTime).getHours()}:00",
          // endTime:"${new Date(jobDetails?.endTime).getHours()}:00",
    let query = `
    query{
      withAuth(token:"${token}"){
        getMiniJobCostV1 (
          branch: "${hospitalId}"
        department: "${departmentId}"
        profession: "${professionId}"
        qualification: "${qualificationId}"
        specialization: "${specializationId}"
        experience: "${experienceId}"
          maxRate: ${jobDetails.rateOfferedPerHour}
          applicants: ${jobDetails.noOfProfessional}
          days: ${
            isReccuring
              ? weekDaysResultDay
              : findDiff(
                  jobDetails.startDate,
                  jobDetails.endDate,
                  jobDetails.startTime,
                  jobDetails.endTime
                )
          }
          startTime: "${format(new Date(jobDetails.startTime), "HH:mm")}",
          endTime: "${format(new Date(jobDetails.endTime), "HH:mm")}",
          
        )
        {
          shift
          nurse
          hospital
          margin
          gst
          total
          marginPercentage
        }
       }
     }
    `;
    graphqlRequest(query, { JD: jobDescription }).then((response) => {
      console.log("694-->", response.data.withAuth.getMiniJobCostV1)
      setTotalCost(response.data.withAuth.getMiniJobCostV1);
    });
  };
  // console.log("3-->",formData.hospitalFormId)
  var creditLimit;
  var utilizedLimit;
  // console.log(formData);
  useEffect(() => {
    let timer;
    if (openErrorSnackbar) {
      timer = setTimeout(() => {
        setOpenErrorSnackbar(false);
        setErrorMessage('');
      }, 6000); // Duration in milliseconds (6 seconds)
    }

    return () => clearTimeout(timer);
  }, [openErrorSnackbar]);

  useEffect(() => {
    let creditLimitFormCheck = formData.creditLimitF;
    let utilizedLimitFormCheck = formData.utilizedLimitF;
    let isFormDataLimitExceeded = creditLimitFormCheck < utilizedLimitFormCheck;

    if (isFormDataLimitExceeded && !openErrorSnackbar) {
      setErrorMessage(
        "Credit Limit Exceeded, Please pay pending bills to use further!"
      );
      setOpenErrorSnackbar(true);
    }
  }, [formData.hospitalFormId]);

 

  function fetchDepartmentDropDown() {
    // console.log("2-->",formData.hospitalFormId)
    // console.log("formData.hospitalFormId:", formData.hospitalFormId);
    // console.log("hospitals:", hospitals);
    // let id =
    //   hospitals.filter(
    //     (data) =>
    //       data.jobLocation.hospitalName === professionalDetails.hospitals
    //   )[0].id || formData.hospitalFormId;

    let branch = hospitals.filter(
      (data) => data.jobLocation.hospitalName === professionalDetails.hospitals
    );
    // console.log("branchData>>", branch);
    // var creditLimit
    creditLimit = branch[0].creditLimit;
    // var utilizedLimit
    utilizedLimit = branch[0].utilizedLimit;
    let id = branch[0].id || formData.hospitalFormId;

    // console.log("creditLimit>>", creditLimit);
    // console.log("utilizedLimit>>", utilizedLimit);
    setCreditLimitData(creditLimit);
    setUtilizedLimitData(utilizedLimit);

    // comparison of creditLimit and utilizedLimit

    let isCreditLimitExceeded = creditLimit < utilizedLimit;

    setLimitExceeded(isCreditLimitExceeded);

    // Check if credit limit is initially exceeded
    if (isCreditLimitExceeded) {
      // Show error Snackbar immediately
      setErrorMessage(
        "Credit Limit Exceeded, Please pay pending bills to use further!"
      );
      setOpenErrorSnackbar(true);
    }

    // Set interval to check and show error Snackbar every 2 seconds
    // setInterval(() => {
    //   isCreditLimitExceeded = creditLimit < utilizedLimit;
    //   if (isCreditLimitExceeded) {
    //     setErrorMessage(
    //       "Credit Limit Exceeded, Please pay pending bills to use further!"
    //     );
    //     setOpenErrorSnackbar(true);
    //   }
    // }, 2000);

    let query = `
    query{
      withAuth(token:"${token}"){
        listMiniJobDepartments(branch:"${id}") {
          id
          departmentType{
            id
            name
          }
          branch{
            id
            creditLimit
            utilizedLimit
          }
        }
       }
     }
    `;
    graphqlRequest(query).then((response) => {
      // console.log("department-->", response);
      if (response.data.withAuth.listMiniJobDepartments.length === 1) {
        setDepartment(response.data.withAuth.listMiniJobDepartments);

        setProfessionalDetails({
          ...professionalDetails,
          department: response.data.withAuth.listMiniJobBranches[0]?.name,
        });
        // console.log("department2-->",response.data.withAuth.listMiniJobDepartments[0]?.name)
        // console.log("professionalDetails-->",professionalDetails)
      } else {
        setDepartment(response.data.withAuth.listMiniJobDepartments);
        // setAdditionalSkill(response.data.withAuth.listMiniJobDepartments);
        setProfessionalDetails({
          ...professionalDetails,
          department: "",
          profession: "",
          qualification: "",
          specialization: "",
          experience: "",
          additionalSkill: "",
        });
        setFormData({
          ...formData,
          department: "",
          profession: "",
          qualification: "",
          specialization: "",
          experience: "",
          additionalSkill: "",
        });
      }

      setDepartment(response.data.withAuth.listMiniJobDepartments);
      
      // setAdditionalSkill(response.data.withAuth.listMiniJobDepartments);
    });
  }

  function fetchProfessionsDropDown() {
    let hospitalId =
      hospitals.filter(
        (data) =>
          data.jobLocation.hospitalName === professionalDetails.hospitals
      )[0].id || formData.hospitalFormId;

    let departmentId =
      department.filter(
        (data) => data.departmentType?.name === professionalDetails.department
      )[0].id || formData.departmentFormId;

    let query = `
    query{
      withAuth(token:"${token}"){
        listMiniJobProfessions (branch:"${hospitalId}" department:"${departmentId}") {
          id
          name
          postCost
        }
       }
     }
    `;
    graphqlRequest(query).then((response) => {
      if (response.data.withAuth.listMiniJobProfessions.length === 1) {
        setProfession(response.data.withAuth.listMiniJobProfessions);
        setProfessionalDetails({
          ...professionalDetails,
          profession: response.data.withAuth.listMiniJobProfessions[0]?.name,
        });
        if (professionalDetails.profession) {
          if (
            professionalDetails.profession ===
            response.data.withAuth.listMiniJobProfessions[0]?.name
          ) {
            fetchQualificationDropDown();
          }
        }
      } else {
        setProfessionalDetails({
          ...professionalDetails,
          profession: "",
          qualification: "",
          specialization: "",
          experience: "",
        });
        setFormData({
          ...formData,
          profession: "",
          qualification: "",
          specialization: "",
          experience: "",
        });
        setProfession(response.data.withAuth.listMiniJobProfessions);
      }
    });
  }
  function fetchQualificationDropDown() {
    let hospitalId =
      hospitals.filter(
        (data) =>
          data.jobLocation.hospitalName === professionalDetails.hospitals
      )[0]?.id || formData.hospitalFormId;

    // console.log("department", department)
    // console.log("professionalDetails", professionalDetails)
    let departmentId =
      department.filter(
        (data) => data.departmentType?.name === professionalDetails.department
      )[0]?.id || formData.departmentFormId;

    let professionId =
      profession.filter(
        (data) => data?.name === professionalDetails.profession
      )[0]?.id || formData.professionFormId;

    setSkillDepartmentID(departmentId);
    setSkillProfessionID(professionId);

    // console.log("departmentId", departmentId)
    let query = `
      query{
        withAuth(token:"${token}"){
          listMiniJobQualifications (branch:"${hospitalId}" 
                                    department:"${departmentId}"
                                    profession:"${professionId}"
          ) {
            id
            name
          }
         }
       }
      `;
    graphqlRequest(query).then((response) => {
      // console.log("qresult1", response.data.withAuth.listMiniJobQualifications)
      // console.log("qresult2", response.data.withAuth.listMiniJobQualifications.length)
      if (response.data.withAuth.listMiniJobQualifications.length === 1) {
        setQualification(response.data.withAuth.listMiniJobQualifications);
        setProfessionalDetails({
          ...professionalDetails,
          qualification:
            response.data.withAuth.listMiniJobQualifications[0]?.name,
        });
      } else {
        setQualification(response.data.withAuth.listMiniJobQualifications);
        setProfessionalDetails({
          ...professionalDetails,
          qualification: "",
          specialization: "",
          experience: "",
        });
        setFormData({
          ...formData,
          qualification: "",
          specialization: "",
          experience: "",
        });
      }
    });
  }
  function fetchSpecializationDropDown() {
    let hospitalId =
      hospitals.filter(
        (data) =>
          data.jobLocation.hospitalName === professionalDetails.hospitals
      )[0]?.id || formData.hospitalFormId;

    let departmentId =
      department.filter(
        (data) => data.departmentType?.name === professionalDetails.department
      )[0]?.id || formData.departmentFormId;

    let professionId =
      profession.filter(
        (data) => data?.name === professionalDetails.profession
      )[0]?.id || formData.professionFormId;

    let qualificationId =
      qualification.filter(
        (data) => data?.name === professionalDetails.qualification
      )[0]?.id || formData.qualificationFormId;

    let query = `
      query{
        withAuth(token:"${token}"){
          listMiniJobSpecializations (branch:"${hospitalId}" 
                                    department:"${departmentId}"
                                    profession:"${professionId}"
                                    qualification:"${qualificationId}"
          ) {
            id
            name
          }
         }
       }
      `;
    graphqlRequest(query).then((response) => {
      if (response.data.withAuth.listMiniJobSpecializations.length === 1) {
        setSpecialization(response.data.withAuth.listMiniJobSpecializations);
        setProfessionalDetails({
          ...professionalDetails,
          specialization:
            response.data.withAuth.listMiniJobSpecializations[0]?.name,
        });
      } else {
        setSpecialization(response.data.withAuth.listMiniJobSpecializations);
        setProfessionalDetails({
          ...professionalDetails,
          specialization: "",
          experience: "",
        });
        setFormData({
          ...formData,
          specialization: "",
          experience: "",
        });
      }
    });
  }
  function fetchExperienceDropDown() {
    let hospitalId =
      hospitals.filter(
        (data) =>
          data.jobLocation.hospitalName === professionalDetails.hospitals
      )[0]?.id || formData.hospitalFormId;

    let departmentId =
      department.filter(
        (data) => data.departmentType?.name === professionalDetails.department
      )[0]?.id || formData.departmentFormId;

    let professionId =
      profession.filter(
        (data) => data?.name === professionalDetails.profession
      )[0]?.id || formData.professionFormId;

    let qualificationId =
      qualification.filter(
        (data) => data?.name === professionalDetails.qualification
      )[0]?.id || formData.qualificationFormId;

    let specializationId =
      specialization.filter(
        (data) => data?.name === professionalDetails.specialization
      )[0]?.id || formData.specializationFormId;

    let query = `
      query{
        withAuth(token:"${token}"){
          listMiniJobExperiences (branch:"${hospitalId}" 
                                    department:"${departmentId}"
                                    profession:"${professionId}"
                                    qualification:"${qualificationId}"
                                    specialization: "${specializationId}"
          ) {
            id
            name
          }
         }
       }
      `;
    graphqlRequest(query).then((response) => {
      if (response.data.withAuth.listMiniJobExperiences.length === 1) {
        setExperience(response.data.withAuth.listMiniJobExperiences);
        setProfessionalDetails({
          ...professionalDetails,
          experience: response.data.withAuth.listMiniJobExperiences[0]?.name,
        });
      } else {
        setExperience(response.data.withAuth.listMiniJobExperiences);
        setProfessionalDetails({
          ...professionalDetails,
          experience: "",
        });
        setFormData({
          ...formData,
          experience: "",
        });
      }
    });
  }

  function fetchAdditionalSkillDropDown() {
    //  console.log("departmentFormid1>>", formData?.departmentTypeFormId)
    let departmentTypeId =
      department.filter(
        (data) => data.departmentType?.name === professionalDetails.department
      )[0]?.departmentType?.id || formData?.departmentTypeFormId;

    let professionId =
      profession.filter(
        (data) => data?.name === professionalDetails.profession
      )[0]?.id || formData?.professionFormId;

    // console.log("departmentTypeId",departmentTypeId)
    // console.log("departmentFormid",formData?.departmentTypeFormId)
    // console.log("professionId",professionId)
    // console.log("professionFormId",formData?.professionFormId)
    let query = `
      query{
        withAuth(token:"${token}"){
          listMiniJobSkills (departmentType:"${departmentTypeId}" professionalType:"${professionId}"
          ) {
            id
            name
          }
         }
       }
      `;
    graphqlRequest(query).then((response) => {
      // console.log("skillresp1", response.data.withAuth.listMiniJobSkills)
      // console.log("skillresp2", response.data.withAuth.listMiniJobSkills.length)
      if (response?.data?.withAuth?.listMiniJobSkills?.length >= 1) {
        // setQualification(response.data.withAuth.listMiniJobSkills);
        setAdditionalSkill(response.data.withAuth.listMiniJobSkills);
        setProfessionalDetails({
          ...professionalDetails,
          // qualification: response.data.withAuth.listMiniJobSkills[0]?.name,
          additionalSkill: response.data.withAuth.listMiniJobSkills[0]?.name,
        });
      } else {
        // setQualification(response.data.withAuth.listMiniJobQualifications);
        setAdditionalSkill(response.data.withAuth.listMiniJobSkills);
        setProfessionalDetails({
          ...professionalDetails,
          additionalSkill: "",
        });
        setFormData({
          ...formData,
          additionalSkill: "",
        });
      }
    });
  }

  function mergeDateAndTime(mydate, mytime) {
    let date = new Date(mydate);
    const options = {
      hour12: false, // Use 24-hour format
      hour: "2-digit", // Display the hour with leading zeros
      minute: "2-digit", // Display the minute with leading zeros
    };

    let st = new Date(mytime).toLocaleTimeString("en-US", options);
    // console.log("st---------", st);
    date.setHours(st.split(":")[0]);
    date.setMinutes(st.split(":")[1]);
    date.setSeconds(0);
    date.setMilliseconds(0);

    if (st === "24:00") {
      const prevDay = new Date(date.getTime() - 24 * 60 * 60 * 1000);
      return prevDay;
    }

    return date;
  }

  function getDaysBetweenDates(dateString1, dateString2) {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffInTime = Math.abs(date2 - date1);
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  }

  // function findDiff(startDate, endDate, startTime, endTime) {
  //   console.log("checking.....", startDate, endDate, startTime, endTime);
  //   let updatedStartDate = mergeDateAndTime(startDate, startTime);
  //   let updatedEndDate = mergeDateAndTime(endDate, endTime);
  //   console.log("updatedStartDate", updatedStartDate);
  //   console.log("updatedEndDate", updatedEndDate);
  //   let days;
  //   const m1 = moment(updatedStartDate, "YYYY-MM-DD h:mm");
  //   const m2 = moment(updatedEndDate, "YYYY-MM-DD h:mm");
  //   days = m2.diff(m1, "days") + 1;
  //   let result = new Date(endDate).getDate() - new Date(startDate).getDate();
  //   if (m2.diff(m1, "minutes") === 1440 && result === 1) {
  //     return days - 1;
  //   } else if (
  //     new Date(startTime).getTime() === new Date(endTime).getTime() &&
  //     days !== 2
  //   ) {
  //     message.error("some error with time ");
  //     // return days - 1;
  //   } else {
  //     return days;
  //   }
  // }

  function findDiff(startDate, endDate, startTime, endTime) {
    let updatedStartDate = mergeDateAndTime(startDate, startTime);
    let updatedEndDate = mergeDateAndTime(endDate, endTime);

    const m1 = moment(updatedStartDate);
    const m2 = moment(updatedEndDate);

    // If the end time is before the start time on the same day, consider it a full-day shift spanning two days
    if (m2.isBefore(m1)) {
        return 1; // Handle case where end date is before start date
    } else if (m1.isSame(m2, 'day') && startTime === endTime) {
        return 0; // Return 0 to indicate an error
    } else {
        const diffInDays = m2.diff(m1, 'days') + 1;
        const diffInMinutes = m2.diff(m1, 'minutes');

        // Calculate the number of 24-hour shifts needed
        const numberOfShifts = Math.floor(diffInMinutes / 1440);
        
        // Error handling for same start and end time but incorrect day difference
        if (startTime === endTime && diffInDays !== numberOfShifts + 1) {
            console.error("Some error with time.");
            return 0;
        }

        if (numberOfShifts > 0) {
            return numberOfShifts;
        } else {
            return diffInDays; // Default to returning the number of days if not a 24-hour shift scenario
        }
    }
}


//   function findDiff(startDate, endDate, startTime, endTime) {
//     let updatedStartDate = mergeDateAndTime(startDate, startTime);
//     let updatedEndDate = mergeDateAndTime(endDate, endTime);

//     const m1 = moment(updatedStartDate);
//     const m2 = moment(updatedEndDate);
    
//     if (m2.isBefore(m1)) {
//         return 1; // Handle case where end date is before start date
//     } else if (m1.isSame(m2, 'day') && startTime === endTime) {
//         return 0; // Return 0 to indicate an error
//     } else {
//         const diffInDays = m2.diff(m1, 'days') + 1;
//         if(m2.diff(m1, 'minutes') === 1440){
//           return 1; 
//         }else if (m2.diff(m1, 'minutes') === 1440 && diffInDays === 2) {
//             return 2; // 24 hours shift spanning two days
//         } else if (startTime === endTime && diffInDays !== 2) {
//             console.error("Some error with time.");
//             return 0;
//         } else {
//             return diffInDays;
//         }
//     }
// }


  // function findDiff(startDate, endDate, startTime, endTime) {
  //   // console.log("checking.....", startDate, endDate, startTime, endTime);
  //   let updatedStartDate = mergeDateAndTime(startDate, startTime);
  //   let updatedEndDate = mergeDateAndTime(endDate, endTime);
  //   // console.log("updatedStartDate", updatedStartDate);
  //   // console.log("updatedEndDate", updatedEndDate);
  //   const m1 = moment(updatedStartDate, "YYYY-MM-DD h:mm");
  //   const m2 = moment(updatedEndDate, "YYYY-MM-DD h:mm");
  //   let days = m2.diff(m1, "days") + 1;
  //   if (
  //     m2.isBefore(m1) ||
  //     (m1.date() === m1.daysInMonth() && m2.date() === 1)
  //   ) {
  //     // Handle case where end date is before start date or end date is the first day of the next month
  //     return 1;
  //   } else if (m1.isSame(m2, "day") && startTime === endTime) {
  //     return 0; // Return 0 to indicate an error, but skip showing the error message
  //   } else {
  //     let result = new Date(endDate).getDate() - new Date(startDate).getDate();
  //     if (m2.diff(m1, "minutes") === 1440 && result === 1) {
  //       return days - 1;
  //     } else if (
  //       startTime &&
  //       endTime &&
  //       new Date(startTime).getTime() === new Date(endTime).getTime() &&
  //       days !== 2
  //     ) {
  //       message.error("Some error with time.");
  //       // return days - 1;
  //     } else {
  //       return days;
  //     }
  //   }
  // }

  // fetch hospita dropdown and Contact Details and job description
  useEffect(() => {
    fetchHospitalDropdownData();
    fetchContactDetailsUser();
    fetchJobDescription();
  }, []);
  // fetch department dropdown
  useEffect(() => {
    professionalDetails?.hospitals?.length > 2 && fetchDepartmentDropDown();
  }, [professionalDetails.hospitals]);

  // fetch profession dropdown
  useEffect(() => {
    professionalDetails?.department?.length > 2 && fetchProfessionsDropDown();
  }, [professionalDetails.department]);

  // fetch qualification dropdown
  useEffect(() => {
    professionalDetails?.profession?.length > 2 && fetchQualificationDropDown();
    fetchQualificationDropDown();
  }, [professionalDetails.profession]);

  // fetch specialization dropdown
  useEffect(() => {
    professionalDetails.qualification?.length > 2 &&
      fetchSpecializationDropDown();
  }, [professionalDetails.qualification]);

  // console.log("setskillProfessionID", professionalDetails.qualification?.length)
  // fetch experience dropdown
  useEffect(() => {
    professionalDetails.qualification?.length > 2 && fetchExperienceDropDown();
  }, [professionalDetails.specialization]);

  //fetch additional skills dropdown
  // console.log("professionalDetails>>>",professionalDetails.additionalSkill?.length)
  // console.log("skills222>>>",professionalDetails)
  // console.log("setSkillDepartmentID", skillDepartmentID)
  // console.log("setskillProfessionID", skillProfessionID)
  // console.log("departmentTypeFormIdBelow>>", formData?.departmentTypeFormId)
  // console.log("additionalSkill>>", additionalSkill)
  useEffect(() => {
    // professionalDetails?.additionalSkill?.length > 2 && fetchAdditionalSkillDropDown();
    if (
      (formData?.departmentTypeFormId || skillDepartmentID) &&
      skillProfessionID
    ) {
      fetchAdditionalSkillDropDown();
    } else {
      fetchAdditionalSkillDropDown();
    }
  }, [formData?.departmentTypeFormId, skillDepartmentID, skillProfessionID]);

  // useEffect(() => {
  //   if(professionalDetails.hospitals && professionalDetails.department && professionalDetails.profession && professionalDetails.qualification  && professionalDetails.specialization){
  //     fetchAdditionalSkillDropDown();
  //   }

  // }, [professionalDetails.hospitals, professionalDetails.department, professionalDetails.profession, professionalDetails.qualification, professionalDetails.specialization]);

  // console.log("1094--->", formData)
  // console.log("1095--->", minJobRate)
  // useEffect(() => {
  //   if (
  //     (formData.hospitals &&
  //       formData.profession &&
  //       formData.qualification &&
  //       formData.department &&
  //       formData.specialization &&
  //       formData.experience &&
  //       formData.name &&
  //       formData.email &&
  //       formData.contact &&
  //       formData.startDate &&
  //       formData.endDate &&
  //       formData.startTime &&
  //       formData.endTime &&
  //       formData.noOfProfessional &&
  //       formData.rateOfferedPerHour
  //       ) ||
  //     (professionalDetails.hospitals &&
  //       professionalDetails.profession &&
  //       professionalDetails.qualification &&
  //       professionalDetails.department &&
  //       professionalDetails.specialization &&
  //       professionalDetails.experience &&
  //       // jobDetails.startDate &&
  //       // jobDetails.endDate &&
  //       // jobDetails.startTime &&
  //       // jobDetails.endTime &&
  //       // jobDetails.noOfProfessional &&
  //       // jobDetails.rateOfferedPerHour &&
  //       Object.keys(jobDetailsError).length === 0 &&
  //       jobDescription.length > 4 &&
  //       // additionalFacilities.isFood ||
  //       // additionalFacilities.isAccommodation ||
  //       // additionalFacilities.isTransport &&
  //       contactDetails.name &&
  //       contactDetails.email &&
  //       contactDetails.contact)
  //   ) {
  //     console.log("if condition");
  //     setDisabled(false);
  //   } else setDisabled(true);
  // }, [
  //   (formData.hospitals,
  //   formData.profession,
  //   formData.qualification,
  //   formData.department,
  //   formData.specialization,
  //   formData.experience,
  //   formData.name,
  //   formData.email,
  //   formData.contact,
  //   formData.startDate,
  //   formData.endDate,
  //   formData.startTime,
  //   formData.endTime,
  //   formData.noOfProfessional,
  //   formData.rateOfferedPerHour
  //   ) ||
  //     (professionalDetails.hospitals,
  //     professionalDetails.profession,
  //     professionalDetails.qualification,
  //     professionalDetails.department,
  //     professionalDetails.specialization,
  //     professionalDetails.experience,
  //     // jobDetails.startDate &&
  //     //   jobDetails.endDate &&
  //     //   jobDetails.startTime &&
  //     //   jobDetails.endTime &&
  //     //   jobDetails.noOfProfessional &&
  //     //   jobDetails.rateOfferedPerHour &&
  //     jobDetails,
  //     jobDetailsError,
  //     jobDescription,
  //     // additionalFacilities.isFood ||
  //     // additionalFacilities.isAccommodation ||
  //     // additionalFacilities.isTransport &&
  //     contactDetails.name,
  //     contactDetails.email,
  //     contactDetails.contact),
  // ]);

  useEffect(() => {
    const isFormDataComplete =
      formData.hospitals &&
      formData.profession &&
      formData.qualification &&
      formData.department &&
      formData.specialization &&
      formData.experience &&
      formData.name &&
      formData.email &&
      formData.contact;
    // formData.noOfProfessional &&
    // formData.rateOfferedPerHour;

    const isProfessionalDetailsComplete =
      professionalDetails.hospitals &&
      professionalDetails.profession &&
      professionalDetails.qualification &&
      professionalDetails.department &&
      professionalDetails.specialization &&
      professionalDetails.experience &&
      contactDetails.name &&
      contactDetails.email &&
      contactDetails.contact &&
      jobDescription.length > 4 &&
      Object.keys(jobDetailsError).length === 0 &&
      jobDetails.startDate &&
      jobDetails.endDate &&
      jobDetails.startDate &&
      jobDetails.endDate &&
      jobDetails.noOfProfessional &&
      jobDetails.rateOfferedPerHour;

    if (
      (isFormDataComplete &&
        jobDetails.startDate &&
        jobDetails.endDate &&
        jobDetails.noOfProfessional &&
        jobDetails.rateOfferedPerHour >= minJobRate) ||
      (isProfessionalDetailsComplete &&
        jobDetails.startDate &&
        jobDetails.endDate &&
        jobDetails.noOfProfessional &&
        jobDetails.rateOfferedPerHour >= minJobRate)
    ) {
      console.log("cond1");
      setDisabled(false);
    } else if (
      formData.name &&
      formData.email &&
      formData.contact &&
      formData.jobDescription &&
      professionalDetails.hospitals &&
      professionalDetails.department &&
      professionalDetails.profession &&
      professionalDetails.qualification &&
      professionalDetails.specialization &&
      professionalDetails.experience &&
      jobDetails.startDate &&
      jobDetails.endDate &&
      jobDetails.noOfProfessional &&
      jobDetails.rateOfferedPerHour >= minJobRate
    ) {
      console.log("cond2");
      setDisabled(false);
    } else {
      console.log("cond3");
      setDisabled(true);
    }

    // console.log("minJobRate", minJobRate)
    // if (
    //   (isFormDataComplete || isProfessionalDetailsComplete) &&
    //   jobDetails.startDate &&
    //   jobDetails.endDate &&
    //   jobDetails.startDate &&
    //   jobDetails.endDate &&
    //   jobDetails.noOfProfessional &&
    //   jobDetails.rateOfferedPerHour >= minJobRate
    // ) {
    //   console.log("cond1")
    //   setDisabled(false);
    // } else {
    //   console.log("cond2")
    //   setDisabled(true);
    // }
  }, [
    formData,
    professionalDetails,
    contactDetails,
    jobDescription,
    jobDetailsError,
    jobDetails.startDate,
    jobDetails.endDate,
    jobDetails.startDate,
    jobDetails.endDate,
    jobDetails.noOfProfessional,
    jobDetails.rateOfferedPerHour,
  ]);

  function jobDetailsErrorCheck() {
    let s = new Date(jobDetails.startTime);
    let e = new Date(jobDetails.endTime);
    let hour =
      (formData?.profession || professionalDetails?.profession) === "Doctor"
        ? 4
        : 6;
  
    let error = {};
    let WorkingHour;
    
    // Convert times to minutes since start of day
    let startMinutes = s.getHours() * 60 + s.getMinutes();
    let endMinutes = e.getHours() * 60 + e.getMinutes();
    let totalMinutes;
  
     // Calculate the difference in days
  let startDate = new Date(jobDetails.startDate);
  let endDate = new Date(jobDetails.endDate);
  let dateDiff = (endDate - startDate) / (1000 * 3600 * 24);

  // Calculate total minutes between start and end time
  if (dateDiff > 0) {
    totalMinutes = (dateDiff * 24 * 60) + (endMinutes - startMinutes);
  } else {
    totalMinutes = endMinutes - startMinutes;
  }

    WorkingHour = totalMinutes / 60; // Convert minutes to hours
     // Handle cases where working hours span more than 24 hours
  if (WorkingHour > 24) {
    WorkingHour = totalMinutes % (24 * 60) / 60;
  }

    console.log("WorkingHour", WorkingHour)
    
    // Ensure the date range is valid
    let diffinDay = getDaysBetweenDates(jobDetails.startDate, jobDetails.endDate);

    console.log("diffinDay", diffinDay)
    
    if (!jobDetails.startDate) {
      error.startDate = "Please select start date";
    }
    if (!jobDetails.endDate) {
      error.endDate = "Please select end date";
    }
    if (!jobDetails.startTime) {
      error.startTime = "Please select start time";
    }
    if (!jobDetails.endTime) {
      error.endTime = "Please select end time";
    }
    if (jobDetails.noOfProfessional < 1) {
      error.noOfProfessional = "Please provide number of professionals";
    }
  
    if (WorkingHour < hour) {
      error.time = `Working hours must be greater than ${hour} hours`;
    }

    if (jobDetails.rateOfferedPerHour < (minJobRate || 150)) {
          error.rateOfferedPerHour = `Rate Offered Per Hour must be greater than ₹${minJobRate}`;
        }
  
    if (diffinDay !== 1 && WorkingHour === 24) {
      error.date = "24-hour work cannot be posted for more than 1 day";
    }

    // if ((jobDetails.endDate.getTime() - jobDetails.startDate.getTime()) / (1000 * 3600 * 24) < 0) {
    //   error.date = "Please select a correct date";
    // }

    if ((new Date(jobDetails.endDate).getTime() - new Date(jobDetails.startDate).getTime()) / (1000 * 3600 * 24) < 0) {
          error.date = "Please select a correct date";
        }
  
    // Handle recurring appointments
    if (isReccuring) {
      let resultDays = calForDays();
      if (weekdays.length < 1) {
        error.weekdays = "Please select weekdays";
      }
      if (resultDays < 1) {
        error.weekdays = "Dates selected are not in sync with the recurring days. Please review dates and recurring days";
      } else {
        setWeekDaysResultDay(resultDays);
      }
    }
    
    return error;
  }
  
  
  

  // function jobDetailsErrorCheck() {
  //   // let s = new Date(jobDetails.startTime).getHours();
  //   // let e = new Date(jobDetails.endTime).getHours();
  //   let s = new Date(jobDetails.startTime);
  //   let e = new Date(jobDetails.endTime);
  //   let hour =
  //     (formData?.profession || professionalDetails?.profession) === "Doctor"
  //       ? 4
  //       : 6;
  //   // console.log("1179-->",  formData.rateOfferedPerHour )
  //   // formData.rateOfferedPerHour = ""
  //   // console.log("1181-->",  formData.rateOfferedPerHour )
  //   // let checkRatePerHour = formData.rateOfferedPerHour || jobDetails.rateOfferedPerHour
  //   let error = {};
  //   let WorkingHour;
  //   let diffinDay = getDaysBetweenDates(
  //     jobDetails?.startDate,
  //     jobDetails?.endDate
  //   );
  //   // console.log("test start time", s);
  //   // console.log("test end time", e);
  //   if (
  //     new Date(jobDetails.startDate).toDateString() ===
  //       new Date(jobDetails.endDate).toDateString() &&
  //     new Date().toDateString() ===
  //       new Date(jobDetails.startDate).toDateString()
  //   ) {
  //     if (s < e) {
  //       // WorkingHour = Math.ceil(e - s);
  //       WorkingHour = Math.ceil((e - s) / (1000 * 60 * 60));
  //     } else {
  //       // WorkingHour = Math.ceil(24 - s + e);
  //       WorkingHour = Math.ceil((24 * 60 * 60 * 1000 - s.getTime() + e.getTime()) / (1000 * 60 * 60));
  //     }
  //   } else {
  //     if (s < e) {
  //       // WorkingHour = Math.ceil(e - s);
  //       WorkingHour = Math.ceil((e - s) / (1000 * 60 * 60));
  //     } else {
  //       // WorkingHour = Math.ceil(24 - s + e);
  //       WorkingHour = Math.ceil((24 * 60 * 60 * 1000 - s.getTime() + e.getTime()) / (1000 * 60 * 60));
  //     }
  //   }
  //   if (
  //     e - s < 0 &&
  //     new Date(jobDetails.endDate).toDateString() ===
  //       new Date(jobDetails.startDate).toDateString()
  //   ) {
  //     // console.log("DIFF of start", s, " and  end", e);
  //     error.date = "please select upcoming date";
  //   }
  //   // if (s > 12 && e < 12) {
  //   //   if (
  //   //     new Date(jobDetails.endDate).toDateString() ===
  //   //     new Date(jobDetails.startDate).toDateString()
  //   //   ) {
  //   //     error.time = "please select upcoming next date";
  //   //   }
  //   // }
  //   if (s.getHours() > 12 && e.getHours() < 12) {
  //     if (new Date(jobDetails.endDate).toDateString() === new Date(jobDetails.startDate).toDateString()) {
  //       error.time = "please select upcoming next date";
  //     }
  //   }
  //   // console.log("WorkingHour", WorkingHour);
  //   // console.log("diffinDay", diffinDay);
  //   if (!jobDetails.startDate) {
  //     error.startDate = "please select start date";
  //   }
  //   if (!jobDetails.endDate) {
  //     error.endDate = "please select end date";
  //   }
  //   if (!jobDetails.startTime) {
  //     error.startTime = "please select start time";
  //   }
  //   if (!jobDetails.endTime) {
  //     error.endTime = "please select end time";
  //   }
  //   if (jobDetails.noOfProfessional < 1) {
  //     error.noOfProfessional = "Please Provide No. of Professional";
  //   }

  //   // console.log("prof", professionalDetails.profession)
  //   //Nurse
  //   // console.log("minJobRate",minJobRate)
  //   // if(professionalDetails.profession === "Doctor"){
  //   //   if (jobDetails.rateOfferedPerHour < (minJobRate || 240)) {
  //   //     error.rateOfferedPerHour = `Rate Offered Per Hour must be greater than ₹${minJobRate || 240}`;
  //   //   }
  //   // }else{
  //   //   if (jobDetails.rateOfferedPerHour < (minJobRate || 150)) {
  //   //     error.rateOfferedPerHour = `Rate Offered Per Hour must be greater than ₹${minJobRate || 150}`;
  //   //   }
  //   // }
  //   if (jobDetails.rateOfferedPerHour < (minJobRate || 150)) {
  //     error.rateOfferedPerHour = `Rate Offered Per Hour must be greater than ₹${minJobRate}`;
  //   }
  //   if (diffinDay !== 1 && WorkingHour === 24) {
  //     error.date = "24hr work cant be posted more than 1 days";
  //   }
  //   if (
  //     (jobDetails?.endDate?.getTime() - jobDetails?.startDate?.getTime()) /
  //       (1000 * 3600 * 24) <
  //     0
  //   ) {
  //     error.date = "Please select correct date";
  //   }
  //   console.log("WorkingHour", WorkingHour)
  //   console.log("s", s)
  //   console.log("e", e)
  //    // Adjust WorkingHour calculation to include minutes
  // let startMinutes = s.getHours() * 60 + s.getMinutes();
  // console.log("startTime", startMinutes)
  // let endMinutes = e.getHours() * 60 + e.getMinutes();
  // console.log("endMinutes", endMinutes)
  // let totalMinutes = endMinutes - startMinutes;
  // console.log("totalMinutes1", totalMinutes)
  // if (totalMinutes < 0) {
  //   totalMinutes += 24 * 60; // handle overnight shifts
  // }
  // let WorkingHoursIncludingMinutes = totalMinutes / 60;
  // console.log("WorkingHoursIncludingMinutes", WorkingHoursIncludingMinutes)
  // if (WorkingHoursIncludingMinutes < hour) {
  //     error.time = `working hour must be greater than ${hour} hr`;
  //   }

  //   // if (WorkingHour < hour) {
  //   //   error.time = `working hour must be greater than ${hour} hr`;
  //   // }
  //   if (isReccuring) {
  //     let resultDays = calForDays();
  //     if (weekdays.length < 1) {
  //       error.weekdays = "please select weekdays";
  //     }
  //     if (resultDays < 1) {
  //       error.weekdays =
  //         "Date selected are not in sync with the recurring days. Please review date and recurring days";
  //     } else {
  //       setWeekDaysResultDay(resultDays);
  //     }
  //   }

  //   return error;
  // }
  function calForDays() {
    let count = 0;
    const diffDays = findDiff(
      jobDetails.startDate,
      jobDetails.endDate,
      jobDetails.startTime,
      jobDetails.endTime
    );
    for (let i = 0; i < diffDays; i++) {
      let currentDate = new Date(jobDetails.startDate);
      currentDate.setDate(currentDate.getDate() + i);

      // console.log(currentDate.getDay());
      weekdays.map((data) => {
        if (data === currentDate.getDay()) {
          count++;
        }
      });
    }
    return count;
  }
  function professionalDetailsErrorCheck() {
    let error = {};
    if (professionalDetails?.profession?.length < 1) {
      error.profession = "profession problem";
    }
    if (professionalDetails?.hospitals?.length < 1) {
      error.hospital = "hospital issue";
    }
    if (professionalDetails?.qualification?.length < 1) {
      error.qualification = "qualification problem";
    }
    if (professionalDetails?.department?.length < 1) {
      error.department = "department issue";
    }
    if (professionalDetails?.specialization?.length < 1) {
      error.specialization = "specialization problem";
    }
    if (professionalDetails?.experience?.length < 1) {
      error.experience = "experience issue";
    }
    return error;
  }
  function fetchMinjobRate() {
    let hospitalId =
      formData.hospitalFormId ||
      hospitals.filter(
        (data) =>
          data.jobLocation.hospitalName === professionalDetails.hospitals
      )[0]?.id;

    let departmentId =
      formData.departmentFormId ||
      department.filter(
        (data) => data.departmentType?.name === professionalDetails.department
      )[0]?.id;

    let professionId =
      formData.professionFormId ||
      profession.filter(
        (data) => data?.name === professionalDetails.profession
      )[0]?.id;

    let qualificationId =
      formData.qualificationFormId ||
      qualification.filter(
        (data) => data?.name === professionalDetails.qualification
      )[0]?.id;

    let specializationId =
      formData.specializationFormId ||
      specialization.filter(
        (data) => data?.name === professionalDetails.specialization
      )[0]?.id;
    let experienceId =
      formData.experienceFormId ||
      experience.filter(
        (data) => data?.name === professionalDetails.experience
      )[0]?.id;

    let query = `
    query{
      withAuth(token:"${token}"){
        getMiniJobRate ( 
          branch:"${hospitalId}" 
          department:"${departmentId}"
          profession:"${professionId}"
          qualification:"${qualificationId}"
          specialization: "${specializationId}"
          experience: "${experienceId}"
        )
       }
     }
      `;
    graphqlRequest(query).then((response) => {
      // console.log("1353-->", response);
      setMinJobRate(response.data.withAuth.getMiniJobRate);
    });
  }

  function fetchFormMinjobRate() {
    let hospitalId = formData.hospitalFormId;

    let departmentId = formData.departmentFormId;

    let professionId = formData.professionFormId;

    let qualificationId = formData.qualificationFormId;

    let specializationId = formData.specializationFormId;
    let experienceId = formData.experienceFormId;

    let query = `
    query{
      withAuth(token:"${token}"){
        getMiniJobRate ( 
          branch:"${hospitalId}" 
          department:"${departmentId}"
          profession:"${professionId}"
          qualification:"${qualificationId}"
          specialization: "${specializationId}"
          experience: "${experienceId}"
        )
       }
     }
      `;
    graphqlRequest(query).then((response) => {
      // console.log("1353-->", response);
      setMinJobRate(response.data.withAuth.getMiniJobRate);
    });
  }

  function updateShift() {
    let myshift = [];
    console.log("Shift--->", myshift);
    let reccuringShift = [];
    const diffDays = findDiff(
      jobDetails.startDate,
      jobDetails.endDate,
      jobDetails.startTime,
      jobDetails.endTime
    );
    console.log("day dif checking", diffDays);
    for (let i = 0; i < diffDays; i++) {
      let currentDate = new Date(jobDetails.startDate);
      currentDate.setDate(currentDate.getDate() + i);
      myshift[i] = currentDate;
    }
    for (let i = 0; i < diffDays; i++) {
      let currentDate = new Date(jobDetails.startDate);
      currentDate.setDate(currentDate.getDate() + i);
      weekdays.map((data) => {
        if (data === currentDate.getDay()) {
          reccuringShift.push(currentDate);
        }
      });
    }
    isReccuring ? setShiftList(reccuringShift) : setShiftList(myshift);
  }
  useEffect(() => {
    setProfessionalDetailsError(professionalDetailsErrorCheck());
    setJobDetailsError(jobDetailsErrorCheck());
  }, [jobDetails, professionalDetails, isReccuring, weekdays]);

  useEffect(() => {
    if (Object.keys(professionalDetailsError).length === 0) {
      setmyJobDescription();
    } else {
      setJobDescription("");
    }
  }, [professionalDetailsError]);

  useEffect(() => {
    if (
      (formData &&
        // formData.startDate &&
        // formData.endDate &&
        // formData.startTime &&
        // formData.endDate &&
        formData.noOfProfessional &&
        formData.rateOfferedPerHour) ||
      (Object.keys(jobDetailsError).length === 0 &&
        Object.keys(professionalDetailsError).length === 0)
    ) {
      fetchLocumJobRate();
      updateShift();
      isReccuring
        ? setTotalDays(weekDaysResultDay)
        : setTotalDays(
            findDiff(
              jobDetails.startDate,
              jobDetails.endDate,
              jobDetails.startTime,
              jobDetails.endTime
            )
          );
    } else {
      setTotalCost([]);
      setShiftList([]);
    }
    if (
      formData.rateOfferedPerHour ||
      Object.keys(professionalDetailsError).length === 0
    ) {
      fetchMinjobRate();
      fetchFormMinjobRate();
    }
  }, [formData.rateOfferedPerHour, professionalDetailsError, jobDetailsError]);

  function addHours(date, hours) {
    // console.log("old", {date, hours})
    date.setHours(date.getHours() + hours);
    // console.log("new", {date, hours})
    return date;
  }
  function validateRecurring() {
    let error = {};
    return error;
  }

  useEffect(() => {
    setWeekdaysError(validateRecurring());
  }, [weekdays]);
  useEffect(() => {
    if (
      new Date(jobDetails.startTime).getHours() <
      addHours(new Date(), 2).getHours()
    ) {
      setJobDetails({ ...jobDetails, startTime: null });
    }
  }, [jobDetails.startDate]);

  useEffect(() => {
    if (
      new Date(jobDetails.endTime).getHours() <
      addHours(new Date(), 2).getHours()
    ) {
      setJobDetails({ ...jobDetails, endTime: null });
    }
  }, [jobDetails.endDate]);
  // console.log("jobDetailsError", jobDetails.startTime);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("--------------------------name value",name, value);
    // Update the form field value in the formData state

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // console.log("jobDetails--->", jobDetails)
  // console.log("1289-->", professionalDetails?.hospitals);

  // console.log("jobDetailsRate--->", jobDetails.rateOfferedPerHour)
  // console.log("minJobRate2", minJobRate)
  const isNurseRateValid = jobDetails?.rateOfferedPerHour < minJobRate;
  const isDoctorRateValid = jobDetails?.rateOfferedPerHour < minJobRate;
  // console.log("isNurseRateValid>>", isNurseRateValid)
  // console.log("isDoctorRateValid>>", isDoctorRateValid)
  const allSlot = isNurseRateValid && isDoctorRateValid;
  // console.log("allSlot1", allSlot)
  // console.log("jobDetailsError", jobDetailsError)

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackFinalPost = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event dataprofession: "",
      window.Moengage.track_event(eventHelpers.FINAL_JOBS_PST, {
        Hospital: professionalDetails.hospitals || formData.hospitals,
        Department: professionalDetails.department || formData.department,
        Profession: professionalDetails.profession || formData.profession,
        Qualification:
          professionalDetails.qualification || formData.qualification,
        Specialization:
          professionalDetails.specialization || formData.specialization,
        Experience: formData.experience || professionalDetails.experience,
        "Start Date": jobDetails.startDate,
        "End Date": jobDetails.endDate,
        "Start Time": jobDetails.startTime,
        "End Time": jobDetails.endTime,
        "No. of Professional": jobDetails.noOfProfessional,
        "Rate Offered Per hour": jobDetails.rateOfferedPerHour,
        // "Recurring",
        // "Additional Facilities": {
        isFood: additionalFacilities.isFood || formData.isFood,
        isAccommodation:
          additionalFacilities.isAccommodation || formData.isAccommodation,
        isTransport: additionalFacilities.isTransport || formData.isTransport,
        // },
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
      });
      // window.Moengage.destroy_session();
    }
  };

  // console.log("1718--->", jobDetails)

  const handleSkillChange = (event) => {
    setSelectedSkills(event.target.value);
  };

  const skillData = [
    { name: "JavaScript" },
    { name: "React" },
    { name: "Node.js" },
    { name: "Python" },
    { name: "HTML" },
    { name: "CSS" },
    { name: "SQL" },
    // Add more skills as needed
  ];

  const allSkills = (
    Array.isArray(formData?.additionalSkill) ? formData.additionalSkill : []
  ).concat(
    Array.isArray(professionalDetails?.additionalSkill)
      ? professionalDetails.additionalSkill
      : []
  );

  const handleAddMoreSkills = () => {
    setDisplayedSkills((prev) => prev + 10);
  };

  // const handleSkillClick = (skill) => {
  //   // Assuming 'name' is the key in the professionalDetails state where you want to store the skill
  //   setProfessionalDetails({ ...professionalDetails, skill });
  // };

  // const handleSkillClick = (skill) => {
  //   // Assuming 'name' and 'id' are properties of the skill object
  //   console.log("skills", skill)
  //   setProfessionalDetails({
  //     ...professionalDetails,
  //     additionalSkill: [...professionalDetails.additionalSkill, skill.name ]
  //   });

  //   if (selectedSkills1.includes(skill.name)) {
  //     setSelectedSkills1(selectedSkills1.filter(selectedSkill => selectedSkill !== skill.name));
  //   } else {
  //     setSelectedSkills1([...selectedSkills1, skill.name]);
  //   }
  // };

  // formData?.additionalSkill
  // formData.additionalSkillIDs
  // console.log("additionalSkillIDs>>", formData);

  const handleSkillClick = (skill) => {
    // Toggle selected state of the skill
    if (selectedSkills1.includes(skill.name)) {
      setSelectedSkills1(
        selectedSkills1.filter((selectedSkill) => selectedSkill !== skill.name)
      );
      // Remove skill from payload
      if (formData?.additionalSkillIDs) {
        setFormData((prevDetails) => ({
          ...prevDetails,
          additionalSkill: prevDetails.additionalSkill.filter(
            (s) => s !== skill.name
          ),
        }));
      } else {
        setProfessionalDetails((prevDetails) => ({
          ...prevDetails,
          additionalSkill: prevDetails.additionalSkill.filter(
            (s) => s !== skill.name
          ),
        }));
      }
    } else {
      setSelectedSkills1([...selectedSkills1, skill.name, skill.id]);
      // Add skill to payload
      if (formData?.additionalSkillIDs) {
        setFormData((prevDetails) => ({
          ...prevDetails,
          additionalSkill: [
            ...prevDetails.additionalSkill,
            { name: skill.name, id: skill.id },
          ],
        }));
      } else {
        setProfessionalDetails((prevDetails) => ({
          ...prevDetails,
          additionalSkill: [
            ...prevDetails.additionalSkill,
            { name: skill.name, id: skill.id },
          ],
        }));
      }
    }
  };

  useEffect(() => {
    if (formData?.additionalSkillIDs) {
      setFormData((prevDetails) => ({
        ...prevDetails,
        additionalSkill: "",
      }));
      setProfessionalDetails((prevDetails) => ({
        ...prevDetails,
        additionalSkill: "",
      }));
    }
  }, [formData?.additionalSkillIDs, professionalDetails.additionalSkill]);

  // console.log(formData?.additionalSkillIDs)
  // console.log("formData>>", formData);
  // console.log("limitExceeded", limitExceeded);

  const handleOk = () => {
    setVisible(false);
  };

  // const handleCancel = () => {
  //   setVisible(false);
  // };

  const formPhoneWithPlus = "+" + formData?.contact
  const contactWithPlus = "+" + contactDetails?.contact

  const today = new Date();
  const currentHour = today.getHours();
  const currentMinutes = today.getMinutes();
// const isCurrentHourDisabled = (hour) => {
//   return hour === currentHour && currentMinutes >= 20;
// };
const isCurrentHourDisabled = (hour, selectedDate) => {
  const isSameDay = selectedDate.toDateString() === today.toDateString();
  return isSameDay && hour === currentHour && currentMinutes > 30;
};

  return (
    <div className="locum-form-container">
      <div className="locum-form-wrapper">
        <Snackbar
          open={openErrorSnackbar}
          // autoHideDuration={6000}
          onClose={() => setOpenErrorSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setOpenErrorSnackbar(false)}
            severity="error"
          >
            {errorMessage}
          </MuiAlert>
        </Snackbar>
        <AntModal
          // title="Credit Limit Exceeded"
          visible={visible}
          onOk={handleOk}
          closable={false}
          // footer={null}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]}
          // onCancel={handleCancel}
        >
          <p>Credit Limit Exceeded, Please pay pending bills to use further!</p>
        </AntModal>

        <JobsHeader title={"Post A job"} />
        <BreadCrumb action={"Post a job"} title={"Locum"} />
        <SuggestionBox fillForm={fillForm} />
        <div className="professional-details-form">
          <div className="pdf-wrapper">
            <div className="pdf-top">
              {(formData.hospitals &&
                formData.department &&
                formData.profession &&
                formData.qualification &&
                formData.specialization &&
                formData.experience) ||
              Object.keys(professionalDetailsError).length < 1 ? (
                <img src={completed} alt="form-input-completed" />
              ) : (
                <img src={pending} alt="form-input-completed" />
              )}
              <h3 className="lucum-job-form-title">Professional Details</h3>
            </div>
            <div className="pdf-bottom">
              <div className="hospital-input-container">
                {/* hospita dropdown */}
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Hospital
                  </InputLabel>
                  <Select
                    label="Hospital"
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    className="dropdown-hospital-locum-job"
                    sx={{
                      width: "300px",
                    }}
                    // value={professionalDetails?.hospitals}

                    value={
                      formData?.hospitals ||
                      professionalDetails?.hospitals ||
                      ""
                    }
                    //value={formData?.hospitals ? formData.hospitals : professionalDetails?.hospitals || ""}
                    onChange={(e) => {
                      e.target.name = "hospitals";
                      handleProfessionalState(["hospitals"]);
                      handleDropdown(e.target.value, "hospitals");
                      handleChange(e);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) return "";
                      else return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      {/* hospital */}
                    </MenuItem>
                    {hospitals?.map((data) => (
                      <MenuItem
                        value={data.jobLocation.hospitalName}
                        label="Hospital"
                      >
                        {data.jobLocation.hospitalName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* department */}

                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Department
                  </InputLabel>
                  <Select
                    className="dropdown-hospital-locum-job"
                    label="Department"
                    sx={{
                      width: "300px",
                    }}
                    // value={professionalDetails?.department}
                    value={
                      formData?.department ||
                      professionalDetails?.department ||
                      ""
                    }
                    // disabled={creditLimit < utilizedLimit}
                    onChange={(e) => {
                      e.target.name = "department";
                      handleProfessionalState(["hospitals", "department"]);
                      handleDropdown(e.target.value, "department");
                      handleChange(e);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) return "";
                      else return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="ok">
                      {/* {data.label} */}
                    </MenuItem>
                    {department?.map((data) => (
                      <MenuItem value={data?.departmentType?.name}>
                        {data?.departmentType?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* profession */}
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Profession
                  </InputLabel>

                  <Select
                    label="Profession"
                    className="dropdown-hospital-locum-job"
                    sx={{
                      width: "300px",
                    }}
                    // defaultValue={professionalDetails?.profession}
                    // value={professionalDetails?.profession}
                    value={
                      formData?.profession ||
                      professionalDetails?.profession ||
                      ""
                    }
                    onChange={(e) => {
                      e.target.name = "profession";
                      handleProfessionalState([
                        "hospitals",
                        "department",
                        "profession",
                      ]);
                      handleDropdown(e.target.value, "profession");
                      handleChange(e);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) return "";
                      else return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="ok">
                      {/* {data.label} */}
                    </MenuItem>
                    {profession?.map((data) => (
                      <MenuItem value={data?.name}>{data?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* qualification */}
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Qualification
                  </InputLabel>

                  <Select
                    label="Qualification"
                    className="dropdown-hospital-locum-job"
                    sx={{
                      width: "300px",
                    }}
                    // defaultValue={professionalDetails?.qualification}
                    // defaultValue={professionalDetails.qualification}
                    // value={professionalDetails?.qualification}
                    value={
                      formData?.qualification ||
                      professionalDetails?.qualification ||
                      ""
                    }
                    onChange={(e) => {
                      e.target.name = "qualification";
                      handleProfessionalState([
                        "hospitals",
                        "department",
                        "profession",
                        "qualification",
                      ]);
                      handleDropdown(e.target.value, "qualification");
                      handleChange(e);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) return "";
                      else return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="ok">
                      {/* {data.label} */}
                    </MenuItem>
                    {qualification?.map((data) => (
                      <MenuItem value={data?.name}>{data?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* specialization */}

                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Specialization
                  </InputLabel>

                  <Select
                    className="dropdown-hospital-locum-job"
                    label="Specialization"
                    sx={{
                      width: "300px",
                    }}
                    // defaultValue={professionalDetails?.specialization}
                    // value={professionalDetails?.specialization}
                    value={
                      formData?.specialization ||
                      professionalDetails?.specialization ||
                      ""
                    }
                    onChange={(e) => {
                      e.target.name = "specialization";
                      handleProfessionalState([
                        "hospitals",
                        "department",
                        "profession",
                        "qualification",
                        "specialization",
                      ]);
                      handleDropdown(e.target.value, "specialization");
                      handleChange(e);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) return "";
                      else return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="ok">
                      {/* {data.label} */}
                    </MenuItem>
                    {specialization?.map((data) => (
                      <MenuItem value={data?.name}>{data?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* experience */}
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Experience
                  </InputLabel>
                  <Select
                    className="dropdown-hospital-locum-job"
                    label="Experience"
                    sx={{
                      width: "300px",
                    }}
                    defaultValue={professionalDetails.experience}
                    // value={professionalDetails?.experience}
                    value={
                      formData?.experience ||
                      professionalDetails?.experience ||
                      ""
                    }
                    onChange={(e) => {
                      e.target.name = "experience";
                      handleProfessionalState([
                        "hospitals",
                        "department",
                        "profession",
                        "qualification",
                        "specialization",
                        "experience",
                      ]);
                      handleDropdown(e.target.value, "experience");
                      handleChange(e);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) return "";
                      else return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="ok">
                      {/* {data.label} */}
                    </MenuItem>
                    {experience?.map((data) => (
                      <MenuItem value={data?.name}>{data?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* additional skills */}
                {/* <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Additional Skill(multiple selection)
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-select-label"
                    id="demo-multiple-select"
                    multiple
                    // value={selectedSkills}
                    // value={
                    //   // formData?.qualification ||
                    //   professionalDetails?.additionalSkill ||
                    //   ""
                    // }
                    value={
                      (Array.isArray(formData?.additionalSkill)
                        ? formData?.additionalSkill
                        : []) ||
                      (Array.isArray(professionalDetails?.additionalSkill)
                        ? professionalDetails?.additionalSkill
                        : [])
                    }
                    // onChange={handleSkillChange}
                    onChange={(e) => {
                      e.target.name = "additionalSkill";
                      handleProfessionalState([
                        "hospitals",
                        "department",
                        "profession",
                        "qualification",
                        "specialization",
                        "experience",
                        "additionalSkill",
                      ]);
                      handleDropdown(e.target.value, "additionalSkill");
                      handleChange(e);
                    }}
                    label="Additional Skills(multiple selection)"
                    className="dropdown-hospital-locum-job"
                    sx={{
                      width: "300px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {additionalSkill?.map((data) => (
                      <MenuItem key={data.name} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <div>
                  <h2 className="t-bold t-blue t-20 mt5 job-posting-shared-rich-text-editor__fade-in">
                    Additional Skills(Optional)
                  </h2>
                  <div className="job-posting-shared-job-skill-typeahead__skills-container">
                    {additionalSkill
                      ?.slice(0, displayedSkills)
                      .map((skill, index) => (
                        <button
                          key={index}
                          className={`selectable-button artdeco-pill artdeco-pill--slate artdeco-pill--2 ember-view mv1 mr2 pv2 ${
                            selectedSkills1.includes(skill.name)
                              ? "selected-skill"
                              : ""
                          }`}
                          onClick={() => handleSkillClick(skill)}
                        >
                          <span
                            className="artdeco-pill__text"
                            style={{ marginRight: "4px" }}
                          >
                            {skill.name}
                          </span>
                          <li-icon
                            aria-hidden="true"
                            type="plus-icon"
                            className="artdeco-pill__icon"
                            size="small"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              data-supported-dps="16x16"
                              fill="currentColor"
                              className="mercado-match"
                              width="16"
                              height="16"
                              focusable="false"
                            >
                              {/* <path d="M14 9H9v5H7V9H2V7h5V2h2v5h5z"></path> */}
                              {selectedSkills1.includes(skill?.name) ? (
                                <path
                                  d="M14.58 4.58L6 13.17 2.42 9.59l-1.17 1.17L6 15.17l9.75-9.75z"
                                  fill="white"
                                ></path>
                              ) : (
                                <path d="M14 9H9v5H7V9H2V7h5V2h2v5h5z"></path>
                              )}
                            </svg>
                          </li-icon>
                        </button>
                      ))}
                  </div>
                  {displayedSkills < additionalSkill?.length && (
                    <button
                      onClick={handleAddMoreSkills}
                      className="t-14 t-black mv2"
                    >
                      Add more skills
                    </button>
                  )}
                </div>

                {/* end additional skills */}
              </div>
            </div>
          </div>
        </div>
        {/* JOB DETAILS FORM */}
        {width > 480 ? (
          <div className="job-details-form-main-container">
            <div className="job-details-form">
              <div className="jdf-wrapper">
                <div className="jdf-top">
                  {Object.keys(jobDetailsError).length < 1 ? (
                    <img src={completed} alt="form-input-completed" />
                  ) : (
                    <img src={pending} alt="form-input-completed" />
                  )}
                  <h3 className="lucum-job-form-title">Job Details</h3>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="jdf-bottom">
                    <div className="jdf-left">
                      <label
                        className="input-container"
                        style={{ border: "1px solid lightgrey" }}
                      >
                        <input
                          onBlur={() => {
                            if (
                              jobDetails.startDate &&
                              jobDetails.endDate &&
                              jobDetails.startTime &&
                              jobDetails.endTime
                            ) {
                              if (jobDetailsError.time) {
                                message.error(jobDetailsError.time);
                              }
                              if (jobDetailsError.date) {
                                message.error(jobDetailsError.date);
                              }
                            }
                          }}
                          type="text"
                          placeholder="Start Date"
                          value={
                            jobDetails.startDate
                              ? format(jobDetails.startDate, "yyyy-MM-dd")
                              : "Select Start Date"
                          }
                        />
                        <img
                          src={calendar}
                          onClick={() =>
                            (formData.hospitals ||
                              Object.keys(professionalDetailsError).length ===
                                0) &&
                            setOpenStartDate(!openStartDate)
                          }
                          alt="calender-start"
                        />
                      </label>

                      <Modal
                        open={openStartDate}
                        onClose={() => setOpenStartDate(!openStartDate)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            zIndex: 2,
                            // width: "360px",
                            width: width > 480 ? "360px" : "350px",
                            position: "absolute",
                            // top: "200px",
                            top: width > 480 ? "200px" : "400px",
                            // left: "600px",
                            left: width > 480 ? "600px" : "20px",
                            // left: "calc(50% - 360px)"
                          }}
                        >
                          {/* min="new Date().toISOString().slice(0, 10)" */}
                          <Calendar
                            minDate={
                              new Date(
                                new Date().setHours(new Date().getHours() + 2)
                              )
                            }
                            className="calendar-date-input"
                            onChange={(item) => {
                              setJobDetails({
                                ...jobDetails,
                                startDate: item,
                              });
                              setOpenStartDate(false);
                            }}
                            locale={locales["enUS"]}
                            date={jobDetails.startDate}
                          />
                        </div>
                      </Modal>

                      <label className="input-container">
                        {/* <MobileTimePicker label={'"hours"'} views={['hours']} /> */}

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack
                            spacing={3}
                            style={{ width: "100%" }}
                            onBlur={() => {
                              if (
                                jobDetails.startDate &&
                                jobDetails.endDate &&
                                jobDetails.startTime &&
                                jobDetails.endTime
                              ) {
                                if (jobDetailsError.time) {
                                  message.error(jobDetailsError.time);
                                  jobDetailsError.time = null;
                                }
                                if (jobDetailsError.date) {
                                  message.error(jobDetailsError.date);
                                }
                              }
                            }}
                          >
                            <TimePicker
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#9a9a9a",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#9a9a9a",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#9a9a9a",
                                  },
                                },
                              }}
                              autoComplete="off"
                              // minTime={
                              //   todatDate ===
                              //     new Date(
                              //       jobDetails.startDate
                              //     ).toDateString() &&
                              //   moment(addHours(new Date(), 2))
                              // }
                              minTime={
                                todatDate ===
                                  new Date(
                                    jobDetails.startDate
                                  ).toDateString() &&
                                moment(addHours(new Date(), 0))
                              }
                              // disablePast={true}
                              label="Start Time"
                              // disabled={
                              //  !formData.hospitals || Object.keys(professionalDetailsError).length !== 0
                              // }
                              // views={["hours"]}
                              views={["hours","minutes"]}
                              minutesStep={30} 
                              defaultValue={null}
                              value={jobDetails.startTime}
                              onAccept={(e) => {
                                // console.log("ACCEPTED HOUR", e);
                                setJobDetails({
                                  ...jobDetails,
                                  startTime: e,
                                });
                              }}
                              onChange={(e) => {
                                // console.log("TIME HOUR", e);
                                // setJobDetails({
                                //   ...jobDetails,
                                //   startTime: e,
                                // });
                                // console.log({e})
                                // if(e['$H'] != 12) {
                                //   setJobDetails({
                                //     ...jobDetails,
                                //     startTime: e,
                                //   });
                                // }
                              }}
                              shouldDisableTime={(timeValue, clockType) => {
                                if (clockType === 'hours' && isCurrentHourDisabled(timeValue, new Date(jobDetails.startDate))) {
                                  return true;
                                }
                                return false;
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={onKeyDown}
                                  autoComplete="off"
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </label>

                      <TextField
                        id="outlined-basic"
                        label="No. of Professional"
                        name="emailId"
                        variant="outlined"
                        type="number"
                        sx={{ width: "328px" }}
                        autoComplete="off"
                        value={jobDetails.noOfProfessional}
                        // disabled={jobDetailsError.endTime}
                        onBlur={() => {
                          if (jobDetailsError.noOfProfessional) {
                            message.error(jobDetailsError.noOfProfessional);
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          // console.log("Professionals",parseInt(e.target.value).toString())
                          // let profValue = parseInt(e.target.value).toString();
                          // setJobDetails({
                          //   ...jobDetails,
                          //   noOfProfessional: profValue,
                          // });
                          // let profValue = parseInt(e.target.value);
                          // if (!isNaN(profValue) && profValue >= 1) {
                          //   setJobDetails({
                          //     ...jobDetails,
                          //     noOfProfessional: profValue,
                          //   });
                          // }

                          const inputValue = e.target.value;
                          const isValidNumber = /^[1-9]\d*$/.test(inputValue);
                          if (!isValidNumber) {
                            setJobDetails({
                              ...jobDetails,
                              noOfProfessional: "",
                            });
                          } else {
                            const parsedValue = parseInt(inputValue).toString();
                            setJobDetails({
                              ...jobDetails,
                              noOfProfessional: parsedValue,
                            });
                          }
                        }}
                      />
                    </div>

                    <div className="jdf-right">
                      <label
                        className="input-container"
                        style={{ border: "1px solid lightgrey" }}
                      >
                        <input
                          type="text"
                          placeholder="End Date"
                          onBlur={() => {
                            if (
                              jobDetails.startDate &&
                              jobDetails.endDate &&
                              jobDetails.startTime &&
                              jobDetails.endTime
                            ) {
                              if (jobDetailsError.time) {
                                message.error(jobDetailsError.time);
                              }
                              if (jobDetailsError.date) {
                                message.error(jobDetailsError.date);
                              }
                            }
                          }}
                          value={
                            jobDetails.endDate
                              ? format(jobDetails.endDate, "yyyy-MM-dd")
                              : "Select End Date"
                          }
                        />
                        <img
                          src={calendar}
                          onClick={() =>
                            (formData.hospitals ||
                              Object.keys(professionalDetailsError).length ===
                                0) &&
                            setOpenEndDate(!openEndDate)
                          }
                          alt="end-date"
                        />
                      </label>

                      <Modal
                        open={openEndDate}
                        onClose={() => setOpenEndDate(!openEndDate)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            zIndex: 2,
                            width: "360px",
                            position: "absolute",
                            top: "200px",
                            left: "600px",
                          }}
                        >
                          <Calendar
                            minDate={new Date(jobDetails.startDate)}
                            className="calendar-date-input"
                            onChange={(item) => {
                              setJobDetails({ ...jobDetails, endDate: item });
                              setOpenEndDate(false);
                            }}
                            locale={locales["enUS"]}
                            date={jobDetails.endDate}
                          />
                        </div>
                      </Modal>

                      <label className="input-container">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack
                            spacing={3}
                            style={{ width: "100%" }}
                            onBlur={() => {
                              if (jobDetails.startTime && jobDetails.endTime) {
                                if (jobDetailsError.time) {
                                  message.error(jobDetailsError.time);
                                  jobDetailsError.time = null;
                                }
                                if (jobDetailsError.date) {
                                  message.error(jobDetailsError.date);
                                }
                              }
                            }}
                          >
                            <TimePicker
                              label="End Time"
                              // views={["hours"]}
                              views={["hours","minutes"]}
                              minutesStep={30} 
                              orientation="landscape"
                              value={jobDetails.endTime}
                              minTime={
                                todatDate ===
                                  new Date(jobDetails.endDate).toDateString() &&
                                moment(
                                  addHours(
                                    new Date(jobDetails.startTime),
                                    (formData?.profession ||
                                      professionalDetails?.profession) ==
                                      "Doctor"
                                      ? 4
                                      : 6
                                  )
                                )
                              }
                              // minTime={moment(addHours(new Date(), 1))}
                              // disabled={jobDetailsError?.date ? true : false}

                              // disabled={
                              //   Object.keys(professionalDetailsError).length !== 0
                              // }
                              onAccept={(e) => {
                                // console.log({ e });
                                setJobDetails({
                                  ...jobDetails,
                                  endTime: e,
                                });
                              }}
                              onChange={(e) => {
                                // console.log({ e });
                                // setJobDetails({
                                //   ...jobDetails,
                                //   endTime: e,
                                // });
                                // if(e['$H'] != 12) {
                                //   setJobDetails({
                                //     ...jobDetails,
                                //     endTime: e,
                                //   });
                                // }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={onKeyDown}
                                  autoComplete="off"
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </label>

                      <TextField
                        id="outlined-basic"
                        label="Rate offered per hour"
                        name="emailId"
                        type="number"
                        variant="outlined"
                        sx={{ width: "328px" }}
                        autoComplete="off"
                        value={jobDetails.rateOfferedPerHour}
                        onBlur={() => {
                          if (jobDetailsError.rateOfferedPerHour) {
                            message.error(jobDetailsError.rateOfferedPerHour);
                          }
                        }}
                        // disabled={
                        //   Object.keys(professionalDetailsError).length !== 0
                        // }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          // console.log(
                          //   "Professionals",
                          //   parseInt(e.target.value).toString()
                          // );
                          // let rateValue = parseInt(e.target.value).toString();
                          // setJobDetails({
                          //   ...jobDetails,
                          //   rateOfferedPerHour: rateValue,
                          // });
                          const inputValue = e.target.value;
                          // if (!inputValue.trim()) { // Check if input value is empty or contains only whitespace
                          //   message.error(jobDetailsError.rateOfferedPerHour);
                          // }
                          // const isValidNumber = /^[1-9]\d*$/.test(inputValue);
                          const isValidNumber = /^(\d+(\.\d*)?|\.\d+)$/.test(inputValue);
                          if (!isValidNumber) {
                            setJobDetails({
                              ...jobDetails,
                              rateOfferedPerHour: "",
                            });
                          } else {
                            // const parsedValue = parseInt(inputValue).toString();
                            const parsedValue = parseFloat(inputValue).toString();
                            setJobDetails({
                              ...jobDetails,
                              rateOfferedPerHour: parsedValue,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: ".5rem",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // disabled={
                            //   Object.keys(professionalDetailsError).length !== 0
                            // }
                            icon={<img src={uncheck} />}
                            checkedIcon={<img src={check} />}
                            checked={isReccuring}
                            onChange={() => {
                              SetIsReccuring((prev) => !prev);
                              setType("NON_CONSECUTIVE");
                            }}
                          />
                        }
                        label="Recurring"
                        sx={{ fontColor: "0000FF" }}
                      />
                    </FormGroup>
                    <Tooltip title="Use this to schedule shifts on repeated basis across weeks">
                      <InfoIcon />
                    </Tooltip>

                    <div style={{ marginLeft: "1rem" }}>
                      {isReccuring && (
                        <ToggleDays
                          jobDetailsError={jobDetailsError}
                          weekdays={weekdays}
                          setWeekDays={setWeekDays}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="shift-wrapper">
                <div className="shift-container">
                  <div className="main-shift-container">
                    <div className="shift-top">
                      <h3>
                        Job Details Summary{" "}
                        {shiftList.length > 7 ? (
                          <Tooltip
                            className="extradays-strong-toolkit"
                            placement="right"
                            title="To be repeated in the same order on weekly basic"
                          >
                            <InfoIcon />
                          </Tooltip>
                        ) : (
                          " "
                        )}
                      </h3>
                      <p>Date and Time duration: </p>
                    </div>
                    <div className="shift-bottom">
                      {shiftList.slice(0, 7)?.map((data) => {
                        // console.log("slots>>", data);
                        return (
                          !allSlot && (
                            <Slote
                              data={data}
                              startTime={jobDetails.startTime}
                              endTime={jobDetails.endTime}
                            />
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="job-details-form-main-container">
            <div className="job-details-form">
              <div className="jdf-wrapper">
                <div className="jdf-top">
                  {Object.keys(jobDetailsError).length < 1 ? (
                    <img src={completed} alt="form-input-completed" />
                  ) : (
                    <img src={pending} alt="form-input-completed" />
                  )}
                  <h3 className="lucum-job-form-title">Job Details</h3>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="jdf-bottom">
                    <div className="jdf-left">
                      <label
                        className="input-container"
                        style={{ border: "1px solid lightgrey" }}
                      >
                        <input
                          onBlur={() => {
                            if (
                              jobDetails.startDate &&
                              jobDetails.endDate &&
                              jobDetails.startTime &&
                              jobDetails.endTime
                            ) {
                              if (jobDetailsError.time) {
                                message.error(jobDetailsError.time);
                              }
                              if (jobDetailsError.date) {
                                message.error(jobDetailsError.date);
                              }
                            }
                          }}
                          type="text"
                          placeholder="Start Date"
                          value={
                            jobDetails.startDate
                              ? format(jobDetails.startDate, "yyyy-MM-dd")
                              : "Select Start Date"
                          }
                        />
                        <img
                          src={calendar}
                          onClick={() =>
                            (formData.hospitals ||
                              Object.keys(professionalDetailsError).length ===
                                0) &&
                            setOpenStartDate(!openStartDate)
                          }
                          alt="calender-start"
                        />
                      </label>

                      <Modal
                        open={openStartDate}
                        onClose={() => setOpenStartDate(!openStartDate)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            zIndex: 2,
                            // width: "360px",
                            width: width > 480 ? "360px" : "350px",
                            position: "absolute",
                            // top: "200px",
                            top: width > 480 ? "200px" : "400px",
                            // left: "600px",
                            left: width > 480 ? "600px" : "20px",
                            // left: "calc(50% - 360px)"
                          }}
                        >
                          {/* min="new Date().toISOString().slice(0, 10)" */}
                          <Calendar
                            minDate={
                              new Date(
                                new Date().setHours(new Date().getHours() + 2)
                              )
                            }
                            className="calendar-date-input"
                            onChange={(item) => {
                              setJobDetails({
                                ...jobDetails,
                                startDate: item,
                              });
                              setOpenStartDate(false);
                            }}
                            locale={locales["enUS"]}
                            date={jobDetails.startDate}
                          />
                        </div>
                      </Modal>

                      <label className="input-container">
                        {/* <MobileTimePicker label={'"hours"'} views={['hours']} /> */}

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack
                            spacing={3}
                            style={{ width: "100%" }}
                            onBlur={() => {
                              if (
                                jobDetails.startDate &&
                                jobDetails.endDate &&
                                jobDetails.startTime &&
                                jobDetails.endTime
                              ) {
                                if (jobDetailsError.time) {
                                  message.error(jobDetailsError.time);
                                  jobDetailsError.time = null;
                                }
                                if (jobDetailsError.date) {
                                  message.error(jobDetailsError.date);
                                }
                              }
                            }}
                          >
                            <TimePicker
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#9a9a9a",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#9a9a9a",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#9a9a9a",
                                  },
                                },
                              }}
                              autoComplete="off"
                              minTime={
                                todatDate ===
                                  new Date(
                                    jobDetails.startDate
                                  ).toDateString() &&
                                moment(addHours(new Date(), 2))
                              }
                              // disablePast={true}
                              label="Start Time"
                              // disabled={
                              //  !formData.hospitals || Object.keys(professionalDetailsError).length !== 0
                              // }
                              views={["hours"]}
                              defaultValue={null}
                              value={jobDetails.startTime}
                              onAccept={(e) => {
                                // console.log("ACCEPTED HOUR", e);
                                setJobDetails({
                                  ...jobDetails,
                                  startTime: e,
                                });
                              }}
                              onChange={(e) => {
                                // console.log("TIME HOUR", e);
                                // setJobDetails({
                                //   ...jobDetails,
                                //   startTime: e,
                                // });
                                // console.log({e})
                                // if(e['$H'] != 12) {
                                //   setJobDetails({
                                //     ...jobDetails,
                                //     startTime: e,
                                //   });
                                // }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={onKeyDown}
                                  autoComplete="off"
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </label>
                    </div>

                    <div className="jdf-right">
                      <label
                        className="input-container"
                        style={{ border: "1px solid lightgrey" }}
                      >
                        <input
                          type="text"
                          placeholder="End Date"
                          onBlur={() => {
                            if (
                              jobDetails.startDate &&
                              jobDetails.endDate &&
                              jobDetails.startTime &&
                              jobDetails.endTime
                            ) {
                              if (jobDetailsError.time) {
                                message.error(jobDetailsError.time);
                              }
                              if (jobDetailsError.date) {
                                message.error(jobDetailsError.date);
                              }
                            }
                          }}
                          value={
                            jobDetails.endDate
                              ? format(jobDetails.endDate, "yyyy-MM-dd")
                              : "Select End Date"
                          }
                        />
                        <img
                          src={calendar}
                          onClick={() =>
                            (formData.hospitals ||
                              Object.keys(professionalDetailsError).length ===
                                0) &&
                            setOpenEndDate(!openEndDate)
                          }
                          alt="end-date"
                        />
                      </label>

                      <Modal
                        open={openEndDate}
                        onClose={() => setOpenEndDate(!openEndDate)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            zIndex: 2,
                            // width: "360px",
                            width: width > 480 ? "360px" : "350px",
                            position: "absolute",
                            // top: "200px",
                            top: width > 480 ? "200px" : "400px",
                            // left: "600px",
                            left: width > 480 ? "600px" : "20px",
                            // left: "calc(50% - 360px)"
                          }}
                        >
                          <Calendar
                            minDate={new Date(jobDetails.startDate)}
                            className="calendar-date-input"
                            onChange={(item) => {
                              setJobDetails({ ...jobDetails, endDate: item });
                              setOpenEndDate(false);
                            }}
                            locale={locales["enUS"]}
                            date={jobDetails.endDate}
                          />
                        </div>
                      </Modal>

                      <label className="input-container">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack
                            spacing={3}
                            style={{ width: "100%" }}
                            onBlur={() => {
                              if (jobDetails.startTime && jobDetails.endTime) {
                                if (jobDetailsError.time) {
                                  message.error(jobDetailsError.time);
                                  jobDetailsError.time = null;
                                }
                                if (jobDetailsError.date) {
                                  message.error(jobDetailsError.date);
                                }
                              }
                            }}
                          >
                            <TimePicker
                              label="End Time"
                              views={["hours"]}
                              orientation="landscape"
                              value={jobDetails.endTime}
                              minTime={
                                todatDate ===
                                  new Date(jobDetails.endDate).toDateString() &&
                                moment(
                                  addHours(
                                    new Date(jobDetails.startTime),
                                    (formData?.profession ||
                                      professionalDetails?.profession) ==
                                      "Doctor"
                                      ? 4
                                      : 6
                                  )
                                )
                              }
                              // minTime={moment(addHours(new Date(), 1))}
                              // disabled={jobDetailsError?.date ? true : false}

                              // disabled={
                              //   Object.keys(professionalDetailsError).length !== 0
                              // }
                              onAccept={(e) => {
                                // console.log({ e });
                                setJobDetails({
                                  ...jobDetails,
                                  endTime: e,
                                });
                              }}
                              onChange={(e) => {
                                // console.log({ e });
                                // setJobDetails({
                                //   ...jobDetails,
                                //   endTime: e,
                                // });
                                // if(e['$H'] != 12) {
                                //   setJobDetails({
                                //     ...jobDetails,
                                //     endTime: e,
                                //   });
                                // }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={onKeyDown}
                                  autoComplete="off"
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </label>

                      <TextField
                        id="outlined-basic"
                        label="No. of Professional"
                        name="emailId"
                        variant="outlined"
                        type="number"
                        sx={{ width: "328px" }}
                        autoComplete="off"
                        value={jobDetails.noOfProfessional}
                        // disabled={jobDetailsError.endTime}
                        onBlur={() => {
                          if (jobDetailsError.noOfProfessional) {
                            message.error(jobDetailsError.noOfProfessional);
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          // console.log("Professionals",parseInt(e.target.value).toString())
                          // let profValue = parseInt(e.target.value).toString();
                          // setJobDetails({
                          //   ...jobDetails,
                          //   noOfProfessional: profValue,
                          // });
                          // let profValue = parseInt(e.target.value);
                          // if (!isNaN(profValue) && profValue >= 1) {
                          //   setJobDetails({
                          //     ...jobDetails,
                          //     noOfProfessional: profValue,
                          //   });
                          // }

                          const inputValue = e.target.value;
                          const isValidNumber = /^[1-9]\d*$/.test(inputValue);
                          if (!isValidNumber) {
                            setJobDetails({
                              ...jobDetails,
                              noOfProfessional: "",
                            });
                          } else {
                            const parsedValue = parseInt(inputValue).toString();
                            setJobDetails({
                              ...jobDetails,
                              noOfProfessional: parsedValue,
                            });
                          }
                        }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Rate offered per hour"
                        name="emailId"
                        type="number"
                        variant="outlined"
                        sx={{ width: "328px" }}
                        autoComplete="off"
                        value={jobDetails.rateOfferedPerHour}
                        onBlur={() => {
                          if (jobDetailsError.rateOfferedPerHour) {
                            message.error(jobDetailsError.rateOfferedPerHour);
                          }
                        }}
                        // disabled={
                        //   Object.keys(professionalDetailsError).length !== 0
                        // }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          // console.log(
                          //   "Professionals",
                          //   parseInt(e.target.value).toString()
                          // );
                          // let rateValue = parseInt(e.target.value).toString();
                          // setJobDetails({
                          //   ...jobDetails,
                          //   rateOfferedPerHour: rateValue,
                          // });
                          const inputValue = e.target.value;
                          // if (!inputValue.trim()) { // Check if input value is empty or contains only whitespace
                          //   message.error(jobDetailsError.rateOfferedPerHour);
                          // }
                          // const isValidNumber = /^[1-9]\d*$/.test(inputValue);
                           const isValidNumber = /^(\d+(\.\d*)?|\.\d+)$/.test(inputValue);
                          if (!isValidNumber) {
                            setJobDetails({
                              ...jobDetails,
                              rateOfferedPerHour: "",
                            });
                          } else {
                            // const parsedValue = parseInt(inputValue).toString();
                            const parsedValue = parseFloat(inputValue).toString();
                            setJobDetails({
                              ...jobDetails,
                              rateOfferedPerHour: parsedValue,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* shift details */}
                  <div className="shift-wrapper">
                    <div className="shift-container">
                      <div className="main-shift-container">
                        <div className="shift-top">
                          <h3>
                            Job Details Summary{" "}
                            {shiftList.length > 7 ? (
                              <Tooltip
                                className="extradays-strong-toolkit"
                                placement="right"
                                title="To be repeated in the same order on weekly basic"
                              >
                                <InfoIcon />
                              </Tooltip>
                            ) : (
                              " "
                            )}
                          </h3>
                          <p>Date and Time duration: </p>
                        </div>
                        <div className="shift-bottom">
                          {shiftList.slice(0, 7)?.map((data) => {
                            // console.log("slots>>", data);
                            return (
                              !allSlot && (
                                <Slote
                                  data={data}
                                  startTime={jobDetails.startTime}
                                  endTime={jobDetails.endTime}
                                />
                              )
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // disabled={
                            //   Object.keys(professionalDetailsError).length !== 0
                            // }
                            icon={<img src={uncheck} />}
                            checkedIcon={<img src={check} />}
                            checked={isReccuring}
                            onChange={() => {
                              SetIsReccuring((prev) => !prev);
                              setType("NON_CONSECUTIVE");
                            }}
                          />
                        }
                        label="Recurring"
                        sx={{ fontColor: "0000FF" }}
                      />
                    </FormGroup>
                    <Tooltip title="Use this to schedule shifts on repeated basis across weeks">
                      <InfoIcon />
                    </Tooltip>

                    {width > 480 ? (
                      <div style={{ marginLeft: "1rem" }}>
                        {isReccuring && (
                          <ToggleDays
                            jobDetailsError={jobDetailsError}
                            weekdays={weekdays}
                            setWeekDays={setWeekDays}
                          />
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          marginLeft: "1rem",
                          marginTop: "80px",
                          width: "10%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {isReccuring && (
                          <ToggleDays
                            jobDetailsError={jobDetailsError}
                            weekdays={weekdays}
                            setWeekDays={setWeekDays}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="job-description-form">
          <div className="jdf-wrapper">
            <div className="jdf-top">
              {/* {(formData.jobDescription && formData.jobDescription.trim(" ").length < 5) || jobDescription.trim(" ").length < 5 ? (
                <img src={pending} alt="form-input-completed" />
              ) : (
                <img src={completed} alt="form-input-completed" />
              )} */}
              {(formData.jobDescription &&
                formData.jobDescription.trim().length >= 5) ||
              jobDescription.trim().length >= 5 ? (
                <img src={completed} alt="form-input-completed" />
              ) : (
                <img src={pending} alt="form-input-completed" />
              )}
              <h3 className="lucum-job-form-title">Job Description</h3>
            </div>
            <div className="jdf-bottom">
              {/* <label>Type here</label> */}
              <label>Type your JD or Edit, as required</label>
              <textarea
                className="jd-textarea"
                id="story"
                name="story"
                rows="8"
                cols="85"
                value={formData?.jobDescription || jobDescription || ""}
                onChange={(e) => {
                  e.target.name = "jobDescription";
                  setJobDescription(e.target.value);
                  handleChange(e);
                }}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="additional-facilities-form">
          <div className="aff-wrapper">
            <div className="aff-top">
              {additionalFacilities.isFood ||
              additionalFacilities.isAccommodation ||
              additionalFacilities.isTransport ||
              formData.isFood ||
              formData.isAccommodation ||
              formData.isTransport ? (
                <img src={completed} alt="form-input-completed" />
              ) : (
                <img src={pending} alt="form-input-completed" />
              )}
              <h3 className="lucum-job-form-title">
                Additional Facilities (Optional)
              </h3>
            </div>
            <div className="aff-bottom">
              <div className="first-input">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src={uncheck} />}
                      checkedIcon={<img src={check} />}
                      onClick={() => {
                        setAdditionalFacilities({
                          ...additionalFacilities,
                          isFood: !additionalFacilities.isFood,
                        });
                        if (formData && formData.hasOwnProperty("isFood")) {
                          setFormData({
                            ...formData,
                            isFood: !formData.isFood,
                          });
                        } else {
                          setFormData({
                            ...formData,
                            isFood: false,
                          });
                        }
                      }}
                      checked={
                        formData && formData.hasOwnProperty("isFood")
                          ? formData.isFood
                          : additionalFacilities.isFood
                      }
                    />
                  }
                  label="Food"
                  className="lucam-job-checkbox"
                />
              </div>
              <div className="second-input">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src={uncheck} />}
                      checkedIcon={<img src={check} />}
                      onClick={() => {
                        setAdditionalFacilities({
                          ...additionalFacilities,
                          isTransport: !additionalFacilities.isTransport,
                        });
                        if (
                          formData &&
                          formData.hasOwnProperty("isTransport")
                        ) {
                          setFormData({
                            ...formData,
                            isTransport: !formData.isTransport,
                          });
                        } else {
                          setFormData({
                            ...formData,
                            isTransport: false,
                          });
                        }
                      }}
                      checked={
                        formData && formData.hasOwnProperty("isTransport")
                          ? formData.isTransport
                          : additionalFacilities.isTransport
                      }
                    />
                  }
                  label="Transport"
                  className="lucam-job-checkbox"
                />
              </div>
              <div className="third-input">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src={uncheck} />}
                      checkedIcon={<img src={check} />}
                      onClick={() => {
                        setAdditionalFacilities({
                          ...additionalFacilities,
                          isAccommodation:
                            !additionalFacilities?.isAccommodation,
                        });
                        if (
                          formData &&
                          formData.hasOwnProperty("isAccommodation")
                        ) {
                          setFormData({
                            ...formData,
                            isAccommodation: !formData.isAccommodation,
                          });
                        } else {
                          setFormData({
                            ...formData,
                            isAccommodation: false,
                          });
                        }
                      }}
                      // checked={formData?.isAccommodation || false}
                      checked={
                        formData && formData.hasOwnProperty("isAccommodation")
                          ? formData.isAccommodation
                          : additionalFacilities.isAccommodation
                      }
                    />
                  }
                  label="Accommodation"
                  className="lucam-job-checkbox"
                />
              </div>

              <div className="fourth-input"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src={uncheck} />}
                      checkedIcon={<img src={check} />}
                      onClick={() => setIsOthersSelected(!isOthersSelected)}
                      checked={isOthersSelected}
                      // onClick={() => {}}
                      // checked={formData?.isAccommodation || false}
                      // checked={
                      //   formData && formData.hasOwnProperty("others")
                      //     ? formData.others
                      //     : additionalFacilities.others
                      // }
                    />
                  }
                  label="Others"
                  className="lucam-job-checkbox"
                />
              </div>
              {isOthersSelected && (
        // <TextareaAutosize
        //   rows={3}
        //   placeholder="Type additional facilities"
        //   value={othersText}
        //   onChange={(e) => setOthersText(e.target.value)}
        // />
        <textarea
                className="jd-textarea"
                id="story"
                name="story"
                rows="3"
                cols="20"
                placeholder="Type additional facilities"
                value={othersAdditional}
                // onChange={(e) => setOthersText(e.target.value)}
                // value={formData?.jobDescription || jobDescription || ""}
                onChange={(e) => {
                  e.target.name = "othersAdditional";
                  setOthersAdditional(e.target.value);
                  handleChange(e);
                }}
              ></textarea>
      )}
              

            </div>
          </div>
        </div>
        <div className="contact-details-form">
          <div className="cdf-wrapper">
            <div className="cdf-top">
              {(contactDetails?.name &&
                contactDetails.email &&
                contactDetails.contact) ||
              (formData?.name && formData.email && formData.contact) ? (
                <img src={completed} alt="form-input-completed" />
              ) : (
                <img src={pending} alt="form-input-completed" />
              )}{" "}
              <h3 className="lucum-job-form-title">Point of Contact Details</h3>
            </div>
            <div className="cdf-bottom">
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Name
                </InputLabel>
                <Select
                  label="Name"
                  className="dropdown-hospital-locum-job"
                  sx={{
                    // width: "300px",
                    width: width > 480 ? "300px" : "348px",
                    height: "47px",
                  }}
                  // value={contactDetails?.name}
                  value={formData?.name || contactDetails?.name || ""}
                  onChange={(e) => {
                    e.target.name = "name";
                    handleContactDetails(e);
                    handleChange(e);
                    const selectedName = e.target.value;
                    const selectedContact = FetchContactDetails.find(
                      (data) => data.name === selectedName
                    );
                    if (selectedContact) {
                      setFormData({
                        ...formData,
                        name: selectedContact.name || "",
                        email: selectedContact.email || "",
                        contact: selectedContact.contact || "",
                      });
                    }
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) return "";
                    else return selected;
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    {/* hospital */}
                  </MenuItem>
                  {FetchContactDetails?.map((data) => (
                    <MenuItem value={data?.name}>{data?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                // disabled
                id="outlined-basic"
                // label="Email id"
                placeholder="email"
                name="emailId"
                variant="outlined"
                sx={{ width: "328px" }}
                // value={contactDetails?.email}
                value={formData?.email || contactDetails?.email || ""}
                InputProps={{
                  readOnly: true,
                  style: {
                    height: "47px",
                  },
                }}
              />
              <TextField
                placeholder="Contact No"
                id="outlined-basic"
                name="contactNo"
                variant="outlined"
                sx={{ width: "328px" }}
                // type="number"
                type="text"
                // value={contactDetails?.contact}
                //formPhoneWithPlus
                //contactWithPlus
                // value={
                //   formData?.contact
                //     ? formData?.contact
                //     : contactDetails?.contact
                // }
                value={
                  (formData?.contact ? "+" + formData?.contact : "") ||
                  (contactDetails?.contact ? "+" + contactDetails?.contact : "")
                }
                InputProps={{
                  readOnly: true,
                  style: {
                    height: "47px",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="basic-cost-form">
          <div className="bcf-wrapper">
            <div className="bcf-top">
              <div className="bcf-top-one">
                <h3 className="lucum-job-form-title">Basic Cost</h3>
              </div>
              <div className="bcf-top-two">
                <div className="bcf-details">
                  <p>Professional Charges</p>
                  <label>
                    Rs. {totalCost?.hospital?.toLocaleString("en-IN")}{" "}
                  </label>
                </div>
                {totalCost?.marginPercentage ? (
                  <div className="bcf-details">
                    <p>Convenience Fees({totalCost?.marginPercentage}%)</p>
                    <label>
                      Rs. {totalCost?.margin?.toLocaleString("en-IN")}
                    </label>
                  </div>
                ) : (
                  <div className="bcf-details">
                    <p>Convenience Fees</p>
                    <label>
                      Rs. {totalCost?.margin?.toLocaleString("en-IN")}
                    </label>
                  </div>
                )}

                {/* <div className="bcf-details">
                  <p>Convenience Fees({totalCost.marginPercentage}%)</p>
                  <label>
                    Rs. {totalCost?.margin?.toLocaleString("en-IN")}
                  </label>
                </div> */}
                <div className="bcf-details">
                  <p>
                    Taxes(18%)
                    <Tooltip title="Applicable on Convenience fee">
                      <InfoIcon />
                    </Tooltip>
                  </p>
                  <label>Rs. {totalCost?.gst?.toLocaleString("en-IN")}</label>
                </div>

                <div className="bcf-details">
                  <p>Total to be paid for this job</p>
                  <span>*Inclusive of taxes</span>
                  <label>Rs. {totalCost?.total?.toLocaleString("en-IN")}</label>
                </div>
              </div>
            </div>
            <div>
              {/* <div>
                <p
                  style={{
                    margin: 0,
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#d74f58",
                  }}
                >
                  Note: Above amount is inclusive of TDS which is to be deducted
                  at the source (Employer)
                </p>
              </div> */}
              {/* {
                // let isFormDataValid = "";
                const disabled =
                  (!formData.hospitals || Object.keys(professionalDetailsError).length !== 0) &&
                  !isFormDataValid;
              } */}
              <div className="bcf-bottom">
                {disabled ? (
                  <button
                    className="post-button"
                    // onClick={trackPost}
                    // onClick={() => dispatch(setOpenJobMatches())}
                    // onClick={PostLocumJob}
                    style={{ backgroundColor: "#ddd" }}
                    onClick={() => {
                      setOpenModel(!openModel);
                      trackFinalPost();
                    }}
                    disabled={disabled}
                    id="job-posted"
                  >
                    {isJobPostLoading ? <CircularProgress /> : "Post a Job!"}
                  </button>
                ) : (
                  <button
                    className="post-button"
                    // onClick={() => dispatch(setOpenJobMatches())}
                    // onClick={PostLocumJob}
                    onClick={() => {
                      setOpenModel(!openModel);
                      trackFinalPost();
                    }}
                    disabled={disabled}
                  >
                    {isJobPostLoading ? <CircularProgress /> : "Post a Job!"}
                  </button>
                )}
                <Link
                  to="/"
                  onClick={() =>
                    dispatch(setActiveTab({ index: 1, LocumJobToggle: false }))
                  }
                >
                  <button className="cancel-button">Cancel</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openModel}
          onClose={() => setOpenModel(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Jobpopup
            setOpenModel={setOpenModel}
            PostLocumJob={PostLocumJob}
            jobDetails={jobDetails}
            totalDays={totalDays}
          />
        </Modal>
      </div>
    </div>
  );
}

export default LocumJobForm;

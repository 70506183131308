import React, { useEffect, useState } from "react";
import "../LogIn.scss";
import Button from "@mui/material/Button";
import { fontWeight } from "@mui/system";
import logo from "../../../asset/navbar/logo.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { Link } from "react-router-dom";
import { signUpSchema } from "../../../schema/authSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import { useNavigate } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
// import carousel1 from "../../../asset/carousel/Carousel1.png";
// import carousel2 from "../../../asset/carousel/carousel2.png";
// import carousel3 from "../../../asset/carousel/carousel3.png";
import carousel1 from "../../../asset/carousel1/carousel1.png";
import carousel2 from "../../../asset/carousel1/carousel2.png";
import carousel3 from "../../../asset/carousel1/carousel3.png";
import carousel4 from "../../../asset/carousel1/carousel4.png";
import carousel5 from "../../../asset/carousel1/carousel5.png";
import loginProfIcon from "../../../asset/loginProfIcon.svg";
import VerifyOTP from "../PhoneOTP/VerifyOTP";
import graphqlRequest from "../../../requestMethod";
import EmailOtp from "../../../components/PopUp/Otp/EmailOtp/EmailOtp";
import PhoneOtp from "../../../components/PopUp/Otp/PhoneOtp/PhoneOtp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./SignUp.scss";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { message } from "../../../components/Common/SnakeAlert/SnakeAlert";
import { clearState } from "../../../redux/apiCall";
import { signUpSuccess } from "../../../redux/userReducer";
import useWindowDimensions from "../../../components/Common/WindowDimention/windowDimention";

import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-PLW49NB",
};

TagManager.initialize(tagManagerArgs);

const SignUp = () => {
  const [emailOtpField, setEmailOtpField] = useState(new Array(4).fill(""));
  const [phoneOtpField, setPhoneOtpField] = useState(new Array(4).fill(""));
  const [modalForEmailOtp, setModalForEmailOtp] = useState(false);
  const [modalForPhoneOtp, setModalForPhoneOtp] = useState(false);
  const [isEmailVerifyed, setIsEmailVerifyed] = useState(false);
  const [isPhoneVerifyed, setIsPhoneVerifyed] = useState(false);
  const [emailToken, setEmailToken] = useState("");
  const [phoneToken, setPhoneToken] = useState("");

  const [isDidsabled, setIsDisabled] = useState(false);

  let [companyTypeList, setCompanyTypeList] = useState([]);
  let [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedCompanyType, setSelectedCompanyType] =
    useState("Type of company*");

  let [term, setTerm] = useState(false);
  let [formError, setFormError] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserDetails = useSelector((state) => state?.user?.signUpUserGmail);
  console.log("USER DETAILS", UserDetails);

  let [userRegistration, setUserRegistration] = useState({
    salutation: "",
    organization: "",
    role: "",
    companyType: "",
    website: "",
    name: UserDetails?.name,
    email: UserDetails?.email,
    phone: "",
  });

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { height, width } = useWindowDimensions();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  function fetchComapnyListData() {
    const query = `

 query{

 listCompanyType{

name,

 id
 }

}

`;

    graphqlRequest(query).then((response) => {
      console.log("Company List", response?.data?.listCompanyType);

      let sortedCompanies = response?.data?.listCompanyType.sort(function (
        a,

        b
      ) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase

        const nameB = b.name.toUpperCase(); // ignore upper and lowercase

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        } // names must be equal

        return 0;
      });

      console.log("Company List Sorted", sortedCompanies);

      setCompanyTypeList(sortedCompanies);
    });
  }
  function verifyEmail() {
    const query = `
      mutation {
      verifyEmail(email:"${userRegistration?.email}")
      }
    `;
    graphqlRequest(query).then((response) => {
      if (response?.errors) {
        message.error(response.errors[0].message);
      } else {
        setModalForEmailOtp(true);
        setEmailToken(response.data.verifyEmail);
        message.success("OTP has been sent.");
      }
    });
  }
  function verifyPhone() {
    const query = `
    mutation {
      verifyPhone(contact:{code:"91",mobile:"${userRegistration?.phone}"})
    }
  `;
    graphqlRequest(query).then((response) => {
      if (response?.errors) {
        message.error("Phone number already exists!");
      } else {
        setModalForPhoneOtp(true);
        setPhoneToken(response.data.verifyPhone);
        message.success("Otp sent Successfully!");
      }
    });
  }

  useEffect(() => {
    fetchComapnyListData();
    setEmailOtpField();
  }, []);

  const salutationList = [
    {
      id: 1,
      name: "Mr",
    },
    {
      id: 2,
      name: "Mrs",
    },
    {
      id: 3,
      name: "Ms",
    },
    {
      id: 4,
      name: "Dr",
    },
  ];
  let handleCompanyListDetails = (e) => {
    let selectedData = companyTypeList?.filter(
      (data) => data?.name === e.target[0].value
    );
    setSelectedCompany(selectedData);
  };
  function handleUserInput(property, value) {
    setUserRegistration({ ...userRegistration, [property]: value });
    console.log("USER REGISTRATION", userRegistration);
  }
  function validateForm() {
    let error = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!userRegistration.name) {
      error.name = "Name is Required";
    }
    if (
      userRegistration?.name?.length < 3 ||
      userRegistration?.name?.length > 30
    ) {
      error.name =
        "Your name must be greater than 2 char and less than 30 char";
    }
    if (!userRegistration.phone) {
      error.phone = "Phone Number is Required";
    }
    if (userRegistration?.phone?.length !== 10) {
      error.phone = "Phone Number invalid ";
    }
    if (!userRegistration.companyType) {
      error.companyType = "Company Type is required";
    }
    if (!userRegistration.email) {
      error.email = "Email is Required";
    }
    if (!regex.test(userRegistration.email)) {
      error.email = "Please enter valid Email.";
    }
    if (!userRegistration.role) {
      error.role = "Role is required";
    }
    if (
      userRegistration?.role?.length < 2 ||
      userRegistration?.role?.length > 30
    ) {
      error.role = "Role is required";
    }
    if (!userRegistration.salutation) {
      error.salutation = "Salutation is required";
    }
    if (!userRegistration.organization) {
      error.organization = "Registered Company Name is required";
    }
    if (!term) {
      error.term = "Terms and condition needs to be checked";
    }

    if (!isEmailVerifyed) {
      error.emailVerified = "Email Not Verified";
      // message.error("Email Not Verified");
    }

    if (!isPhoneVerifyed) {
      error.isPhoneVerified = "Phone Number not Verified";
      // message.error("Phone Number not Verified");
    }

    return error;
  }

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  // const trackUserRegistration = async () => {
  //   // console.log("111111>>>>", userRegistration)
  //   // console.log("11111111111");
  //   // const timestamp = new Date().getTime();
  //   // const randomString = Math.random().toString(36).substring(2, 12);
  //   // const uniqueId = `${timestamp}${randomString}`;
  //   // console.log(">>>>>>>>>>",uniqueId);
  //   if (window.Moengage) {
  //     // console.log("2222222222222")
  //     // window.Moengage.setDebugLevel(2)
  //     // console.log("1111111>>>>",uniqueId);
  //     //set app id
  //     // set unique id
  //     // window.Moengage.add_unique_user_id(uniqueId);
  //     //set user attributes
  //     // window.Moengage.add_user_attribute("userName", userRegistration.name);
  //     // window.Moengage.add_user_attribute("userEmail", userRegistration.email);
  //     window.Moengage.add_user_name(userRegistration.name);
  //     window.Moengage.add_email(userRegistration.email);
  //     window.Moengage.add_mobile(userRegistration.phone);

  //     //set event data
  //     window.Moengage.track_event("user_Registration", {
  //       // "userId": uniqueId,
  //       // "userName": userRegistration.name,
  //       // "userEmail": userRegistration.email,
  //       // "date": Date.now(),
  //     });
  //     // window.Moengage.destroy_session();
  //   }
  // };

  const trackUserVerifyEmail = async () => {
    // console.log("111111>>>>", userRegistration)
    // console.log("11111111111");
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    // console.log(">>>>>>>>>>",uniqueId);
    if (window.Moengage) {
      // console.log("2222222222222")
      // window.Moengage.setDebugLevel(2)
      // console.log("1111111>>>>",uniqueId);
      //set app id
      // set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_attribute("userName", userRegistration.name);
      // window.Moengage.add_user_attribute("userEmail", userRegistration.email);
      window.Moengage.add_user_name(userRegistration.name);
      window.Moengage.add_email(userRegistration.email);
      // window.Moengage.add_mobile(userRegistration.phone);

      //set event data
      window.Moengage.track_event("Email_Signup_vclick", {
        // "userId": uniqueId,
        // "userName": userRegistration.name,
        // "userEmail": userRegistration.email,
        // "date": Date.now(),
      });
      // window.Moengage.destroy_session();
    }
  };

  const trackUserVerifyPhone = async () => {
    // console.log("111111>>>>", userRegistration)
    // console.log("11111111111");
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    // console.log(">>>>>>>>>>",uniqueId);
    if (window.Moengage) {
      // console.log("2222222222222")
      // window.Moengage.setDebugLevel(2)
      // console.log("1111111>>>>",uniqueId);
      //set app id
      // set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_attribute("userName", userRegistration.name);
      // window.Moengage.add_user_attribute("userEmail", userRegistration.email);
      window.Moengage.add_user_name(userRegistration.name);
      window.Moengage.add_email(userRegistration.email);
      window.Moengage.add_mobile(userRegistration.phone);

      //set event data
      window.Moengage.track_event("Mobile_Signup_vclick", {
        // "userId": uniqueId,
        // "userName": userRegistration.name,
        // "userEmail": userRegistration.email,
        // "date": Date.now(),
      });
      // window.Moengage.destroy_session();
    }
  };

  function handleRegistrationInput() {
    const query = `
   mutation{
       signupHospital(salutation:${userRegistration?.salutation}, name:"${userRegistration?.name}",organization:"${userRegistration?.organization}",email:"${userRegistration?.email}",contact:{code:"91",mobile:"${userRegistration.phone}"},role:"${userRegistration.role}",companyType:"${userRegistration.companyType}",website:"${userRegistration.website}")
     }
  `;
    graphqlRequest(query).then((response) => {
      console.log("SIGN UP RESPONSE", response);
      window.dataLayer.push({
        event: "sign_up: signup_completed_successfuly",
      });
      message.success(
        "Thanks for Registering. Email has been sent to you for next steps."
      );

      console.log(window.dataLayer);
      // console.log("123-->", response);
      const msg = clearState(dispatch);
      if (response?.errors) {
        message.error("Some Issue With SignUp");
      } else {
        navigate("/login");
        // trackUserRegistration();

        window.dataLayer.push({
          event: "sign_up: signup_completed_successfuly",
        });
        message.success(
          "Thank you for Registering. Email has been sent to you for next steps."
        );
        dispatch(signUpSuccess());
      }
    });
  }
  useEffect(() => {
    setFormError(validateForm());
  }, [term, userRegistration, isEmailVerifyed, isPhoneVerifyed]);

  console.log("formError", formError);
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  return (
    <>
      <div className="login-parent-container">
        <div className="login-inner-container">
          <div className="login-header">
            {/* <div style={{ marginTop: "5px", marginLeft: "15px" }}>
              {width < 480 && <img src={logo} alt="Logo" />}
            </div> */}
            {/* <div>
                      {width > 480 && <img src={logo} alt="Logo" style={{ marginLeft: '37rem', marginTop: '2rem' }} />}
                    </div> */}
            <div>
              {width > 480 && (
                <img src={logo} alt="Logo" style={{ marginLeft: "42rem" }} />
              )}
              {/* <img src={logo} /> */}
              {/* {width < 480 && <img src={logo} alt="Logo" />} */}
              <span>Already Signed Up?</span>
              <Button
                id="login-up-button"
                variant="contained"
                sx={{
                  backgroundColor: "#d62511",
                  color: "white",
                  borderRadius: "25px",
                  padding: "8x 25px",
                  fontWeight: "bold",
                  minWidth: "90px",
                  // marginLeft: "63rem",
                  height: "28px",
                  // marginTop: "22px",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </div>
          </div>
          <div className="login-outer-content-container">
            <div className="login-content-left-container">
              <div className="login-content-left-containerContent">
                {/* <div
                  className="login-content-left-containerContent-Div"
                  // style={{
                  //   textAlign: "left",
                  //   color: "#FFF",
                  //   fontFamily: "Noto Sans",
                  //   fontWeight: "700",
                  //   fontSize: "18px",
                  //   lineHeight: "25px",
                  //   marginLeft: "-0.5rem",
                  // }}
                >
                  <div style={{ marginTop: "5px" }}>
                    {/* <img src={logo} /> */}
                {/* {width > 480 && <img src={logo} alt="Logo" />} */}
                {/* </div>
                  <br />
                  <h3>
                    {" "}
                    The Future of <br /> Healthcare Hiring is here!
                  </h3>
                  <h4 style={{ fontWeight: "normal" }}>
                    Made in India for the World.
                  </h4>
                </div> */}

                {width > 480 ? (
                  <div className="login-content-left-containerContent-Div">
                    <br />
                    <h3>
                      {" "}
                      The Future of <br class="break" /> Healthcare Hiring is
                      here!
                    </h3>
                    <h4 style={{ fontWeight: "normal" }}>
                      Made in India for the World.
                    </h4>
                  </div>
                ) : (
                  <>
                    <h3
                      id="headline"
                      style={{
                        width: "324px",
                        marginTop: "10px",
                        marginLeft: "16px",
                        textAlign: "left",
                        color: "white",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                      }}
                    >
                      Let us help you get the perfect person for your job.
                    </h3>

                    <h4
                      style={{
                        width: "324px",
                        marginTop: "1px",
                        marginLeft: "16px",
                        textAlign: "left",
                        color: "white",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                    >
                      Posting a job on our Platform is quick and easy, and it
                      wont take more than 10 minutes to do so.
                    </h4>
                  </>
                )}

                <p
                  className="login-content-left-containerContent-P"
                  style={{
                    fontFamily: "Noto Sans",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "16px",
                    width: "50%",
                    color: "#fff",
                    marginTop: "-2px",
                    marginLeft: "3px",

                    alignItems: "center",
                    textAlign: "left",
                    display: width > 480 ? "block" : "none",
                  }}
                >
                  India's first healthcare marketplace for connecting verified
                  healthcare professionals with employers/hiring managers.
                </p>
              </div>

              {width < 480 && (
                <div>
                  <img
                    src={loginProfIcon}
                    style={{
                      // width: "100%",
                      // height: "100%",
                      // width: "224",
                      // height: "200",
                      marginTop: "-14px",
                      marginLeft: "-160px",
                    }}
                  />
                </div>
              )}

              {width > 480 && (
                <AutoplaySlider
                  play={true}
                  showTimer={false}
                  organicArrows={false}
                  infinite={true}
                  bullets={false}
                  style={{ backgroundColor: "#fff" }}
                  fillParent={false}
                >
                  <div>
                    <img
                      src={carousel1}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel2}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel3}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel4}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Noto Sans",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: "50%",
                        color: "#fff",
                        marginTop: "-2px",
                        // marginLeft: "2.5rem",

                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      Our Clients:
                    </p>
                    <img
                      src={carousel5}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </AutoplaySlider>
              )}
            </div>
            <div className="signup-content-right-container">
              <div className="signup-inner-content-right-container">
                {/* <div className="signup-inner-heading">
                  <span style={{ marginRight: "200px" }}>
                    Take your healthcare hiring to the next level.
                  </span>
                  <span style={{ marginRight: "190px" }}>
                    Sign up with <strong>Jobizo</strong> today!
                  </span>
                </div> */}
                {width > 480 ? (
                  <div className="signup-inner-heading">
                    <span style={{ marginRight: "200px" }}>
                      Take your healthcare hiring to the next level.
                    </span>
                    <span style={{ marginRight: "190px" }}>
                      Sign up with <strong>Jobizo</strong> today!
                    </span>
                  </div>
                ) : (
                  <div className="signup-inner-heading2">
                    <span style={{ marginRight: "200px" }}>
                      Take your healthcare hiring to the next level.
                    </span>
                    <span style={{ marginRight: "190px" }}>
                      Sign up with <strong>Jobizo</strong> today!
                    </span>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "13px",
                    // paddingBottom: "20px",
                    maxWidth: width > 480 ? "450px" : "390px",
                    // maxHeight: "200px",
                    marginTop: width > 480 ? "20px" : "-5px",
                    marginLeft: width > 480 ? "0" : "-6px",
                  }}
                >
                  {width > 480 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ flex: "51%" }}>
                        <FormControl
                          sx={{
                            width: "90%",
                            backgroundColor: "#dfe1e6",
                            height: "35px",
                          }}
                        >
                          {/* <InputLabel style={{ alignItems: "center" }}>
                         Salutation*
                       </InputLabel> */}
                          <Select
                            sx={{ height: "35px", padding: "5px" }}
                            // variant="outlined"
                            // label="Salutation"
                            labelId="demo-simple-select-autowidth-label"
                            //  id="demo-simple-select-autowidth"

                            value={
                              userRegistration?.salutation || "Salutation*"
                            }
                            onChange={(e) =>
                              handleUserInput("salutation", e.target.value)
                            }
                            displayEmpty
                            renderValue={(selected) => {
                              if (!selected) return "";
                              else return selected;
                            }}
                          >
                            <MenuItem disabled value=""></MenuItem>
                            {salutationList?.map((data) => (
                              <MenuItem value={data?.name} label="Salutation">
                                {data?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      {/* NAME */}
                      <div></div>
                      <div
                        style={{ width: "100%", backgroundColor: "#dfe1e6" }}
                      >
                        <TextField
                          name="name"
                          // label="Full Name*"
                          autoComplete="off"
                          placeholder="Full Name*"
                          defaultValue={UserDetails?.name}
                          // value={UserDetails?.name}
                          fullWidth
                          onBlur={() => {
                            if (formError.name) {
                              message.error(formError.name);
                            }
                          }}
                          onChange={(e) =>
                            handleUserInput("name", e.target.value)
                          }
                          // variant="outlined"
                          InputProps={{
                            style: {
                              // fontSize: "10px",
                              // textAlign: "center",
                              height: "35px",
                              textAlign: "center",
                            },
                          }}
                          renderValue={(selected) => {
                            if (!selected) return "";
                            else return selected;
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ flex: "51%" }}>
                          <FormControl
                            sx={{
                              width: "100%",
                              backgroundColor: "#dfe1e6",
                              height: "35px",
                            }}
                          >
                            <Select
                              sx={{ height: "35px", padding: "5px" }}
                              // variant="outlined"
                              // label="Salutation"
                              labelId="demo-simple-select-autowidth-label"
                              //  id="demo-simple-select-autowidth"

                              value={
                                userRegistration?.salutation || "Salutation*"
                              }
                              onChange={(e) =>
                                handleUserInput("salutation", e.target.value)
                              }
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) return "";
                                else return selected;
                              }}
                            >
                              <MenuItem disabled value=""></MenuItem>
                              {salutationList?.map((data) => (
                                <MenuItem value={data?.name} label="Salutation">
                                  {data?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* NAME */}

                      <div>
                        <div
                          style={{ width: "100%", backgroundColor: "#dfe1e6" }}
                        >
                          <TextField
                            name="name"
                            // label="Full Name*"
                            autoComplete="off"
                            placeholder="Full Name*"
                            defaultValue={UserDetails?.name}
                            // value={UserDetails?.name}
                            fullWidth
                            onBlur={() => {
                              if (formError.name) {
                                message.error(formError.name);
                              }
                            }}
                            onChange={(e) =>
                              handleUserInput("name", e.target.value)
                            }
                            // variant="outlined"
                            InputProps={{
                              style: {
                                // fontSize: "10px",
                                // textAlign: "center",
                                height: "35px",
                                textAlign: "center",
                              },
                            }}
                            renderValue={(selected) => {
                              if (!selected) return "";
                              else return selected;
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* EMAIL */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ flex: "100%" }}>
                      <div style={{ width: "100%", height: "100%" }}>
                        <TextField
                          onChange={(e) =>
                            handleUserInput("email", e.target.value)
                          }
                          onBlur={() => {
                            if (formError.email) {
                              message.error(formError.email);
                            }
                          }}
                          name="official_email"
                          autoComplete="off"
                          defaultValue={UserDetails?.email}
                          // value={UserDetails?.email}
                          // label="Official email id*"
                          placeholder="Official email id*"
                          fullWidth
                          sx={{ width: "95%", backgroundColor: "#dfe1e6" }}
                          InputProps={{
                            style: {
                              // fontSize: "10px",
                              // textAlign: "center",
                              height: "35px",
                              margin: "0",
                              padding: "0",
                            },
                          }}
                          disabled={isEmailVerifyed}
                        />
                      </div>
                    </div>

                    <div style={{ width: "35%" }}>
                      {isEmailVerifyed ? (
                        <Button
                          disabled={formError?.email || isEmailVerifyed}
                          sx={{ width: "100%", height: "35px", margin: "0" }}
                          // variant="outlined"
                          style={{
                            backgroundColor: isEmailVerifyed && "green",
                            color: isEmailVerifyed && "#fff ",
                          }}
                        >
                          Verified
                        </Button>
                      ) : (
                        <Button
                          disabled={formError?.email || isEmailVerifyed}
                          sx={{
                            width: "100%",
                            backgroundColor: "#b5d4e8",
                            height: "35px",
                            margin: "0",
                            textTransform: "capitalize",
                            // transition: "background-color 0.3s",
                            // "&:hover": {
                            //   backgroundColor: "#11f2eb",
                            // },
                          }}
                          // variant="outlined"
                          onClick={() => {
                            verifyEmail();
                            trackUserVerifyEmail();
                          }}
                        >
                          Verify
                        </Button>
                      )}

                      <Modal
                        open={modalForEmailOtp}
                        onClose={setModalForEmailOtp}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <EmailOtp
                          verifyEmail={verifyEmail}
                          openOtpHandler={modalForEmailOtp}
                          setOpenOtpHandler={setModalForEmailOtp}
                          verifyMode="Email id"
                          emailToken={emailToken}
                          setIsEmailVerifyed={setIsEmailVerifyed}
                          setModalForEmailOtp={setModalForEmailOtp}
                          isEmailVerifyed={isEmailVerifyed}
                          emailLabel={userRegistration?.email}
                          userRegistration={userRegistration}
                        />
                      </Modal>
                    </div>
                  </div>

                  {/* Phone Number */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ flex: "100%" }}>
                      <div style={{ width: "100%" }} className="verifyInput">
                        <TextField
                          onChange={(e) =>
                            handleUserInput("phone", e.target.value)
                          }
                          name="phone"
                          autoComplete="off"
                          type={"number"}
                          // label="Phone*"
                          placeholder="Phone*"
                          fullWidth
                          onBlur={() => {
                            if (formError.phone) {
                              message.error(formError.phone);
                            }
                          }}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          sx={{ width: "95%", backgroundColor: "#dfe1e6" }}
                          InputProps={{
                            style: {
                              // fontSize: "10px",
                              // textAlign: "center",
                              height: "35px",
                            },
                          }}
                          disabled={isPhoneVerifyed}
                        ></TextField>
                      </div>
                    </div>

                    <div style={{ width: "35%" }}>
                      {isPhoneVerifyed ? (
                        <Button
                          sx={{ width: "100%", height: "35px" }}
                          // variant="outlined"
                          style={{
                            backgroundColor: isPhoneVerifyed && "green",
                            color: isPhoneVerifyed && "#fff",
                          }}
                        >
                          Verified
                        </Button>
                      ) : (
                        <Button
                          disabled={formError?.phone || isPhoneVerifyed}
                          sx={{
                            width: "100%",
                            backgroundColor: "#b5d4e8",
                            height: "35px",
                            textTransform: "capitalize",
                          }}
                          // variant="outlined"
                          onClick={() => {
                            verifyPhone();
                            trackUserVerifyPhone();
                          }}
                        >
                          Verify
                        </Button>
                      )}

                      <Modal
                        open={modalForPhoneOtp}
                        onClose={setModalForPhoneOtp}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <PhoneOtp
                          verifyPhone={verifyPhone}
                          openOtpHandler={modalForPhoneOtp}
                          setOpenOtpHandler={setModalForPhoneOtp}
                          verifyMode="Phone Number"
                          otpField={phoneOtpField}
                          setOtpField={setPhoneOtpField}
                          phoneToken={phoneToken}
                          setModalForPhoneOtp={setModalForPhoneOtp}
                          setIsPhoneVerifyed={setIsPhoneVerifyed}
                          phoneLabel={userRegistration?.phone}
                          userRegistration={userRegistration}
                        />
                      </Modal>
                    </div>
                  </div>
                  {/* Type Of Company */}
                  <div>
                    <FormControl
                      sx={{ width: "100%", backgroundColor: "#dfe1e6" }}
                    >
                      {/* <InputLabel id="demo-simple-select-autowidth-label">
                        Type of company*
                      </InputLabel> */}
                      <Select
                        sx={{
                          width: "100%",
                          height: "35px",
                        }}
                        // label="Type of company*"
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        // value={companyTypeList[0].id || "Type of company*"}
                        value={selectedCompanyType}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedCompanyType(selectedValue);
                          let data = companyTypeList?.filter((data) => {
                            return data.name === selectedValue;
                          });
                          handleUserInput("companyType", data[0].id);
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) return "";
                          else return selected;
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {/* <MenuItem disabled value="">
                        Type of company*
                        </MenuItem> */}
                        {companyTypeList?.map((data) => (
                          <MenuItem value={data?.name} label="Hospital">
                            {data?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <TextField
                      fullWidth
                      // variant="filled"
                      // label="Type of company*"
                      // placeholder="Type of company*"
                      id="demo-simple-select-autowidth"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputProps={{ sx: {height: "35px", backgroundColor: "#dfe1e6" } }}
                      onChange={(e) => {
                        let data = companyTypeList?.find(
                          (data) => data.name === e.target.value
                        );
                        handleUserInput("companyType", data ? data.id : "");
                      }}
                    >
                      <option value="" >Type of company*</option>
                      {companyTypeList?.map((data) => (
                        <option key={data?.id} value={data?.name}>
                          {data?.name}
                        </option>
                      ))}
                    </TextField> */}
                  </div>

                  {/* ORGANIGATION */}
                  <div style={{ backgroundColor: "#dfe1e6" }}>
                    <TextField
                      name="registered_org"
                      autoComplete="off"
                      // label="Registered Company Name*"
                      placeholder="Registered Company Name*"
                      // placeholder="Registered Organization*"
                      fullWidth
                      onBlur={() => {
                        if (formError.organization) {
                          message.error(formError.organization);
                        }
                      }}
                      onChange={(e) =>
                        handleUserInput("organization", e.target.value)
                      }
                      displayEmpty
                      InputProps={{
                        style: {
                          // fontSize: "10px",
                          // textAlign: "center",
                          height: "35px",
                        },
                      }}
                      renderValue={(selected) => {
                        if (!selected) return "";
                        else return selected;
                      }}
                    />
                  </div>

                  {/* {!isEmailVerifyed && (
                    <span
                      style={{
                        color: "red",

                        marginTop: "-2px",

                        fontSize: "small",
                      }}
                    >
                      * Please Verify Your Email Address
                    </span>
                  )} */}

                  {/* {!isPhoneVerifyed && (
                    <span
                      style={{
                        color: "red",

                        marginTop: "-2px",

                        fontSize: "small",
                      }}
                    >
                      * Please Verify Your Phone Number
                    </span>
                  )} */}
                  <div style={{ backgroundColor: "#dfe1e6" }}>
                    <TextField
                      name="role"
                      // label="Your Role*"
                      placeholder="Your Role*"
                      autoComplete="off"
                      fullWidth
                      onBlur={() => {
                        if (formError.role) {
                          message.error(formError.role);
                        }
                      }}
                      onChange={(e) => handleUserInput("role", e.target.value)}
                      InputProps={{
                        style: {
                          // fontSize: "10px",
                          // textAlign: "center",
                          height: "35px",
                        },
                      }}
                    />
                  </div>

                  <div style={{ backgroundColor: "#dfe1e6" }}>
                    <TextField
                      name="website"
                      autoComplete="off"
                      // label="Website (optional)"
                      placeholder="Website (optional)"
                      fullWidth
                      onChange={(e) =>
                        handleUserInput("website", e.target.value)
                      }
                      InputProps={{
                        style: {
                          // fontSize: "10px",
                          // textAlign: "center",
                          height: "35px",
                        },
                      }}
                    />
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="vehicle2"
                      name="term"
                      value="accepted"
                      checked={term}
                      onClick={(e) => setTerm(!term)}
                    />
                    <label for="term">
                      I agree to these{" "}
                      <a
                        // href="https://ifanflex.staging.jobizo.com/#/terms-and-condition"
                        href="https://jobizo.com/terms-and-conditions/"
                        style={{ color: "#2D5FA1" }}
                        target="_blank"
                      >
                        {" "}
                        Terms and Conditions{" "}
                      </a>
                      {/* &{" "} */}
                      {/* <a
                        href="https://ifanflex.staging.jobizo.com/?#/privacy-policy"
                        style={{ color: "#2D5FA1" }}
                        target="_blank"
                      >
                        Privacy Policy
                      </a> */}
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    {/* <div style={{ width: "30%" }}>
                      <Button
                        onClick={() => navigate("/login")}
                        variant="outlined"
                        style={{ width: "100%" }}
                      >
                        Cancel
                      </Button>
                    </div> */}
                    <div style={{ width: "25%" }}>
                      <Button
                        variant="contained"
                        style={{
                          // backgroundColor: "#d62511",
                          backgroundColor:
                            Object.keys(formError).length !== 0
                              ? "#f8a0a0"
                              : "#d62511",
                          color: "white",
                          borderRadius: "25px",
                          fontWeight: "bold",
                          justifyContent: "center",
                          //  Width: "80px",
                          // marginLeft: "11rem",
                          marginLeft: width > 480 ? "11rem" : "8rem",
                          marginTop: width > 480 ? "0" : "-15px",
                          height: "28px",
                        }}
                        disabled={Object.keys(formError).length !== 0}
                        fullWidth
                        onClick={handleRegistrationInput}
                      >
                        Sign Up
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
